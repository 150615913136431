import React, { useState } from 'react';
import security from "../../media/security.png";
import pedit from "../../media/pedit.svg"
import SigninleftNavbar from '../SigninNavbar/SigninleftNavbar';
import { Link } from 'react-router-dom';


const ForgetPassword = () => {
    const [step, setStep] = useState(1); // Initial step

    const handleProceed = () => {
        setStep(step + 1); // Move to the next step
    };

    

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="signbox forget cflex">
                        <p>We will require to send an OTP to your Phone before you can reset the password.</p>
                        <div className="user-pass cflex" style={{ marginBottom: "12px" }}>
                            <input type="text" placeholder='Enter Your Registered Phone no.' />
                        </div>
                        <div className="gsign cflex">
                            <div className="google" onClick={handleProceed}>Proceed</div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="signbox forget2 cflex">
                        <h3>OTP Required</h3>
                        <p>an OTP has been sent to your device please check your inbox</p>
                        <div className="user-pass cflex" style={{ marginBottom: "12px" }}>
                            <div className="feild2">
                                <input type="text" className='number' placeholder='9948441239' />
                                <img src={pedit} alt="" />
                            </div>                           
                             <input type="text" placeholder='Enter OTP' />
                        </div>
                        <div className="gsign cflex">
                            <div className="google" onClick={handleProceed}>Proceed</div>
                        </div>
                        <div className="resend">resend OTP</div>
                    </div>
                );
            case 3:
                return (
                    <div className="signbox forget2 cflex">
                        <h3 style={{marginBottom:"30px"}}>Change Password</h3>
                         <div className="user-pass cflex" style={{ marginBottom: "12px" }}>
                            <input type="text" placeholder='New Password' />
                            <input type="text" placeholder='Confirm New Password' />
                        </div>
                        <div className="gsign cflex">
                            <div className="google" onClick={handleProceed}>Proceed</div>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="signbox forget2 cflex">
                        <h3 style={{marginBottom:"10px"}} >Your Password Reset Successfully</h3>
                        <p style={{marginBottom:"15px"}}>Please Try Login Again using New password</p>
                        
                        <div className="gsign cflex">
                            <Link to={'/sign-in'} className="google" onClick={handleProceed}>Go To Login</Link>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="general-right">

            <div className="right flex">
                <div className="signin">
                    <div className="signin-head s2">
                        <h3>RESET PASSWORD</h3>
                    </div>
                    {renderStepContent()}
                </div>
                <div className="drawing cflex">
                    <div className="draw-head">
                        {/* <h3>LOREM IPSUM</h3> */}
                        <p>Secure and easy access to your account. Manage your campaigns and track your progress with ease.</p>
                    </div>
                    <img src={security} alt="" />
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
