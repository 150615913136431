import React, { useEffect, useRef, useState } from 'react';
import lead from "../../../media/lead.svg";
import bran from "../../../media/bran.svg";
import website from "../../../media/website.svg";
import product from "../../../media/product.svg";
import reel from "../../../media/reels.svg";
import story from "../../../media/story.svg";
import post from "../../../media/post.svg";
import upoad from "../../../media/upload.svg";
import edits from "../../../media/edits.svg";
import i from "../../../media/i.svg";
import call from "../../../media/call.svg";
import celebrate from "../../../media/celebrate.png";
import posts from "../../../media/post.png";
import reels from "../../../media/Reels.png";
import stori from "../../../media/story.png";
import { ConfigProvider, Slider } from 'antd';
import ImageSilder from './ImageSilder';
import VideoSlider from './VideoSlider';
import axios from 'axios';

const statesOfIndia = [
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Ladakh',
    'Lakshadweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
];
const SmartCampaign = ({ step, handleNextStep, handlePreviousStep ,setActiveComponent , setActiveItem }) => {
    const [selectedGoal, setSelectedGoal] = useState(null);
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [selectedContentProvide, setSelectedContentProvide] = useState(null);
    const [hasLandingWebsite, setHasLandingWebsite] = useState(true);
    const [maxPrice, setMaxPrice] = useState(hasLandingWebsite ? 10000 : 15000);
    const [priceRange, setPriceRange] = useState([0, maxPrice]);
    const [isPriceSelected, setIsPriceSelected] = useState(true);
    const [files, setFiles] = useState([])
    const [previewURLs, setPreviewURLs] = useState([]);
    const [imgArr, setImageArr] = useState([])
    const fileInputRef = useRef(null);
    const [hasLandingPage, setHasLandingPage] = useState(false);
    const [brandWebsite, setBrandWebsite] = useState('');
    const [instagramHandle, setInstagramHandle] = useState('');
    // const isNextDisabled = brandWebsite.trim() === '' || instagramHandle.trim() === '';
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const isCampNextDisabled = campaignDescription.trim() === '';
    const [contentOption, setContentOption] = useState('');
    const [isConentTypeSelected, setIsContentTypeSelected] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [isSelectedType, setIsSelectedType] = useState(true);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
    const [ispaymentSElected, setIsPaymentSelected] = useState(true);
    const [selectedAges, setSelectedAges] = useState([]);
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [suggestionText, setSuggestionText] = useState('');

console.log("has landing page",hasLandingWebsite)

    const userProfile = JSON.parse(localStorage.getItem('user'));
    console.log("user profile", userProfile.email)

    useEffect(() => {
        if (userProfile) {
            setSuggestionText(`${userProfile.company_name} ${selectedGoal} Campaign`);
        }
    }, [selectedGoal, userProfile]);

    const initialFormData = {
        user: null,
        creator_brand: null,
        goal: null,
        campaign_type: null,
        landing_page: false,
        landing_page_link: '',
        audience_type: '',
        age_range: 'All',
        gender: 'All',
        location: 'India',
        brand_website: '',
        brand_instahandle: '',
        campaign_name: suggestionText,
        campaign_description: '',
        content: '',
        content_type: '',
        content_type_file: null,
        budget: 0,
        amount_paid: 0,
        call_request: false,
        collection: '',
        selected_ages: '',
        selected_genders: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const updateFormData = (field, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };
    console.log("form data is ", formData)

    const handleUserSelection = (userId) => {
        updateFormData('user', userId);
    };




    const handlePostData = async () => {
        console.log('Form Data:', formData);

        // Make the POST request using formData
        try {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                if (value !== null && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                    if (Array.isArray(value)) {
                        value.forEach((file, index) => {
                            formDataToSend.append(`${key}_${index}`, file);
                        });
                    } else {
                        formDataToSend.append(key, value);
                    }
                }
            });

            console.log("form data to send", formDataToSend)
            const response = await fetch('https://brich.youthevents.in/Bdashboard/create-campaign/', {
                method: 'POST',
                body: formDataToSend, // Modify as needed based on backend expectations
                // headers: {
                //     'Content-Type': 'application/json',
                // },
            });

            if (response.ok) {
                console.log('Campaign created successfully');
                handleNextStep()
            } else {
                console.error('Failed to create campaign');
            }
        } catch (error) {
            console.error('Error creating campaign:', error);
        }
    };

    const [goalMetrics, setGoalMetrics] = useState(null);

    useEffect(() => {
        axios.get('https://brich.youthevents.in/Bdashboard/api/get-all-goal-metrics/')
            .then(response => {
                setGoalMetrics(response.data);
                console.log("data of metrix", response.data);
            })
            .catch(error => {
                console.error('Error fetching goal metrics:', error);
            });
    }, []);
    const [sliderValue, setSliderValue] = useState(hasLandingWebsite ? 10000 : 15000);
    console.log("maxprice",priceRange)
    const [minAccountsReached, setMinAccountsReached] = useState(31120);
    const [maxAccountsReached, setMaxAccountsReached] = useState(40441);

    const handlePriceChange = (value) => {
        setPriceRange(value);
        setIsPriceSelected(false);
        updateFormData('budget', value);

        // Check if goalMetrics is loaded
        if (goalMetrics) {
            // Get selected goal's metrics
            const selectedGoalMetrics = goalMetrics[selectedGoal];

            // if (hasLandingPage) {
            //     selectedGoalMetrics.base_price = selectedGoalMetrics.base_price += 5000;
            // }
            // Calculate dynamic limits
            const minAccountsReached = selectedGoalMetrics.min_accounts_reached + (value - selectedGoalMetrics.base_price) * selectedGoalMetrics.min_views_per_rupee;
            const maxAccountsReached = selectedGoalMetrics.max_accounts_reached + (value - selectedGoalMetrics.base_price) * selectedGoalMetrics.max_views_per_rupee;


            // Update dynamic limits in the UI
            setMinAccountsReached(minAccountsReached);
            setMaxAccountsReached(maxAccountsReached);

            // Similarly, calculate other dynamic values based on the selected goal and handle them accordingly
        }
    };

    const handleGoalSelection = (goal) => {
        setSelectedGoal(goal);
        updateFormData('goal', goal);
        handleUserSelection(userProfile.id)
        updateFormData('creator_brand', userProfile.company_name);
        updateFormData('campaign_type', "Smart Campaign");




    };

    const handleContentTypeSelection = (contentType) => {
        setSelectedContentType(contentType);
        updateFormData('content_type', contentType);

    };

    const handleContentProvideSelection = (contentProvide) => {
        setSelectedContentProvide(contentProvide);
        updateFormData('content', contentProvide);


    };

    console.log("selected goal is ", selectedGoal);

    const [checkedAllLocation, setCheckedAllLocation] = useState(true);
    const [checkedItems, setCheckedItems] = useState('All'); // State to track checked items



    // ... other functions

    // Function to handle landing page selection
    const handleLandingPageSelection = (value) => {
        if (value === 'yes') {
            setHasLandingPage(true);
            setHasLandingWebsite(true)
            updateFormData('landing_page', true);
        } else if (value === 'no') {
            setHasLandingPage(true);
            setHasLandingWebsite(false)
            updateFormData('landing_page', false);
            setMaxPrice(15000)     
          }
    };

    const [BrandswebsiteStart, setBrandswebsiteStart] = useState(false);
    const [Intstagramhandlestart, setIntstagramhandlestart] = useState(false);

    // Function to handle brand website change
    const handleBrandWebsiteChange = (event) => {
        setBrandswebsiteStart(true)
        let url = event.target.value;
        // Check if the URL doesn't start with http:// or https://
        if (!url.match(/^https?:\/\//i)) {
            // If not, prepend "https://"
            url = "https://" + url;
        }
        setBrandWebsite(url);
        updateFormData('brand_website', url);
    };


    // Function to handle Instagram handle change
    const handleInstagramHandleChange = (event) => {
        setIntstagramhandlestart(true)
        setInstagramHandle(event.target.value);
        updateFormData('brand_instahandle', event.target.value);


    };

    const [campaignnameStart, setcampaignnameStart] = useState('');
    const [descriptionStart, setdescriptionStart] = useState('');

    // Function to handle campaign name change
    const handleCampaignNameChange = (event) => {
        setcampaignnameStart(true)
        const newValue = event.target.value;
        setCampaignName(newValue);
        updateFormData('campaign_name', newValue !== suggestionText ? newValue : suggestionText);
        // Update form data with changed value, or user's company name if unchanged
    };

    // Function to handle campaign description change
    const handleCampaignDescriptionChange = (event) => {
        setdescriptionStart(true)
        setCampaignDescription(event.target.value);
        updateFormData('campaign_description', event.target.value);
        updateFormData('campaign_name', suggestionText);
        updateFormData('gender', selectedGenders && selectedGenders.join(",") || "All" );
        updateFormData('age_range', selectedAges && selectedAges.join(",") || "All");
        updateFormData('location', selectedStates || "India");

    };

    // Function to handle option change
    const handleOptionChange = (event) => {
        setContentOption(event.target.value);
        setIsContentTypeSelected(true);
        updateFormData('content', event.target.value);
    };


    const [selectedStates, setSelectedStates] = useState('All');

    console.log("selected states", selectedStates)

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({ ...checkedItems, [name]: checked });
        updateSelectedStates(name, checked);

    };

    const handleAllLocationCheckbox = () => {
        const updatedCheckedItems = {};
        const allChecked = !checkedAllLocation;
        statesOfIndia.forEach(state => {
            updatedCheckedItems[state] = allChecked;
        });
        setCheckedItems(updatedCheckedItems);
        setCheckedAllLocation(allChecked);
        updateSelectedStates('All', allChecked);
        setSelectedStates("")
    };

    const updateSelectedStates = (state, checked) => {
        if (checked) {
            if (selectedStates === 'All') {
                setSelectedStates(state);
            } else {
                setSelectedStates(selectedStates + ` ${state} `);
            }
        } else {
            if (selectedStates.includes(state)) {
                const updatedStates = selectedStates.replace(state, '').replace(/,\s*,/, ',').replace(/^,|,$/g, '');
                setSelectedStates(updatedStates);
            }
        }
    };


    const handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            const file = files[0]; // Only consider the first file for preview
            setImageArr([...files]);
            previewVideo([...files]);
            updateFormData('content_type_file', [...files]);

        }
    };


    const handleDragOver = (event) => {
        event.preventDefault()

    }



    const previewVideo = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewURLs([reader.result]);

        };
        reader.readAsDataURL(file);
        // updateFormData('content_type_file', file);

    };

    const handleFileChange = (event) => {
        const selectedFiles = event.target.files
        setFiles(selectedFiles);

        const filesArray = Array.from(selectedFiles);

        updateFormData('content_type_file', filesArray);

        console.log("Selected video files:", filesArray);

        const firstSelectedFile = filesArray[0];
        if (firstSelectedFile) {
            previewVideo(firstSelectedFile);
        }
    };

    console.log("filess0", files)

    const handleBrowseClick = () => {
        document.getElementById('fileInput').click(); // Trigger the file input click event
    };

    const handleFileChange1 = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const fileURLs = selectedFiles.map((file) => URL.createObjectURL(file));
        setImageArr(fileURLs);
        updateFormData('content_type_file', selectedFiles);

    };

    const browseButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleTypeSelection = (type) => {
        setSelectedType(type);
        setIsSelectedType(false);
        updateFormData('content_type', type); // Update the content_type in the form data

    };



    const handlePaymentOptionChange = (option) => {
        setSelectedPaymentOption(option);
        setIsPaymentSelected(false); // Enable the Next button once a payment option is selected

        if (option === 'Pay Upfront') {
            updateFormData('amount_paid', priceRange);
            updateFormData('call_request', false);
        } else if (option === 'Set up a Call') {
            updateFormData('amount_paid', 0); // Update the amount paid when Set up a Call is selected
            updateFormData('call_request', true); // Set call request to true when Set up a Call is selected
        }
    };

    const handleAgeCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === 'All') {
            if (checked) {
                setSelectedAges(['All', '18-24', '25-34', '35-44', '45-64', '65+']);
            } else {
                setSelectedAges([]);
            }
        } else {
            if (checked) {
                setSelectedAges([...selectedAges, name]);
            } else {
                setSelectedAges(selectedAges.filter((age) => age !== name && age !== 'All'));
            }
        }
    };

    const handleGenderCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === 'All') {
            if (checked) {
                setSelectedGenders(['All', 'Male', 'Female', 'Others']);
            } else {
                setSelectedGenders([]);
            }
        } else {
            if (checked) {
                setSelectedGenders([...selectedGenders, name]);
            } else {
                setSelectedGenders(selectedGenders.filter((gender) => gender !== name && gender !== 'All'));
            }
        }
    };

const[landing_pageStart , setlandingpageStart] = useState(false)

    const handleLandingPageLinkChange = (value) => {
        setlandingpageStart(true)
        let landingPageLink = value;
        // Check if the URL doesn't start with http:// or https://
        if (!landingPageLink.match(/^https?:\/\//i)) {
            // If not, prepend "https://"
            landingPageLink = "https://" + landingPageLink;
        }
        updateFormData('landing_page_link', landingPageLink);
    };


    const handlePayment = async () => {


        console.log("imside payment")
        const paymentData = {
            // Provide necessary payment details
            amount_paid: priceRange,
        };

        try {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                if (value !== null && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                    if (Array.isArray(value)) {
                        value.forEach((file, index) => {
                            formDataToSend.append(`${key}_${index}`, file);
                        });
                    } else {
                        formDataToSend.append(key, value);
                    }
                }
            });

            console.log("form data to send", formDataToSend)
            // Initiate payment using Payment API
            const paymentResponse = await axios.post('https://brich.youthevents.in/Bdashboard/pay/', paymentData ,formDataToSend);
            const paymentStatus = paymentResponse.status; // Assuming the payment response contains status
            console.log("response", paymentResponse)

            if (paymentStatus === 201 && paymentResponse.data.data.instrumentResponse.redirectInfo.url) {
                const paymentUrl = paymentResponse.data.data.instrumentResponse.redirectInfo.url;
                // window.open(paymentUrl, '_blank');
                window.open(paymentUrl, 'Payment', 'width=600,height=700');
                // setIframeSrc(paymentUrl);
            } else {
                // Handle case where there's no redirect URL or payment initiation failed
                console.error('Payment initiation failed or no redirect URL provided:', paymentResponse.data);
            }
        } catch (error) {
            // Handle errors
            console.error('Error initiating payment:', error);
        }
    };


    const isgoalDisabled = selectedGoal === null

    console.log("current step", step)

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.substring(0, maxLength - 3) + '...';
        } else {
            return str;
        }
    }





    const renderStepContent = () => {
        switch (step) {
            //  ----------------------  case1 ---------------------------------------------------------------------------------------------------
            case 1:
                return (
                    <div className='smarts step1'>
                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Select Your Goals</h3>
                                </div>
                            </div>
                            <div className='next' style={{ backgroundColor: isgoalDisabled ? 'grey' : '' }} onClick={isgoalDisabled ? null : handleNextStep} disabled={!isgoalDisabled}>Next</div>
                        </div>
                        <div className="working flex">
                            <div className="goals cflex" onClick={() => handleGoalSelection('Lead Generation')}>
                                <input type="radio" checked={selectedGoal === 'Lead Generation'} readOnly />
                                <img src={lead} alt="" />
                                <h3>Lead Generation</h3>
                                <p>Identify potential Influencers and creators who will be the best suitable source for promoting your Brand.</p>
                            </div>
                            <div className="goals cflex" onClick={() => handleGoalSelection('Brand Awareness')}>
                                <input type="radio" checked={selectedGoal === 'Brand Awareness'} readOnly />
                                <img src={bran} alt="" />
                                <h3>Brand Awareness</h3>
                                <p>Let your brand be the hero for a successful business in the market.</p>
                            </div>
                            <div className="goals cflex" onClick={() => handleGoalSelection('Product Sales')}>
                                <input type="radio" checked={selectedGoal === 'Product Sales'} readOnly />
                                <img src={product} alt="" />
                                <h3>Product Sales</h3>
                                <p>Grow your sales with the best possible tools and services.</p>
                            </div>
                            <div className="goals cflex" onClick={() => handleGoalSelection('Website Visits')}>
                                <input type="radio" checked={selectedGoal === 'Website Visits'} readOnly />
                                <img src={website} alt="" />
                                <h3>Website Visits</h3>
                                <p>Boost your website visits, , One Click at a Time</p>
                            </div>
                        </div>
                        <div className='next1' onClick={handleNextStep}>Next</div>

                    </div>
                );
            case 2:
                //  ----------------------  case2 ---------------------------------------------------------------------------------------------------

                if (selectedGoal === 'Lead Generation') {
                    return (
                        <div className='smarts step2'>

                            <div className="description">
                                <div className="desc">
                                    <div className='general'>
                                        <h3>Lead Generation</h3>
                                    </div>
                                    <p> Identify potential Influencers and creators who will be the best suitable source for promoting your Brand.</p>
                                </div>
                                <div className="backBtn">
                                    <div className="back" onClick={handlePreviousStep}>Back</div>
                                    <div className='next' style={{ backgroundColor: hasLandingPage ? '' : 'grey' }} onClick={hasLandingPage ? handleNextStep : null} disabled={!hasLandingPage}>Next</div>
                                </div>
                            </div>
                            <div className="working cflex landing leadw">
                                <h3>Do You Have a Landing Page ?</h3>
                                <div className="asking flex">
                                    <div className="ask cflex">
                                        <input type="radio" readOnly name="landingPage" onClick={() => handleLandingPageSelection('yes')} />
                                        <h3>Yes, I do</h3>
                                        <p>Add your link to the landing page below</p>
                                        <input
                                            type="text"
                                            name="landingPageLink"
                                            id="landingPageLink"
                                            placeholder="eg: www.example.in"
                                            readOnly={!hasLandingPage}
                                            value={formData.landing_page_link} // Set the value from form data
                                            onChange={(e) => handleLandingPageLinkChange(e.target.value)} // Update form data on change
                                            className={landing_pageStart ? "cbl" : ""}
                                        />

                                    </div>
                                    <div className="ask cflex">
                                        <input type="radio" readOnly name="landingPage" onClick={() => handleLandingPageSelection('no')} />
                                        <h3>No</h3>
                                        <p>Get one Created.</p>
                                        <p>Our team can help you design a professional landing page tailored to your campaign.</p>
                                    </div>


                                </div>
                            </div>
                            <div className="backBtn" style={{ marginTop: "32px" }}>
                                <div className="backs" onClick={handlePreviousStep}>Back</div>

                                <div className='next1' style={{ backgroundColor: hasLandingPage ? '' : 'grey' }} onClick={hasLandingPage ? handleNextStep : null} disabled={!hasLandingPage}>Next</div>
                            </div>

                        </div>
                    );


                } else {
                    handleNextStep()
                }

            case 3:
                //  ----------------------  case3 ---------------------------------------------------------------------------------------------------
                // to do add editor
                return (
                    <div className='smarts step2'>

                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Define Audience Type</h3>
                                </div>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                <div className='next' onClick={handleNextStep}>Next</div>
                            </div>
                        </div>
                        <div className="working flex" style={{ justifyContent: "space-around" }}>
                            <div className="age cflex">
                                Age
                                <div className="age-head">
                                    {selectedAges?.includes('All') ? 'All' : selectedAges?.length > 0 ? truncateString(selectedAges.join(', '), 14) : 'All'}
                                </div>
                                <div className="range" style={{ paddingLeft: "0px" }}>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="All" onChange={handleAgeCheckboxChange} checked={selectedAges.includes('All')} /> All</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="18-24" onChange={handleAgeCheckboxChange} checked={selectedAges.includes('18-24')} /> 18-24</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="25-34" onChange={handleAgeCheckboxChange} checked={selectedAges.includes('25-34')} /> 25-34</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="35-44" onChange={handleAgeCheckboxChange} checked={selectedAges.includes('35-44')} /> 35-44</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="45-64" onChange={handleAgeCheckboxChange} checked={selectedAges.includes('45-64')} /> 45-64</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="65+" onChange={handleAgeCheckboxChange} checked={selectedAges.includes('65+')} /> 65+</div>
                                </div>
                            </div>

                            <div className="gender cflex">
                                Gender
                                <div className="gender-head">
                                    {selectedGenders?.includes('All') ? 'All' : selectedGenders?.length > 0 ? truncateString(selectedGenders.join(', '), 14) : 'All'}
                                </div>
                                <div className="genders">
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="All" onChange={handleGenderCheckboxChange} checked={selectedGenders.includes('All')} /> All</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="Male" onChange={handleGenderCheckboxChange} checked={selectedGenders.includes('Male')} /> Male</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="Female" onChange={handleGenderCheckboxChange} checked={selectedGenders.includes('Female')} /> Female</div>
                                    <div className="rang"><input type='checkbox' className='custom-checkbox1' name="Others" onChange={handleGenderCheckboxChange} checked={selectedGenders.includes('Others')} /> Others</div>
                                </div>
                            </div>

                            <div className="location cflex">
                                Location
                                <div className="location-head">    
                                    {checkedAllLocation ? 'All' : truncateString(selectedStates, 20)}
                                </div>
                                <div className="locations">
                                    <div className="all-fix rang">
                                        <input
                                            type='checkbox'
                                            checked={checkedAllLocation}
                                            onChange={handleAllLocationCheckbox}
                                        />
                                        All
                                    </div>
                                    {statesOfIndia.map((state) => (
                                        <div className="rang" key={state}>
                                            <input
                                                type='checkbox'
                                                name={state}
                                                checked={checkedItems[state] || false}
                                                onChange={handleCheckboxChange}
                                            />
                                            {state}
                                        </div>
                                    ))}
                                </div>
                            </div>


                        </div>
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>

                            <div className='next1' onClick={handleNextStep}>Next</div>
                        </div>
                    </div>
                );
            case 4:
                //  ----------------------  case3 ---------------------------------------------------------------------------------------------------
                // to do add editor
                return (
                    <div className='smarts step2'>

                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Add Brands Details</h3>
                                </div>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                {/* <div className='next' style={{ backgroundColor: isNextDisabled ? 'grey' : '' }} onClick={isNextDisabled ? null : handleNextStep} disabled={!isNextDisabled}>Next</div> */}
                                <div className='next' onClick={handleNextStep} >Next</div>
                            </div>
                        </div>
                        <div className="working flex">
                            <div className="link-insta ">
                                <div className="link">
                                    <h3>Add the link for brands website / product.</h3>
                                    <input
                                        type="text"
                                        placeholder='eg: www.yourbrand.com'
                                        value={brandWebsite}
                                        onChange={handleBrandWebsiteChange}
                                        className={BrandswebsiteStart ? "cbl" : ""}
                                    />
                                </div>
                                <div className="link">
                                    <h3>Please Provide Your Instagram Handle.</h3>
                                    <input
                                        type="text"
                                        placeholder='eg: www.instagram.com/yourbrand'
                                        value={instagramHandle}
                                        onChange={handleInstagramHandleChange}
                                        className={Intstagramhandlestart ? "cbl" : ""}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>

                            <div className='next1' onClick={handleNextStep} >Next</div>
                        </div>

                    </div>
                );
            case 5:
                //  ----------------------  case3 ---------------------------------------------------------------------------------------------------
                // to do add editor
                return (
                    <div className='smarts step2'>
                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Campaign Name</h3>
                                </div>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                <div className='next' style={{ backgroundColor: isCampNextDisabled ? 'grey' : '' }} onClick={isCampNextDisabled ? null : handleNextStep} disabled={!isCampNextDisabled}>Next</div>
                            </div>
                        </div>
                        <div className="working flex">
                            <div className="link-insta">
                                <div className="link">
                                    <h3>Add a Campaign Name</h3>
                                    <input
                                        type="text"
                                        placeholder='Your Campaign Name'
                                        value={campaignName || suggestionText} // Set value based on user.company_name as suggestion text
                                        onChange={handleCampaignNameChange}
                                        className="cbl"
                                    />
                                </div>
                                <div className="link">
                                    <h3>Add Campaign Description</h3>
                                    <input
                                        type="text"
                                        placeholder='Jot Down the Campaign Details'
                                        value={campaignDescription}
                                        onChange={handleCampaignDescriptionChange}
                                        className={descriptionStart ? "cbl" : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>
                            <div className='next1' style={{ backgroundColor: isCampNextDisabled ? 'grey' : '' }} onClick={isCampNextDisabled ? null : handleNextStep} disabled={!isCampNextDisabled}>Next</div>
                        </div>
                    </div>
                );

            case 6:
                //  ----------------------  case4 ---------------------------------------------------------------------------------------------------
                return (
                    <div className='smarts step2'>
                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Content Type</h3>
                                </div>
                                <p>Choose how you want to provide content for your campaign.</p>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                <div className='next' style={{ backgroundColor: isConentTypeSelected ? '' : 'grey' }} onClick={isConentTypeSelected ? handleNextStep : null} disabled={!isConentTypeSelected}>Next</div>
                            </div>
                        </div>
                        <div className="owns flex">
                            <div className="own flex">
                                <input
                                    type="radio"
                                    id="ownContent"
                                    name="contentType"
                                    value="Provided"
                                    checked={contentOption === 'Provided'}
                                    onChange={handleOptionChange}
                                />
                                <label htmlFor="ownContent">
                                    <h3>Provide Own Content.</h3>
                                    <p>Upload your own content that you want the influencers to share.</p>
                                </label>
                            </div>
                            <div className="own flex">
                                <input
                                    type="radio"
                                    id="creatorContent"
                                    name="contentType"
                                    value="Own"
                                    checked={contentOption === 'Own'}
                                    onChange={handleOptionChange}
                                />
                                <label htmlFor="creatorContent">
                                    <h3>Ask Creators to Make Content.</h3>
                                    <p>Allow influencers to create and share their own content based on your guidelines.</p>
                                </label>
                            </div>
                        </div>
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>
                            <div className='next1' style={{ backgroundColor: isConentTypeSelected ? '' : 'grey' }} onClick={isConentTypeSelected ? handleNextStep : null} disabled={!isConentTypeSelected}>Next</div>
                        </div>
                    </div>
                );
            case 7:
                //  ----------------------  case5 ---------------------------------------------------------------------------------------------------

                if (contentOption === 'Own') {

                    return (
                        <div className='smarts step5'>
                            <div className="description">
                                <div className="desc">
                                    <div className='general'>
                                        <h3>Choose Content Type</h3>
                                    </div>
                                    <p>Select the type of content you want for your campaign.</p>
                                </div>
                                <div className="backBtn">
                                    <div className="back" onClick={handlePreviousStep}>Back</div>
                                    <div className='next' style={{ backgroundColor: isSelectedType ? 'grey' : '' }} onClick={isSelectedType ? null : handleNextStep} disabled={!isSelectedType}>Next</div>
                                </div>
                            </div>
                            <div className="types flex">
                                <div className="c-type">
                                    <input
                                        type="radio"
                                        checked={selectedType === 'Reels'}
                                        onChange={() => handleTypeSelection('Reels')}
                                        readOnly
                                    />
                                    <img src={reel} alt="" />
                                    <h3>Reels</h3>
                                    <p>Short, engaging videos ideal for quick storytelling and capturing attention.</p>
                                </div>
                                <div className="c-type">
                                    <input
                                        type="radio"
                                        checked={selectedType === 'Post'}
                                        onChange={() => handleTypeSelection('Post')}
                                        readOnly
                                    />
                                    <img src={post} alt="" />
                                    <h3>Post</h3>
                                    <p>Traditional posts perfect for detailed descriptions and high-quality images.</p>
                                </div>
                                <div className="c-type">
                                    <input
                                        type="radio"
                                        checked={selectedType === 'Story'}
                                        onChange={() => handleTypeSelection('Story')}
                                        readOnly
                                    />
                                    <img src={story} alt="" />
                                    <h3>Story</h3>
                                    <p>Temporary content that is great for time-sensitive promotions and behind-the-scenes looks.</p>
                                </div>
                            </div>
                            <div className="backBtn" style={{ marginTop: "32px" }}>
                                <div className="backs" onClick={handlePreviousStep}>Back</div>
                                <div className='next1' style={{ backgroundColor: isSelectedType ? 'grey' : '' }} onClick={isSelectedType ? null : handleNextStep} disabled={!isSelectedType}>Next</div>
                            </div>
                        </div>
                    );

                }

                return (
                    <div className='smarts step5'>
                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Choose Content Type</h3>
                                </div>
                                <p>Select the type of content you want for your campaign.</p>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                <div className='next' style={{ backgroundColor: isSelectedType ? 'grey' : '' }} onClick={isSelectedType ? null : handleNextStep} disabled={!isSelectedType}>Next</div>
                            </div>
                        </div>
                        <div className="types flex">
                            <div className="c-type">
                                <input
                                    type="radio"
                                    checked={selectedType === 'Reels'}
                                    onChange={() => handleTypeSelection('Reels')}
                                    readOnly
                                />
                                <img src={reel} alt="" />
                                <h3>Reels</h3>
                                <p>Short, engaging videos ideal for quick storytelling and capturing attention.</p>
                            </div>
                            <div className="c-type">
                                <input
                                    type="radio"
                                    checked={selectedType === 'Post'}
                                    onChange={() => handleTypeSelection('Post')}
                                    readOnly
                                />
                                <img src={post} alt="" />
                                <h3>Post</h3>
                                <p>Traditional posts perfect for detailed descriptions and high-quality images.</p>
                            </div>
                            <div className="c-type">
                                <input
                                    type="radio"
                                    checked={selectedType === 'Story'}
                                    onChange={() => handleTypeSelection('Story')}
                                    readOnly
                                />
                                <img src={story} alt="" />
                                <h3>Story</h3>
                                <p>Temporary content that is great for time-sensitive promotions and behind-the-scenes looks.</p>
                            </div>
                        </div>
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>
                            <div className='next1' style={{ backgroundColor: isSelectedType ? 'grey' : '' }} onClick={isSelectedType ? null : handleNextStep} disabled={!isSelectedType}>Next</div>
                        </div>
                    </div>
                );
            case 8:
                //  ----------------------  case6 ---------------------------------------------------------------------------------------------------
                if (contentOption === 'Own') {
                   
                    handleNextStep(); // Proceed to the subsequent step
                }

                if (selectedType === 'Reels') {
                    return (
                        <div className='smarts step5'>
                            <div className="description">
                                <div className="desc">
                                    <div className='general'>
                                        <h3>Upload Your Reel</h3>
                                    </div>
                                    <p style={{ maxWidth: "479px" }}>*upload video, max duration 90 seconds, makes sure the resolution ratio is 9:16 (portrait) , You can upload multiple videos.</p>
                                </div>
                                <div className="backBtn">
                                    <div className="back" onClick={handlePreviousStep}>Back</div>
                                    <div className='next' style={{ backgroundColor: files.length == 0 ? 'grey' : '' }} onClick={files.length == 0 ? null : handleNextStep} disabled={!files.length == 0}>Next</div>
                                </div>
                            </div>
                            <div className="posts flex">
                                <div className="post cflex"
                                    onDragOver={handleDragOver} onDrop={handleDrop}
                                >
                                    <img src={upoad} alt="" />
                                    <p>Drag & Drop or</p>
                                    <div className="browse" onClick={handleBrowseClick}>Browse</div>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        accept="video/*"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        multiple
                                    />

                                </div>

                                {files.length == 0 ? (
                                    <div className="post1  flex">
                                        <div className="preview">Preview</div>

                                        {files.length === 0 && <p>Your preview will generate here</p>}
                                    </div>

                                ) : (

                                    <div className="pre">
                                        <div className="ipost cflexx">
                                            <img src={reels} alt="" className='reels-review' />
                                            <VideoSlider videos={files} />

                                        </div>

                                    </div>
                                )}

                            </div>
                            <div className="backBtn" style={{ marginTop: "32px" }}>
                                <div className="backs" onClick={handlePreviousStep}>Back</div>

                                <div className='next1' style={{ backgroundColor: files.length == 0 ? 'grey' : '' }} onClick={files.length == 0 ? null : handleNextStep} disabled={!files.length == 0}>Next</div>
                            </div>

                        </div>
                    );
                } else if (selectedType === 'Post') {
                    return (
                        <div className='smarts step5'>
                            <div className="description">
                                <div className="desc">
                                    <div className='general' style={{ maxWidth: "240px" }}>
                                        <h3>Upload Images For Post</h3>
                                    </div>
                                    <p style={{ maxWidth: "416px" }}>*upload photos, makes sure the resolution ratio is 4:4 (square) , You can upload multiple Images.</p>
                                </div>
                                <div className="backBtn">
                                    <div className="back" onClick={handlePreviousStep}>Back</div>
                                    <div className='next' style={{ backgroundColor: imgArr.length == 0 ? 'grey' : '' }} onClick={imgArr.length == 0 ? null : handleNextStep} disabled={!imgArr.length == 0}>Next</div>
                                </div>
                            </div>
                            <div className="posts flex">
                                <div className="post cflex" onDragOver={handleDragOver} onDrop={handleDrop}>
                                    <img src={upoad} alt="" />
                                    <p>Drag & Drop or</p>
                                    <div className="browse" onClick={browseButtonClick} >Browse</div>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange1}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                    />


                                </div>
                                {imgArr.length == 0 ? (
                                    <div className="post1 pos flex">
                                        <div className="preview">Preview</div>

                                        {imgArr.length === 0 && <p>Your preview will generate here</p>}
                                    </div>

                                ) : (

                                    <div className="pre1">
                                        <div className="ipost cflexx">
                                            <img src={posts} alt="" className="insta-post" />
                                            {/* <img src={URL.createObjectURL(file)} alt={file.name} className='post-result' /> */}

                                            <ImageSilder images={imgArr} />

                                        </div>

                                    </div>
                                )}

                            </div>
                            <div className="backBtn" style={{ marginTop: "32px" }}>
                                <div className="backs" onClick={handlePreviousStep}>Back</div>

                                <div className='next1' style={{ backgroundColor: imgArr.length == 0 ? 'grey' : '' }} onClick={imgArr.length == 0 ? null : handleNextStep} disabled={!imgArr.length == 0}>Next</div>
                            </div>
                        </div>
                    );
                } else if (selectedType === 'Story') {
                    return (
                        <div className='smarts step5'>
                            <div className="description">
                                <div className="desc">
                                    <div className='general' style={{ maxWidth: "351px" }}>
                                        <h3>Upload Image for Instagram Stories</h3>
                                    </div>
                                    <p style={{ maxWidth: "479px" }}>*upload image, stories stay around 24 hours on the page, makes sure the resolution ratio is 9:16 (portrait) , You can upload multiple Images.</p>
                                </div>
                                <div className="backBtn">
                                    <div className="back" onClick={handlePreviousStep}>Back</div>
                                    <div className='next' style={{ backgroundColor: imgArr.length == 0 ? 'grey' : '' }} onClick={imgArr.length == 0 ? null : handleNextStep} disabled={!imgArr.length == 0}>Next</div>
                                </div>
                            </div>
                            <div className="posts flex">
                                <div className="post cflex" onDragOver={handleDragOver} onDrop={handleDrop}>
                                    <img src={upoad} alt="" />
                                    <p>Drag & Drop or</p>
                                    <div className="browse" onClick={browseButtonClick} >Browse</div>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange1}
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />


                                </div>
                                {imgArr.length == 0 ? (
                                    <div className="post1  flex">
                                        <div className="preview">Preview</div>

                                        {imgArr.length === 0 && <p>Your preview will generate here</p>}
                                    </div>

                                ) : (

                                    <div className="pre">
                                        <div className="ipost cflexx">
                                            <img src={stori} alt="" />
                                            {/* <img src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '255px', height: "454px" }} /> */}
                                            <ImageSilder images={imgArr} />


                                        </div>

                                    </div>
                                )}

                            </div>
                            <div className="backBtn" style={{ marginTop: "32px" }}>
                                <div className="backs" onClick={handlePreviousStep}>Back</div>

                                <div className='next1' style={{ backgroundColor: imgArr.length == 0 ? 'grey' : '' }} onClick={imgArr.length == 0 ? null : handleNextStep} disabled={!imgArr.length == 0}>Next</div>
                            </div>
                        </div>
                    );
                }


            case 9:
                //  ----------------------  case7 ---------------------------------------------------------------------------------------------------
                return (
                    <div className='smarts step7'>
                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Set a Budget</h3>
                                </div>
                                <p>Adjust your budget to see estimated results for your campaign.</p>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                <div className='next' style={{ backgroundColor: isPriceSelected ? 'grey' : '' }} onClick={isPriceSelected ? null : handleNextStep} disabled={isPriceSelected}>Next</div>
                            </div>
                        </div>
                        {selectedGoal && (
                            <div className="budgets flex">

                                {selectedGoal === 'Lead Generation' && (
                                    <>
                                        <div className="set cflex ">
                                            <p>Set your desired budget using the slider below to see how it affects your campaign outcomes.</p>
                                            <div className="brange flex">
                                                <img src={edits} alt="" />
                                                <div className="cost">{priceRange} ₹</div>

                                            </div>
                                            <div className="discount cflex">
                                                <div className="discount-slider">
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Slider: {
                                                                    railBg: "#0C2D1A",
                                                                    trackBg: "rgba(90, 181, 129, 1)",
                                                                    handleColor: "#5AB581",
                                                                    handleActiveColor: "#5AB581",
                                                                    dotBorderColor: "#5AB581",
                                                                    dotActiveBorderColor: "#5AB581",
                                                                    trackHoverBg: "rgba(90, 181, 129, 1)",
                                                                    railHoverBg: "#0C2D1A"

                                                                }
                                                            },
                                                        }}
                                                    >
                                                        <Slider
                                                            step={100}
                                                            defaultValue={sliderValue}
                                                            min={hasLandingWebsite ? 10000 : 15000}
                                                            max={500000}
                                                            onChange={handlePriceChange}
                                                            style={{ railBg: "rgba(90, 181, 129, 1)", trackBg: "#0C2D1A;" }}
                                                        />
                                                    </ConfigProvider>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="result flex ">
                                            <div className="estimate cflex">
                                                <h3>Estimated Results</h3>
                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Accounts Reached</div>
                                                        <div className="limit-h">{minAccountsReached} - {maxAccountsReached}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>

                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Leads Generated</div>
                                                        <div className="limit-h">{Math.floor(minAccountsReached / 500)} - {Math.floor(maxAccountsReached / 250)}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>

                                            </div>



                                        </div>
                                    </>
                                )}
                                {selectedGoal === 'Brand Awareness' && (
                                    <>
                                        <div className="set cflex ">
                                            <p>Set your desired budget using the slider below to see how it affects your campaign outcomes.</p>
                                            <div className="brange flex">
                                                <img src={edits} alt="" />
                                                <div className="cost">{priceRange} ₹</div>

                                            </div>
                                            <div className="discount cflex">
                                                <div className="discount-slider">
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Slider: {
                                                                    railBg: "#0C2D1A",
                                                                    trackBg: "rgba(90, 181, 129, 1)",
                                                                    handleColor: "#5AB581",
                                                                    handleActiveColor: "#5AB581",
                                                                    dotBorderColor: "#5AB581",
                                                                    dotActiveBorderColor: "#5AB581",
                                                                    trackHoverBg: "rgba(90, 181, 129, 1)",
                                                                    railHoverBg: "#0C2D1A"

                                                                }
                                                            },
                                                        }}
                                                    >
                                                        <Slider
                                                            step={100}
                                                            defaultValue={10000}
                                                            min={10000}
                                                            max={500000}
                                                            onChange={handlePriceChange}
                                                            style={{ railBg: "rgba(90, 181, 129, 1)", trackBg: "#0C2D1A;" }}
                                                        />
                                                    </ConfigProvider>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="result flex ">
                                            <div className="estimate cflex">
                                                <h3>Estimated Results</h3>
                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Accounts Reached</div>
                                                        <div className="limit-h">{minAccountsReached} - {maxAccountsReached}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>


                                            </div>



                                        </div>
                                    </>
                                )}
                                {selectedGoal === 'Product Sales' && (
                                    <>
                                        <div className="set cflex ">
                                            <p>Set your desired budget using the slider below to see how it affects your campaign outcomes.</p>
                                            <div className="brange flex">
                                                <img src={edits} alt="" />
                                                <div className="cost">{priceRange} ₹</div>

                                            </div>
                                            <div className="discount cflex">
                                                <div className="discount-slider">
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Slider: {
                                                                    railBg: "#0C2D1A",
                                                                    trackBg: "rgba(90, 181, 129, 1)",
                                                                    handleColor: "#5AB581",
                                                                    handleActiveColor: "#5AB581",
                                                                    dotBorderColor: "#5AB581",
                                                                    dotActiveBorderColor: "#5AB581",
                                                                    trackHoverBg: "rgba(90, 181, 129, 1)",
                                                                    railHoverBg: "#0C2D1A"

                                                                }
                                                            },
                                                        }}
                                                    >
                                                        <Slider
                                                            step={100}
                                                            defaultValue={10000}
                                                            min={10000}
                                                            max={500000}
                                                            onChange={handlePriceChange}
                                                            style={{ railBg: "rgba(90, 181, 129, 1)", trackBg: "#0C2D1A;" }}
                                                        />
                                                    </ConfigProvider>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="result flex ">
                                            <div className="estimate cflex">
                                                <h3>Estimated Results</h3>
                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Accounts Reached</div>
                                                        <div className="limit-h">{minAccountsReached} - {maxAccountsReached}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>

                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Product Sales</div>
                                                        <div className="limit-h">{Math.floor(minAccountsReached / 1000)} - {Math.floor(maxAccountsReached / 500)}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>

                                            </div>



                                        </div>
                                    </>
                                )}
                                {selectedGoal === 'Website Visits' && (
                                    <>
                                        <div className="set cflex ">
                                            <p>Set your desired budget using the slider below to see how it affects your campaign outcomes.</p>
                                            <div className="brange flex">
                                                <img src={edits} alt="" />
                                                <div className="cost">{priceRange} ₹</div>

                                            </div>
                                            <div className="discount cflex">
                                                <div className="discount-slider">
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Slider: {
                                                                    railBg: "#0C2D1A",
                                                                    trackBg: "rgba(90, 181, 129, 1)",
                                                                    handleColor: "#5AB581",
                                                                    handleActiveColor: "#5AB581",
                                                                    dotBorderColor: "#5AB581",
                                                                    dotActiveBorderColor: "#5AB581",
                                                                    trackHoverBg: "rgba(90, 181, 129, 1)",
                                                                    railHoverBg: "#0C2D1A"

                                                                }
                                                            },
                                                        }}
                                                    >
                                                        <Slider
                                                            step={100}
                                                            defaultValue={10000}
                                                            min={10000}
                                                            max={500000}
                                                            onChange={handlePriceChange}
                                                            style={{ railBg: "rgba(90, 181, 129, 1)", trackBg: "#0C2D1A;" }}
                                                        />
                                                    </ConfigProvider>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="result flex ">
                                            <div className="estimate cflex">
                                                <h3>Estimated Results</h3>
                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Accounts Reached</div>
                                                        <div className="limit-h">{minAccountsReached} - {maxAccountsReached}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>

                                                <div className="acc-reach flex">
                                                    <div className="limit">
                                                        <div className="limith">Website Views</div>
                                                        <div className="limit-h">{Math.floor(minAccountsReached / 800)} - {Math.floor(maxAccountsReached / 300)}</div>

                                                    </div>
                                                    <img src={i} alt="" />

                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        )}
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>

                            <div className='next1' style={{ backgroundColor: isPriceSelected ? 'grey' : '' }} onClick={isPriceSelected ? null : handleNextStep} disabled={isPriceSelected}>Next</div>
                        </div>
                    </div>
                );

            case 10:
                //  ----------------------  case8 ---------------------------------------------------------------------------------------------------
                return (
                    <div className='smarts step2'>
                        <div className="description">
                            <div className="desc">
                                <div className='general'>
                                    <h3>Payment Summary</h3>
                                </div>
                                <p>Review your payment options and select your preferred method.</p>
                            </div>
                            <div className="backBtn">
                                <div className="back" onClick={handlePreviousStep}>Back</div>
                                <div className='next' style={{ backgroundColor: ispaymentSElected ? 'grey' : '' }} onClick={selectedPaymentOption === 'Pay Upfront' ? handlePayment : handlePostData} disabled={ispaymentSElected} >{selectedPaymentOption === 'Pay Upfront' ? 'next' : 'create'}</div>
                            </div>
                        </div>
                        <div className="owns flex">
                            <div className="own flex">
                                <div className="total">{priceRange} ₹</div>
                                <input
                                    type="radio"
                                    checked={selectedPaymentOption === 'Pay Upfront'}
                                    onChange={() => handlePaymentOptionChange('Pay Upfront')}
                                />
                                <h3>Pay Upfront</h3>
                                <p>Make a full payment now to proceed with your campaign.</p>
                            </div>
                            <div className="own flex">
                                <input
                                    type="radio"
                                    checked={selectedPaymentOption === 'Set up a Call'}
                                    onChange={() => handlePaymentOptionChange('Set up a Call')}
                                />
                                <img src={call} alt="" />
                                <h3>Set up a Call To Discuss</h3>
                                <p>Schedule a call with our team to discuss payment and campaign details.</p>
                            </div>
                        </div>
                        <div className="backBtn" style={{ marginTop: "32px" }}>
                            <div className="backs" onClick={handlePreviousStep}>Back</div>
                            <div className='next1' style={{ backgroundColor: ispaymentSElected ? 'grey' : '' }} onClick={selectedPaymentOption === 'Pay Upfront' ? handlePayment : handlePostData} disabled={ispaymentSElected}>{selectedPaymentOption === 'Pay Upfront' ? 'next' : 'create'}</div>
                        </div>
                    </div>
                );
            case 11:
                //  ----------------------  case9 ---------------------------------------------------------------------------------------------------
                return (
                    <div className='smarts step2 smarty cflex'>
                        <img src={celebrate} alt="" />
                        <div className="general" style={{ maxWidth: "248px" }}>
                            <h3>Your Campaign is Ready </h3>
                        </div>
                        <p>Our Team will contact you within next 24 Hours</p>
                        <div className="next next1" style={{ width: "120px", height: "32px", display: "flex" }} onClick={() => { setActiveComponent('manageCampaign'); setActiveItem('manageCampaign'); }}>Great</div>


                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="smart-campaign-steps">
            <div className="smart-heading" >
                <h3>SMART CAMPAIGN</h3>
                <p>Auto generated campaign </p>
            </div>

            {renderStepContent()}
        </div>
    );
};

export default SmartCampaign;
