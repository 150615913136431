import React from 'react'
import NotSigninleftNavbar from '../Navbar/SigninleftNavbar'
import "./policy.css"

const Terms = () => {

  return (
    <div className='general-right'>
      {/* <NotSigninleftNavbar /> */}
      <div className="right">
        <h3 className='pricavy-head'>TERMS AND CONDITION</h3>
        <div className="policy-box">
          <div className="privacy-wrap">

            <div className="privacies" >
              <h3>Introduction</h3>
              <p>These terms and conditions govern your use of the online platform "brands.brich.in," owned and operated by the partnership firm M/S BRICH.</p>
            </div>
            <div className="privacies">
              <h3>Platform Services</h3>
              <p>The Platform provides services to assist brands in starting their online sales and marketing campaigns using influencer marketing and other performance marketing techniques.</p>
            </div>
            <div className="privacies">
              <h3>Registration</h3>
              <p>To access certain features of the Platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and activities under your account.</p>
            </div>
            <div className="privacies">
              <h3>Use of Platform</h3>
              <p>You agree to use the Platform only for lawful purposes and in accordance with these Terms. You shall not use the Platform in any manner that could disable, overburden, damage, or impair the Platform or interfere with any other party's use of the Platform.</p>
            </div>
            <div className="privacies">
              <h3>Intellectual Property</h3>
              <p>All content, materials, and trademarks on the Platform are the property of BRICH or its licensors and are protected by intellectual property laws. Brands retain ownership of any content uploaded to the Platform but grant BRICH a license to use it for providing services.</p>
            </div>
            <div className="privacies">
              <h3>Influencer Engagement</h3>
              <p>Brands are solely responsible for selecting influencers and engaging them for promotional activities. BRICH does not guarantee campaign performance or results and is not liable for losses incurred by Brands.</p>
            </div>
            <div className="privacies">
              <h3>Fees and Payments</h3>
              <p>Fees for using the Platform and Services may apply and are subject to change. Payment terms will be specified in the agreement between BRICH and the Brand.</p>
            </div>
            <div className="privacies">
              <h3>Disclaimer of Warranties</h3>
              <p>The Platform and Services are provided "as is" without warranties of any kind. BRICH makes no warranties or representations about the accuracy or completeness of the content provided on the Platform.</p>
            </div>
            <div className="privacies">
              <h3>Limitation of Liability</h3>
              <p>BRICH shall not be liable for indirect, incidental, special, consequential, or punitive damages arising from your use of the Platform. BRICH's total liability to you for all claims shall not exceed the total amount paid by you for using the Services.</p>
            </div>
            <div className="privacies">
              <h3>Governing Law and Jurisdiction</h3>
              <p>These Terms shall be governed by the laws of the specified jurisdiction. Any disputes shall be subject to the exclusive jurisdiction of the courts in the specified jurisdiction.</p>
            </div>
            <div className="privacies">
              <h3>Modification of Terms</h3>
              <p>BRICH reserves the right to modify these Terms at any time by posting the updated Terms on the Platform. Your continued use of the Platform after any changes constitutes acceptance of the modified Terms.</p>
            </div>
            <div className="privacies">
              <h3>Contact Information</h3>
              <p>If you have any questions about these Terms or the Platform, you may contact BRICH at the provided email or address.</p>
            </div>

          </div>
        </div>


      </div>
    </div>
  )
}

export default Terms


{/* <div className="overlay1">
          <div className="quote-popups">
            <div className="cross" >
            </div>
            <h3 className='pheading'>
              Terms and Conditions
            </h3>

            <ul className="termss">
              <li>
                <h4>Introduction</h4>
                <p>
                  These terms and conditions govern your use of the online platform "brands.brich.in," owned and operated by the partnership firm M/S BRICH.
                </p>
              </li>
              <li>
                <h4>Platform Services</h4>
                <p>
                  The Platform provides services to assist brands in starting their online sales and marketing campaigns using influencer marketing and other performance marketing techniques.
                </p>
              </li>
              <li>
                <h4>Registration</h4>
                <p>
                  To access certain features of the Platform, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and activities under your account.
                </p>
              </li>
              <li>
                <h4>Use of Platform</h4>
                <p>
                  You agree to use the Platform only for lawful purposes and in accordance with these Terms. You shall not use the Platform in any manner that could disable, overburden, damage, or impair the Platform or interfere with any other party's use of the Platform.
                </p>
              </li>
              <li>
                <h4>Intellectual Property</h4>
                <p>
                  All content, materials, and trademarks on the Platform are the property of BRICH or its licensors and are protected by intellectual property laws. Brands retain ownership of any content uploaded to the Platform but grant BRICH a license to use it for providing services.
                </p>
              </li>
              <li>
                <h4>Influencer Engagement</h4>
                <p>
                  Brands are solely responsible for selecting influencers and engaging them for promotional activities. BRICH does not guarantee campaign performance or results and is not liable for losses incurred by Brands.
                </p>
              </li>
              <li>
                <h4>Fees and Payments</h4>
                <p>
                  Fees for using the Platform and Services may apply and are subject to change. Payment terms will be specified in the agreement between BRICH and the Brand.
                </p>
              </li>
              <li>
                <h4>Disclaimer of Warranties</h4>
                <p>
                  The Platform and Services are provided "as is" without warranties of any kind. BRICH makes no warranties or representations about the accuracy or completeness of the content provided on the Platform.
                </p>
              </li>
              <li>
                <h4>Limitation of Liability</h4>
                <p>
                  BRICH shall not be liable for indirect, incidental, special, consequential, or punitive damages arising from your use of the Platform. BRICH's total liability to you for all claims shall not exceed the total amount paid by you for using the Services.
                </p>
              </li>
              <li>
                <h4>Governing Law and Jurisdiction</h4>
                <p>
                  These Terms shall be governed by the laws of the specified jurisdiction. Any disputes shall be subject to the exclusive jurisdiction of the courts in the specified jurisdiction.
                </p>
              </li>
              <li>
                <h4>Modification of Terms</h4>
                <p>
                  BRICH reserves the right to modify these Terms at any time by posting the updated Terms on the Platform. Your continued use of the Platform after any changes constitutes acceptance of the modified Terms.
                </p>
              </li>
              <li>
                <h4>Contact Information</h4>
                <p>
                  If you have any questions about these Terms or the Platform, you may contact BRICH at the provided email or address.
                </p>
              </li>
            </ul>



          </div>
        </div> */}