import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import avatar from "../../media/place.jpg"
import SearchBar from '../Signin Home page/Signin components/Searchbar';
import SigninleftNavbar from '../SigninNavbar/SigninleftNavbar';
import CreatorMenu from '../Signin Home page/Signin components/CreatorMenu';
import AddToCollection from '../Signin Home page/Signin components/AddToCollection';
import arrow from "../../media/arrow.svg"
import phone from "../../media/phone.svg"
import insta from "../../media/insta.svg"
import yt from "../../media/yt.svg"
import sorts from "../../media/sorts.svg"
import dots from "../../media/dots.svg"
import collections from "../../media/collections.svg"
import axios from "axios"

const SeachAllResults = () => {
    const { name } = useParams();
    const [openFilter, setOpenFilter] = useState(null);
    const [selectedCreator, setSelectedCreator] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0 });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedCreators, setSelectedCreators] = useState([]);
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [isSelectionMode1, setIsSelectionMode1] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    const [creatorsDatas, setCreatorsDatas] = useState([]);

    useEffect(() => {
        // Function to fetch data from the backend API
        const fetchCreatorsData = async () => {
            try {
                // Make a GET request to the backend API endpoint (adjust the URL accordingly)
                const response = await axios.get('https://brich.youthevents.in/Bdashboard/get-all-creators-data');

                // Update state with the received data
                setCreatorsDatas(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call the fetch function when the component mounts
        fetchCreatorsData();
    }, []);

    // Find the selected creator details from 'creators' array using 'name'
    const [creators, setCreators] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCreators = async () => {
            try {
                const response = await axios.get(`https://brich.youthevents.in/Bdashboard/search_creators_by_name/?search=${name}`);
                setCreators(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching creators:', error);
                setLoading(false);
            }
        };

        fetchCreators();
    }, [name]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (creators.length === 0) {
        return <div>No creators found for "{name}".</div>;
    }

    const handleCollections = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };


    const handleDotsClick = (event, creator) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({ top: rect.top + window.scrollY });
        setSelectedCreator(creator);
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleSelect = () => {
        // Handle the "Select" action here
        setIsSelectionMode(true);
        handleMenuClose();
    };

    const handleAddToCollection = () => {
        // Handle the "Add to Collection" action here
        console.log(`Added ${selectedCreator?.name} to collection`);
        handleMenuClose();
    };

    const handleCheckboxChange = (creator) => {
        setSelectedCreators((prevSelected) =>
            prevSelected.includes(creator)
                ? prevSelected.filter((selected) => selected !== creator)
                : [...prevSelected, creator]
        );
    };

    const handleCreatorClick = (creator) => {
        if (isSelectionMode || isSelectionMode1) {
            handleCheckboxChange(creator);
        }
    };

    console.log(selectedCreators);


    const formatNumber = (num) => {
        const numValue = parseInt(num);
        if (numValue >= 1000000) {
            return (parseFloat((numValue / 1000000).toFixed(1)) + 'M').replace('.0', '');
        }
        if (numValue >= 1000) {
            return (parseFloat((numValue / 1000).toFixed(1)) + 'K').replace('.0', '');
        }
        return num;
    };

    return (
        <>
            <div className='general-right'>
                <SigninleftNavbar />
                <div className="right">
                    {creators.length > 0 ? ( // Check if creatorsDatas has data
                        <>
                            <div className="search-bars ">
                                <SearchBar placeholder="Creators" data={creatorsDatas} />
                            </div>
                            <div className="welcome">
                                <div className="head">
                                    <h3 style={{ fontSize: "36px" }}>Search Results</h3>
                                    <p>Find the best influencers for your campaign based on your search criteria.</p>

                                </div>
                                <div className="request">
                                    Request a Callback
                                    <div className="images">
                                        <img src={phone} alt="" />
                                        <img className='arrow' src={arrow} alt="" />

                                    </div>

                                </div>
                            </div>

                            <div className="filter-list">
                                <div className="list-head">
                                    <h3>Creators List</h3>
                                    <p>{creators?.length} Creators</p>
                                </div>
                                {isSelectionMode ? (
                                    <div className="addtoc" onClick={handleCollections}>
                                        <div className="selectedno">{selectedCreators.length}</div>
                                        Add to Collection
                                        <img src={collections} alt="" />
                                    </div>
                                ) : (
                                    <>

                                    </>
                                )}


                            </div>
                            <div className="thead">
                                <div className="thead-wrap">
                                    <div className="th1 tname">Creator’s Name</div>
                                    <div className="th tfollower">Followers</div>
                                    <div className="th tcreator-type">Creator Type</div>
                                    <div className="th tcreator-type">Content Type</div>
                                    <div className="th tcity">City</div>
                                </div>
                            </div>
                            {creators.map((searchCreator, index) => (


                                <>
                                    <div
                                        className={`creator ${isSelectionMode && selectedCreators.includes(searchCreator) ? 'selected' : ''}`}
                                        onClick={() => handleCreatorClick(searchCreator)}
                                        // style={{ marginBottom: "50px" }}
                                    >
                                        <div className="creator-wrap">
                                            {isSelectionMode && (
                                                <div className="name-checkbox custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={creatorsDatas.includes(searchCreator)}
                                                        onChange={() => handleCheckboxChange(searchCreator)}
                                                    />
                                                    <label></label>
                                                </div>
                                            )}
                                            <div className="name">
                                                <div className="profilewrapper">

                                                    <img className='brand-profile' src={searchCreator.profile || avatar} alt="" />
                                                </div>
                                                <h3>{searchCreator?.name}</h3>
                                            </div>
                                            <div className="follower">{formatNumber(searchCreator.follower)}</div>
                                            <div className="creator-type">{searchCreator.creator_type}</div>
                                            <div className="content-type">{searchCreator.content_type}</div>
                                            <div className="city">{searchCreator.city}</div>
                                        </div>
                                        <div className="social">
                                            <img src={insta} alt="" />
                                            <img src={yt} alt="" />
                                        </div>
                                        <div className="dots" onClick={(e) => handleDotsClick(e, searchCreator)}>
                                            <img src={dots} alt="" />
                                        </div>
                                    </div>
                                    {/* //----------------- for mobile ------------------------------------------------------ */}
                                    <div
                                        className={`Screator Screator1 ${isSelectionMode1 && selectedCreators.includes(searchCreator) ? 'selected' : ''}`}
                                        onClick={() => handleCreatorClick(searchCreator)}
                                    >
                                        <div className="Screator-wrap Screator-wrap1">

                                            <div className="Sname">
                                                <div className="profileMwrapper">

                                                    <img src={searchCreator.profile || avatar} alt="" />
                                                </div>

                                                <div className="name-checkbox select-check select-homme">
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedCreators.includes(searchCreator)}
                                                        onChange={() => handleCheckboxChange(searchCreator)}
                                                    />
                                                    <label></label>
                                                </div>
                                                <div className='Shead'>
                                                    <h3>{searchCreator?.name}</h3>
                                                    <h4>{searchCreator.creator_type}</h4>
                                                    <h4>{searchCreator.city}</h4>

                                                </div>
                                            </div>
                                            <div className="fc">
                                                <div className="Sfollower">{formatNumber(searchCreator.follower)}</div>
                                                <div className="Scontent-type">{searchCreator.content_type}</div>
                                            </div>
                                            <div className="Ssocial">
                                                <img src={insta} alt="" />
                                                <img src={yt} alt="" />
                                            </div>
                                        </div>

                                    </div>
                                </>
                            ))}

                            {isMenuOpen && (
                                <CreatorMenu
                                    onSelect={handleSelect}
                                    onAddToCollection={handleAddToCollection}
                                    style={{ top: menuPosition.top }}
                                />
                            )}
                        </>
                    ) : (
                        <p>Loading...</p>
                    )}

                </div>
                {showPopup && <AddToCollection onClose={handleClosePopup} selectedCreators={selectedCreators} />}


            </div >

        </>
    )
}

export default SeachAllResults
