import React, { useEffect, useState } from 'react';
import avatar from "../../../media/place.jpg"
import insta from "../../../media/insta.svg"
import yt from "../../../media/yt.svg"
import axios from "axios"

const DetailedCampaignView = ({ campaign, goBackToList }) => {
    // Implement your detailed view UI here using the 'campaign' data passed as a prop
    const [campaignDetails, setCampaignDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchCampaignDetails = async () => {
            try {
                const response = await axios.get(`https://brich.youthevents.in/Bdashboard/campaigns/${campaign.id}/`);
                setCampaignDetails(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching campaign details:', error);
                setLoading(false);
            }
        };


        fetchCampaignDetails();
    }, [campaign.id]);

    useEffect(() => {

        const fetchCampaignLinks = async () => {
            try {
                const response = await axios.get(`https://brich.youthevents.in/Bdashboard/fetch-all-campaign-links/`, {
                    params: {
                        campaign_id: campaign.id,
                    },
                });
                setCampaignLinks(response.data.links);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching campaign links:', error);
                setLoading(false);
            }
        };

        fetchCampaignLinks();
    }, [campaign.id]);



    console.log("creator in campaign detail", campaignDetails)
    const [campaignLinks, setCampaignLinks] = useState([]);



    console.log("campaign links are", campaignLinks)

    const getCampaignLinkForCreator = (creatorId) => {
        const linkObj = campaignLinks.find(link => link.creator_email === creatorId);
        return linkObj ? linkObj.link : null;
    };

    const hasCampaignLink = (creatorId) => {
        const linkObj = campaignLinks.find(link => link.creator_email === creatorId);
        return linkObj ? true : false;
    };



    if (loading) {
        return <div>Loading...</div>;
    }
    const formatNumber = (num) => {
    const numValue = parseInt(num);
    if (numValue >= 1000000) {
        return (parseFloat((numValue / 1000000).toFixed(1)) + 'M').replace('.0', '');
    }
    if (numValue >= 1000) {
        return (parseFloat((numValue / 1000).toFixed(1)) + 'K').replace('.0', '');
    }
    return num;
};



    return (
        <div className="detailed-view">
            <div className="manage-back">
                <h3>MANAGE CAMPAIGN</h3>
                <div className="back2 " onClick={goBackToList}>
                    back
                </div>
            </div>
            {/* <h3>{campaign.campaignName}</h3> */}
            <div className="details">
                <div className="detail">
                    <div className="deta">
                        <h3 className={`${campaign.campaign_name.length > 28 ? 'large-font' : ''}`}>{campaign.campaign_name}</h3>
                        <div className="cam-type">{campaign.campaign_type}</div>
                    </div>
                    <p>Track and manage your lead generation campaign effectively. Keep an eye on your budget, target audience, and campaign details.</p>
                    <div className="ditems">
                        <div className="ditem"><span className="goal">Budget:</span>₹ {Math.floor(campaign.budget)}</div>
                        <div className="ditem"><span className="goal">Gender:</span>{campaign.targetAudience}</div>
                        <div className="ditem"><span className="goal">Age:</span>{campaign.age_range}</div>
                        <div className="ditem"><span className="goal">Location:</span>{campaign.location}</div>
                    </div>

                </div>
                <div className="detail">
                    <div className="deta" style={{ marginBottom: "13px" }}>
                        <h3 style={{ marginBlock: "0" }}>MILESTONE</h3>
                        <div className="cam-type1"> <span className="goal">Goal: </span>{campaign.goal}</div>
                    </div>
                    {/* <p style={{ marginBottom: "13px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    <div className="ditems1 flex">
                        <div className="inner-frame">
                            <h3>Updates: </h3>
                            {campaign.milestone == "" ? (
                                <div className="leads cflex">
                                    <h3>No Updates Found</h3>
                                </div>
                            ) : (
                                <div className="leads cflex">
                                    {campaign.milestone.split(",").map((stone, index) => (
                                            index === 0 ? (
                                            <h3 key={index}><span className="gold">{stone}</span></h3>
                                            ) : index === 1 ? (
                                            <h4 key={index}>{stone}</h4>
                                            ) : (
                                            <h5 key={index}>{stone}</h5>
                                            )
                                           
                                    ))}
                                </div>

                            )}


                        </div>
                    </div>

                </div>
            </div>
            <div className="flex">

                <div className="creator-detail cflex">
                    <div className="headingsss cflex">

                        <h3>CREATORS IN CAMPAIGN</h3>
                        <p>Following are the creators that are shortlisted for your campaign.</p>
                    </div>
                    <div className="campaign-creator cflex">

                        {campaignDetails?.creators.map((creator, index) => (
                            <>
                                <div className='camp-creator-wrap'>

                                    <div className="Screator-wrap2">
                                        <div className="Sname sname1">
                                            <div className="profileMwrapper">

                                                <img src={creator.profile || avatar} alt="" />
                                            </div>
                                            <div className='Shead shead1'>
                                                <h3>{creator.name}</h3>
                                                <h4>{creator.creator_type}</h4>
                                                <h4>{creator.city}</h4>

                                            </div>
                                        </div>
                                        <div className="fc">
                                            <div className="Sfollower">{formatNumber(creator.follower)}</div>
                                            <div className="Scontent-type">{creator.content_type.split(',').join(', ')}</div>
                                        </div>
                                        <div className="Ssocial">
                                            <img src={insta} alt="" />
                                            <img src={yt} alt="" />
                                        </div>
                                        {hasCampaignLink(creator.email_id) ? (
                                            <div className="view-post">
                                                <a href={getCampaignLinkForCreator(creator?.email_id)} target="_blank" rel="noopener noreferrer" className="postss">
                                                    View Post
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="view-post">
                                                <div className="postss disabled">
                                                    View Post
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>

                                <div className='camp-creator-wrap2'>
                                    <div className="Screator-wrap1">
                                        <div className="Sname sname1">
                                            <div className="profileMwrapper">
                                                <img src={creator.profile || avatar} alt="" />
                                            </div>
                                            <div className='Shead shead1 shead2'>
                                                <h3>{creator.name}</h3>
                                                <h4>{creator.creator_type}</h4>
                                                <h4>{creator.city}</h4>
                                            </div>
                                        </div>
                                        <div className="fc">
                                            <div className="Sfollower">{formatNumber(creator.follower)}</div>
                                            <div className="Scontent-type">{creator.content_type.split(',').join(', ')}</div>
                                        </div>
                                        <div className="Ssocial">
                                            <img src={insta} alt="" />
                                            <img src={yt} alt="" />
                                        </div>

                                    </div>

                                    {hasCampaignLink(creator.email_id) ? (
                                        <div className="view-post" style={{ width: "90%" }}>
                                            <a href={getCampaignLinkForCreator(creator.email_id)} target="_blank" rel="noopener noreferrer" className="postss postss1">
                                                View Post
                                            </a>
                                        </div>
                                    ) : (
                                        <div className="view-post" style={{ width: "90%" }}>
                                            <div className="postss postss1 disabled">
                                                View Post
                                            </div>
                                        </div>
                                    )}


                                </div>
                            </>

                        ))}

                    </div>


                </div>
            </div>
            <div className="back3 " onClick={goBackToList}>
                back
            </div>
        </div>
    );
};

export default DetailedCampaignView;