import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import left from "../../../media/lft.svg";
import right from "../../../media/rgt.svg";
import "../create.css";

const propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.instanceOf(File) // Array of File objects
  ).isRequired
};

const VideoSlider = (props) => {
  const { videos } = props; // Destructuring directly from props

  const [currentVideo, setCurrentVideo] = useState(0);
  const [previewURLs, setPreviewURLs] = useState([]);

  useEffect(() => {
    const urls = [];
    const loadPreviewURLs = async () => {
      for (const file of videos) {
        const reader = new FileReader();
        reader.onload = () => {
          urls.push(reader.result);
          if (urls.length === videos.length) {
            setPreviewURLs(urls);
          }
        };
        reader.readAsDataURL(file);
      }
    };

    if (videos.length > 0) {
      loadPreviewURLs();
    }
  }, [videos]);

  const videoLength = previewURLs.length;

  const nextVideoSlide = () => {
    setCurrentVideo((currentVideo + 1) % videoLength);
  };

  const prevVideoSlide = () => {
    setCurrentVideo((currentVideo - 1 + videoLength) % videoLength);
  };

  return (
    <section className="video-slider">
      <img src={left} className="left-vid" onClick={prevVideoSlide} alt="Previous" />
      {previewURLs?.map((video, index) => (
        <div
          className={index === currentVideo ? "slide active" : "slide"}
          key={index}
        >
          {index === currentVideo && (
            <video controls width="255" height="454" autoPlay loop muted className='reels-result' key={index}>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ))}
      <img src={right} className="right-vid" onClick={nextVideoSlide} alt="Next" />
    </section>
  );
};

VideoSlider.propTypes = propTypes;

export default React.memo(VideoSlider);
