import React, { useState } from 'react';
import axios from "axios"
import cross from "../../../media/cross.svg";
import line from "../../../media/line.svg";
import vibe from "../../../media/vibe.png";

const EditCollection = ({ onClose, collection, updateCollections }) => {
    const [editedCollectionName, setEditedCollectionName] = useState(collection?.collection_name || '');
    const [editedCollectionDescription, setEditedCollectionDescription] = useState(collection?.description || '');


    const handleSaveChanges = async () => {
        // Perform the API call to update the collection with edited details
        console.log(JSON.parse(localStorage.getItem('user')).email)
        // Make an axios PUT request to update the collection
        await axios.put(`https://brich.youthevents.in/Bdashboard/api/collections/${collection.id}/`, {
            collection_name: editedCollectionName,
            description: editedCollectionDescription,

        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                user: JSON.parse(localStorage.getItem('user')).email
            }
        }).then(response => {
            console.log('Collection updated successfully:', response.data);
            onClose();
            updateCollections();
        })
            .catch(error => {
                console.error('Error creating collection:', error);
            });

        // Close the popup and update the collections list


    };

    return (

        <div className="overlay">
            <div className="create-collection-popup">
                <div className="cross" onClick={onClose}>
                    <img src={cross} alt="" />
                </div>


                <div className="h3">
                    <h3>Edit a Collection</h3>
                    <img src={line} alt="" />
                </div>
                <div className="write">
                    <h3>Add Collection Name</h3>
                    <input
                        id="editedName"
                        type="text"
                        value={editedCollectionName}
                        onChange={(e) => setEditedCollectionName(e.target.value)}
                    />
                </div>
                <div className="write">
                    <h3>Add Collection Description</h3>
                    <textarea
                        id="editedDescription"
                        value={editedCollectionDescription}
                        onChange={(e) => setEditedCollectionDescription(e.target.value)}
                    ></textarea>
                </div>
                <div className="proceed" onClick={handleSaveChanges}>
                    Save
                </div>
                <div className="vibe">
                    <img src={vibe} alt="" />
                </div>


            </div>
        </div>

    );
};

export default EditCollection;
