import React from 'react';
import down from '../../../media/down.svg';

const FilterDropdown = ({ label, options, openFilter, onFilterChange }) => {
  const isOpen = openFilter === label;

  const toggleDropdown = () => {
    onFilterChange(label, isOpen ? null : label); // Toggle the dropdown based on its current state
  };

  return (
    <div className="filter">
      <div className="filter-label" onClick={toggleDropdown}>
        {label} <img src={down} alt="" />
      </div>
      {isOpen && (
        <div className="filter-options">
          {options.map((option, index) => (
            <div key={index} className="filter-option" onClick={() => onFilterChange(label, option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
