import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collections: [
    // {
    //   creators :  [{}, {}, {}],
    //   description : "cdvfs",
    //   name: "ujjwal saini"
    // }
  ],
  userAuthenticated: false, 
  userProfile: null,
  showPrivacy:false,
  showTerms:false
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setCollections: (state, action) => {
      state.collections = action.payload;
    },
    // setDeptTime: (state, action) => {
    //   state.deptTime = action.payload;
    // },
    setUserAuthenticated: (state, action) => {
      state.userAuthenticated = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setShowterms: (state, action) => {
      state.showTerms = action.payload;
    },
    setShowprivacy: (state, action) => {
      state.showPrivacy = action.payload;
    },
    
  },
});


export const {
  setCollections,
  setUserAuthenticated,
  setUserProfile ,
  // setDeptTime,
  setShowterms,
  setShowprivacy
} = filterSlice.actions;

export default filterSlice.reducer;
