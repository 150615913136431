import React from 'react'
import celebrate from "../../../media/celebrate.png";

const SuccessPage = (setActiveComponent , setActiveItem ) => {
    return (
        <div className='general-right campign-blob'>

        <div className="right">
        <div className="smart-campaign-steps">
            <div className="smart-heading" >
                <h3>SMART CAMPAIGN</h3>
                <p>Auto generated campaign </p>
            </div>

            <div className='smarts step2 smarty cflex'>
                <img src={celebrate} alt="" />
                <div className="general" style={{ maxWidth: "248px" }}>
                    <h3>Your Campaign is Ready </h3>
                </div>
                <p>Our Team will contact you within next 24 Hours</p>
                <div className="next next1" style={{ width: "120px", height: "32px", display: "flex" }} onClick={() => { setActiveComponent('manageCampaign'); setActiveItem('manageCampaign'); }}>Great</div>


            </div>
        </div>
        </div>
        </div>
    )
}

export default SuccessPage
