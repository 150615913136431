import React, { useEffect, useState } from 'react'
import "./setting.css"
import upload from "../../media/pupload.svg"
import pedit from "../../media/pedit.svg"
import out from "../../media/out.svg"
import tick from "../../media/tick.svg"
import SigninleftNavbar from '../SigninNavbar/SigninleftNavbar'
import axios from "axios"

const Settings = () => {
    const [email, setEmail] = useState(true);
    const [creator, setCreator] = useState(true);
    const [campaign, setCampaign] = useState(true);
    const [notification, setNotification] = useState(true);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // const userProfile = JSON.parse(localStorage.getItem('user'));
    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('user')) || {});

    const token = localStorage.getItem('token')



    console.log("user profile", userProfile, token)
    const useremail = userProfile.email


    const handlePasswordChange = async () => {


        // Perform necessary validations before sending the request

        const data = {
            currentPassword,
            newPassword,
            confirmPassword,
            useremail
        };

        try {
            const response = await axios.post('https://brich.youthevents.in/Bdashboard/change-password/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    // Include any necessary authentication headers if required
                }
            });

            // Handle response based on success or failure
            if (response.status === 200) {
                // Password changed successfully, handle the response accordingly
            } else {
                // Failed to change password, handle the error response
            }
        } catch (error) {
            // Handle network errors or other exceptions
        }
    };


    const [editableFields, setEditableFields] = useState({
        company_name: false,
        phone: false,
        email: false,
    });


    // ... (existing code remains the same)

    const handleFieldEdit = (fieldName) => {
        setEditableFields({
            ...editableFields,
            [fieldName]: true,
        });
    };

    const handleInputChange = (event, fieldName) => {
        setUserProfile({
            ...userProfile,
            [fieldName]: event.target.value,
        });
    };

    const updateUserProfile = async () => {
        try {
            const token = localStorage.getItem('token')
            const response = await axios.put(
                'https://brich.youthevents.in/Bdashboard/update-profile/',
                userProfile,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                console.log('Response data:', response.data);
                localStorage.setItem('user', JSON.stringify(response.data.user));

                console.log('Profile updated successfully');
            } else {
                console.log('Response status:', response.status);
                console.error('Failed to update profile');
            }
        } catch (error) {
            // Handle errors or exceptions
            console.error('An error occurred:', error);
        }
    };


    const [profilePicture, setProfilePicture] = useState(null); // To store the profile picture file
    const [profilePhotoInput, setProfilePhotoInput] = useState(null); // To store the profile picture input element



    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0];
        setProfilePicture(file);
        uploadProfilePicture(file);
    };

    useEffect(() => {
        if (profilePicture) {
            uploadProfilePicture();
        }
    }, [profilePicture]);

    const handleUploadClick = () => {
        if (profilePhotoInput) {
            profilePhotoInput.click();
        }
    };


    const uploadProfilePicture = async (file) => {
        try {
            const formData = new FormData();
            formData.append('profile_photo', file);

            const token = localStorage.getItem('token');
            const response = await axios.post(
                'https://brich.youthevents.in/Bdashboard/upload-profile-photo/',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                console.log('Profile picture uploaded successfully');
                // Optionally, update the user profile with the new profile picture data received in the response
                fetchProfilePhoto();

            } else {
                console.error('Failed to upload profile picture');
            }
        } catch (error) {
            console.error('An error occurred while uploading profile picture:', error);
        }
    };


    const [profilePhoto, setProfilePhoto] = useState('');

    // Function to fetch the user's profile photo
    const fetchProfilePhoto = async () => {
        try {
            // Assuming you have stored the user's authentication token in localStorage
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            // Make a GET request to fetch the profile photo
            const response = await axios.get('https://brich.youthevents.in/Bdashboard/upload-profile-photo/', config);

            // Set the profile photo URL in the state
            setProfilePhoto(response.data.profile_photo);

        } catch (error) {
            console.error('Error fetching profile photo:', error);
        }
    };
    useEffect(() => {

        fetchProfilePhoto();
    }, []);


    console.log("profile photo is", profilePhoto)

    const toggleReceiveemail = () => {
        setEmail(!email);
    };

    const toggleReceivecreator = () => {
        setCreator(!creator);
    };

    const toggleReceivecampaign = () => {
        setCampaign(!campaign);
    };

    const toggleReceivenotification = () => {
        setNotification(!notification);
    };
    const logoutUser = () => {

        axios.post('https://brich.youthevents.in/Bdashboard/api/logout/')  // Use the URL defined for logout
            .then(response => {
                console.log('Logged out successfully');
                localStorage.removeItem('token');
                delete axios.defaults.headers.common['Authorization'];
                localStorage.removeItem('user');
                window.location.href = `/sign-in`;
            })
            .catch(error => {
                // Handle errors
                console.error('Error logging out:', error);
            });



    };


    return (
        <div className="general-right">

            <div className="right cflex">
                <div className="headings-logout">
                    <div className="headingss">
                        <h3>Settings</h3>
                        <p>Manage your account settings and update your details as needed.</p>
                    </div>
                    <div className="logout" onClick={logoutUser}>
                        <img src={out} alt="" className='logoutimg' />
                        Signout
                    </div>
                </div>
                <div className="user-detail flex">
                    <div className="profile-detail">
                        <div className="profile-feild">
                            <div className="profile cflex" onClick={handleUploadClick}>
                                {profilePhoto ? (
                                    <img src={`data:image/jpeg;base64,${profilePhoto}`} alt="Profile" className='profile-pic' />
                                ) : (
                                    <>
                                        <img src={upload} alt="" />
                                        Upload
                                    </>
                                )}
                                <input
                                    type="file"
                                    ref={(input) => {
                                        setProfilePhotoInput(input);
                                    }}
                                    onChange={handleProfilePictureChange}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                />
                            </div>
                            <div className="feilds">
                                <div className="feild">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={userProfile.company_name}
                                        onChange={(e) => handleInputChange(e, 'company_name')}
                                        readOnly={!editableFields.company_name}
                                    />
                                    {editableFields.company_name ?
                                        <img className='tick' src={tick} alt="" onClick={() => handleFieldEdit('company_name')} />
                                        :
                                        <img src={pedit} alt="" onClick={() => handleFieldEdit('company_name')} />

                                    }
                                </div>

                                <div className="feild">
                                    <input
                                        type="number"
                                        placeholder="Mobile No"
                                        value={userProfile.phone}
                                        onChange={(e) => handleInputChange(e, 'phone')}
                                        readOnly={!editableFields.phone}
                                        maxLength={10}
                                    />
                                    {editableFields.phone ?
                                        <img className='tick' src={tick} alt="" onClick={() => handleFieldEdit('phone')} />
                                        :
                                        <img src={pedit} alt="" onClick={() => handleFieldEdit('phone')} />

                                    }
                                </div>

                                <div className="feild">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        value={userProfile.email}
                                        onChange={(e) => handleInputChange(e, 'email')}
                                        readOnly={!editableFields.email}
                                    />
                                    {editableFields.email ?
                                        <img className='tick' src={tick} alt="" onClick={() => handleFieldEdit('email')} />
                                        :
                                        <img src={pedit} alt="" onClick={() => handleFieldEdit('email')} />

                                    }

                                </div>

                            </div>

                        </div>

                        <div className="update-btn">
                            {editableFields.email || editableFields.phone || editableFields.company_name ?
                                <div className="updateB" onClick={updateUserProfile}>
                                    Update
                                </div>
                                :
                                <div className="updateB" onClick={updateUserProfile}>
                                    Update Details
                                </div>
                            }

                        </div>
                    </div>

                </div>

                <div className="passwords">
                    <div className="profile-detail">
                        <div className="profile-feild profile-feild1">
                            <div className="pass-head">
                                <h3>Change Passwords</h3>
                            </div>

                            <div className="feilds feilds1">
                                <div className="feild">
                                    <input
                                        type="password"
                                        placeholder="Current Password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    {/* <img src={pedit} alt="" /> */}
                                </div>

                                <div className="feild">
                                    <input
                                        type="password"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    {/* <img src={pedit} alt="" /> */}
                                </div>

                                <div className="feild">
                                    <input
                                        type="password"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    {/* <img src={pedit} alt="" /> */}
                                </div>
                            </div>

                        </div>

                        <div className="update-btn">

                            <div className="updateB" onClick={handlePasswordChange}>
                                Update Password
                            </div>

                        </div>
                    </div>

                </div>
                <div className="notiications flex">
                    <div className="profile-feild profile-feild1">
                        <div className="pass-head">
                            <h3>Notifications</h3>
                        </div>

                        <div className="feilds feilds2">
                            <div className={`toggler ${email ? 'active2' : ''}`}>
                                Email Notifications

                                <div className={`slider1 ${email ? 'active' : ''}`} onClick={toggleReceiveemail}>
                                    <div className={`slider-button ${email ? 'active' : ''}`}></div>
                                </div>

                            </div>
                            <div className={`toggler ${creator ? 'active2' : ''}`}>
                                Creators Notifications
                                <div className={`slider1 ${creator ? 'active' : ''}`} onClick={toggleReceivecreator}>
                                    <div className={`slider-button ${creator ? 'active' : ''}`}></div>
                                </div>

                            </div>
                            <div className={`toggler ${campaign ? 'active2' : ''}`}>
                                Campaign Notifications
                                <div className={`slider1 ${campaign ? 'active' : ''}`} onClick={toggleReceivecampaign}>
                                    <div className={`slider-button ${campaign ? 'active' : ''}`}></div>
                                </div>

                            </div>
                            <div className={`toggler ${notification ? 'active2' : ''}`}>
                                Update Notifications
                                <div className={`slider1 ${notification ? 'active' : ''}`} onClick={toggleReceivenotification}>
                                    <div className={`slider-button ${notification ? 'active' : ''}`}></div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="logout1" onClick={logoutUser}>
                    <img src={out} alt="" className='logoutimg' />
                    Signout
                </div>

            </div>
        </div>
    )
}

export default Settings
