import React, { useEffect, useState } from 'react';
import cross from "../../../media/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCollections } from "../../../redux/filterSlice";
import user from "../../../media/user.svg"
import CreateCollection from '../../Collections/Collection components/CreateCollection';
import axios from 'axios';




const AddToCollection = ({ onClose, selectedCreators, setIsSelectionMode }) => {
    console.log("selected creactor im add to toc", selectedCreators)
    const dispatch = useDispatch();
    const [Collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [isCreateCollectionOpen, setIsCreateCollectionOpen] = useState(false);

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await axios.get('https://brich.youthevents.in/Bdashboard/api/collections/', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        user: ` ${JSON.parse(localStorage.getItem('user')).email}`
                    }
                });
                setCollections(response.data);
                console.log("collectoi", response.data)
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };

        fetchCollections();
    }, []);


    const openCreateCollection = () => {
        setIsCreateCollectionOpen(true);
    };

    const closeCreateCollection = () => {
        setIsCreateCollectionOpen(false);
    };

    const handleCollectionSelection = (collection) => {
        setSelectedCollection(collection); // Set the selected collection
    };

    const handleAddToSelectedCollection = async () => {
        if (selectedCollection) {
            console.log(JSON.parse(localStorage.getItem('user')).email)
            // Make an axios PUT request to update the collection
            await axios.put(`https://brich.youthevents.in/Bdashboard/api/collections/${selectedCollection.id}/`, {
                collection_name: selectedCollection.collection_name,
                description: selectedCollection.description,
                creators: selectedCreators

            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    user: JSON.parse(localStorage.getItem('user')).email
                }
            }).then(response => {
                console.log('Collection updated successfully:', response.data);
                onClose();
                setIsSelectionMode(false);

                // updateCollections();
            })
                .catch(error => {
                    console.error('Error creating collection:', error);
                });

            // setCollections(updatedCollection);
        }
        onClose();
    };




    return (
        <div className="overlay">
            <div className="create-collection-popup addtoC">
                <div className="cross" onClick={onClose}>
                    <img src={cross} alt="" />
                </div>
                <h3>Select a Collection</h3>

                {Collections.length === 0 ? (
                    <>
                        <div className="emplyCollection">
                            You don’t have any collections
                        </div>
                        <div className="createC" onClick={openCreateCollection}>
                            Create Collection
                        </div>

                    </>
                ) : (
                    <>
                        <div className="collectionh">
                            {Collections?.map((collection, index) => {
                                const allCategories = collection.creators?.map((creator) => creator.creator_type);
                                const sortedCategories = [...new Set(allCategories)].sort((a, b) => b.length - a.length);
                                const longCategory = sortedCategories[0];
                                const shortCategory = sortedCategories[sortedCategories.length - 1];
                                const uniqueCategories = sortedCategories.filter(
                                    (category) => category !== longCategory && category !== shortCategory
                                );

                                return (
                                    <div className='coll coll1' key={index}>
                                        <div className="colls">
                                            <input
                                                type="radio"
                                                name="collectionRadio"
                                                id={`collection${index}`}
                                                value={collection.collection_name}
                                                checked={selectedCollection?.collection_name === collection.collection_name}
                                                onChange={() => handleCollectionSelection(collection)}
                                            />
                                            <div className="CLname">{collection.collection_name}</div>
                                            <div className="CLimage">
                                                <div className="users">
                                                    {collection.creators?.length || 0}
                                                    <img src={user} alt="" />
                                                </div>
                                            </div>
                                            <div className="CLcat">
                                                <div className="numbers">
                                                    <div className="short">
                                                        {shortCategory ? shortCategory : "--"}
                                                    </div>
                                                    <div className="num">
                                                        +{uniqueCategories.length}
                                                    </div>

                                                </div>
                                                <div className="long">
                                                    {longCategory ? longCategory : '--'}
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                )
                            })}


                        </div>
                        <div className="btns">
                            <div className="createcoll" onClick={openCreateCollection}>
                                Create Collection
                            </div>
                            <div className="addToCollectionBtn" onClick={handleAddToSelectedCollection}>
                                Add
                            </div>

                        </div>

                    </>
                )}
                {isCreateCollectionOpen && (
                    <CreateCollection onClose={closeCreateCollection} />
                )}



            </div>
        </div>
    );
};

export default AddToCollection;
