import { React, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import arrow from "../../../media/arrow.svg"
import phone from "../../../media/phone.svg"
import sort from "../../../media/sort.svg"
import avatar from "../../../media/place.jpg"
import insta from "../../../media/insta.svg"
import yt from "../../../media/yt.svg"
import sorts from "../../../media/sorts.svg"
import dots from "../../../media/dots.svg"
import mobiledrop from "../../../media/mobiledrop.svg"
import FilterDropdown from '../../Signin Home page/Signin components/FilterDropdown';
import CreatorMenu from '../../Signin Home page/Signin components/CreatorMenu';
import axios from 'axios';
import "../collection.css"
import delet from "../../../media/delete.svg"
import edit from "../../../media/edit.svg"
import adds from "../../../media/adds.svg"
import EditCollection from './EditCollection';
import cross from "../../../media/cross.svg";
import comma from "../../../media/comma.svg";
import rcomma from "../../../media/rcomma.svg";
import CustomCampaign from '../../Create Campaign/Campaign types/CustomCampaign';
import EditCreators from './EditCreators';
import CreatorMenu2 from './CreatorMenu2';

const CollectionDetails = () => {
  const { id } = useParams();

  const [openFilter, setOpenFilter] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [isSelectionMode1, setIsSelectionMode1] = useState(true);
  const [showfilters, setShowFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [collection, setCollection] = useState(null);

  const fetchCollectionDetail = async () => {
    try {
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      const response = await axios.get(`https://brich.youthevents.in/Bdashboard/collections/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          user: user.email
        }
      });

      setCollection(response.data);
    } catch (error) {
      console.error('Error fetching collection details:', error);
    }
  };

  useEffect(() => {

    fetchCollectionDetail();
  }, [id]);

  console.log("collection detail", collection)

  const [showcreatorEditpopup, setShowCreatorEditPopup] = useState(false);

  const handleEditCreators = (collection) => {
    setSelectedCollection(collection);
    setShowCreatorEditPopup(true);
  };
  const handleCloseEditCreators = () => {
    setSelectedCollection(null);
    setShowCreatorEditPopup(false);
  };




  const handleFilterItemClick = (filter) => {
    setSelectedFilter(filter === selectedFilter ? null : filter);
  };

  const handleShowFilters = () => {
    setShowFilters(true)
  }
  const handleDoneFilters = () => {
    setShowFilters(false)
  }

  const handleFilterChange = (filter) => {
    setOpenFilter(filter === openFilter ? null : filter);
  };

  const handleDotsClick = (event, creator) => {
    event.preventDefault();
    const rect = event.target.getBoundingClientRect();
    setMenuPosition({ top: rect.top + window.scrollY });
    setSelectedCreator(creator);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = () => {
    setIsSelectionMode(true);
    handleMenuClose();
  };

  const handleAddToCollection = () => {
    // Handle the "Add to Collection" action here
    console.log(`Added ${selectedCreator.name} to collection`);
    handleMenuClose();
  };

  const handleCheckboxChange = (creator) => {
    setSelectedCreators((prevSelected) =>
      prevSelected.includes(creator)
        ? prevSelected.filter((selected) => selected !== creator)
        : [...prevSelected, creator]
    );
  };

  const handleCreatorClick = (creator) => {
    if (isSelectionMode || isSelectionMode1) {
      handleCheckboxChange(creator);
    }
  };

  console.log(selectedCreators);






  const handleCategoryFilterChange = (filterLabel, selectedOption) => {
    if (filterLabel === 'Categories') {
      setOpenFilter(selectedOption);
      filterCreators(selectedOption);
    } else {
      setOpenFilter(null);
    }
  };

  useEffect(() => {
    if (openFilter) {
      filterCreators(openFilter);
    } else {
      setCreatorsDatas(collection?.creators);
    }
  }, [openFilter, collection?.creators]);

  const [creatorsDatas, setCreatorsDatas] = useState([]);
  const [creatorsData, setCreatorsData] = useState([]);

  useEffect(() => {
    const fetchCreatorsData = async () => {
      try {
        const response = await axios.get('https://brich.youthevents.in/Bdashboard/get-all-creators-data');

        setCreatorsData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCreatorsData();
  }, []);

  const filterCreators = (selectedCategory) => {
    if (selectedCategory) {
      const filtered = collection?.creators.filter(creator => creator.creatorType === selectedCategory);
      setCreatorsDatas(filtered);
    } else {
      setCreatorsDatas(collection?.creators); // Show all creators if no category is selected
    }
  };

  const formatNumber = (num) => {
    const numValue = parseInt(num);
    if (numValue >= 1000000) {
        return (parseFloat((numValue / 1000000).toFixed(1)) + 'M').replace('.0', '');
    }
    if (numValue >= 1000) {
        return (parseFloat((numValue / 1000).toFixed(1)) + 'K').replace('.0', '');
    }
    return num;
};


  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);


  // Function to handle opening the edit popup
  const handleEditCollection = (collection) => {
    setSelectedCollection(collection);
    setShowEditPopup(true);
  };

  // Function to close the edit popup
  const handleCloseEditPopup = () => {
    setSelectedCollection(null);
    setShowEditPopup(false);
  };
 
  const handleDeleteCollection = async (collectionId) => {
    try {
      await axios.delete(`https://brich.youthevents.in/Bdashboard/api/collections/${collectionId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          user: `${JSON.parse(localStorage.getItem('user')).email}`
        }
      });
      // After successful deletion, update the collections state
      window.location.href = '/'
    } catch (error) {
      console.error('Error deleting collection:', error);
    }
  };

  const sendQuoteRequest = async (collectionId) => {
    try {
      const response = await axios.post(`https://brich.youthevents.in/Bdashboard/api/collections/${collectionId}/request-quote/`, {
        // Optionally, you can send additional data in the request body if needed
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Handle success - show a message or perform other actions if needed
      console.log('Quote request sent:', response.data.message);
      setShowSuccessPopup(true)
    } catch (error) {
      console.error('Error sending quote request:', error);
      // Handle errors - display an error message or perform other actions
    }
  };
  const [showCampaign, setShowCampaign] = useState({ show: false, campaignId: null });

  const handleStartCampaignClick = (campaignId) => {
    setShowCampaign({ show: true, campaignId });
  };
  const handleBackToMainContent = () => {
    setShowCampaign({ show: false, campaignId: null });
  };

  const [currentStep, setCurrentStep] = useState(2);
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const allCategories = collection?.creators?.map((creator) => creator.creator_type);
  const sortedCategories = [...new Set(allCategories)].sort((a, b) => b.length - a.length);
  const longCategory = sortedCategories[0];
  const shortCategory = sortedCategories[sortedCategories.length - 1];
  const uniqueCategories = sortedCategories.filter(
    (category) => category !== longCategory && category !== shortCategory
  );

  return (
    <>
      <div className='general-right'>
        <div className="right">
          {showCampaign.show ? (
            <CustomCampaign
              campaignId={showCampaign.campaignId}
              onBack={handleBackToMainContent}
              step={currentStep}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
            />
          ) : (
            <>
              <div className="search-bars ">
              </div>
              <div className="welcome">
                <div className="head">
                  <h3>{collection?.collection_name}</h3>
                  <p>{collection?.description}</p>
                </div>
                <div className="ca">
                  <div className="greenaction">
                    <div className="get" onClick={() => sendQuoteRequest(collection.id)} >GET QUOTE</div>
                    <div className="get" onClick={() => handleStartCampaignClick(collection.id)}>START CAMPAIGN</div>
                  </div>
                  <div className="saction">
                    <div className="action" onClick={() => handleDeleteCollection(collection.id)} ><img src={delet} alt="" /></div>
                    <div className="action" onClick={() => handleEditCollection(collection)} ><img src={edit} alt="" /></div>
                    {/* <Link to={`/collection-details/${collection.id}`} className="action"><img src={adds} alt="" /></Link> */}
                    <div className="action" onClick={() => handleEditCreators(collection)}><img src={adds} alt="" /></div>
                  </div>
                  <div className="scat">
                    <div className="short">
                      {shortCategory ? shortCategory : "--"}
                    </div>

                    <div className="long">
                      {longCategory ? longCategory : '--'}
                    </div>
                    <div className="num">
                      +{uniqueCategories.length}
                    </div>

                  </div>

                </div>
              </div>
              <div className="filter-list">
                <div className="list-head">
                  <h3>Creators List</h3>
                  <p>{creatorsDatas?.length} Creators</p>
                </div>

                <div className="filters filters1">
                  <FilterDropdown label="Categories" options={["Fashion", "Entertainment", "Artist", "Fitness", "Gaming",]} openFilter={openFilter} onFilterChange={handleCategoryFilterChange} />
                  <FilterDropdown label="Followers" options={["All", "10k- 20k", "20k- 40k", "40k- 60k", "60k- 80k", "80k- 100k"]} openFilter={openFilter} onFilterChange={handleFilterChange} />
                  <FilterDropdown label="Platform" options={["Youtube", "Instagram", "Both"]} openFilter={openFilter} onFilterChange={handleFilterChange} />
                  <FilterDropdown label="Location" options={["Option 1", "Option 2", "Option 3"]} openFilter={openFilter} onFilterChange={handleFilterChange} />
                  <div className="sort"><img src={sort} alt="" /></div>
                </div>
                <div className="filter-logo" onClick={handleShowFilters}>
                  <img src={sorts} alt="" />
                  Filters
                </div>
                {showfilters && (
                  <div className='mobile-filters'>
                    <div className="filter-item" onClick={() => handleFilterItemClick('Categories')}>
                      <h3>Categories</h3>
                      <img src={mobiledrop} alt="" />
                      {selectedFilter === 'Categories' && (
                        <div className="category-options">
                          <ul>
                            <li>Category 1</li>
                            <li>Category 2</li>
                            <li>Category 3</li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Followers')}>
                      <h3>Followers</h3>
                      <img src={mobiledrop} alt="" />
                      {selectedFilter === 'Followers' && (
                        <div className="followers-options">
                          <ul>
                            <li>All</li>
                            <li>10k-20k</li>
                            <li>20k-40k</li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Platform')}>
                      <h3>Platform</h3>
                      <img src={mobiledrop} alt="" />
                      {selectedFilter === 'Platform' && (
                        <div className="platform-options">
                          <ul>
                            <li>YouTube</li>
                            <li>Instagram</li>
                            <li>Both</li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Location')}>
                      <h3>Location</h3>
                      <img src={mobiledrop} alt="" />
                      {selectedFilter === 'Location' && (
                        <div className="location-options">
                          <ul>
                            <li>Option 1</li>
                            <li>Option 2</li>
                            <li>Option 3</li>
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Sort')}>
                      <h3>Sort</h3>
                      <img src={mobiledrop} alt="" />
                      {selectedFilter === 'Sort' && (
                        <div className="sort-options">
                          <ul>
                            <li>Option A</li>
                            <li>Option B</li>
                            <li>Option C</li>
                            {/* Add more sort options */}
                          </ul>
                        </div>
                      )}
                    </div>



                    <div className="done-black" onClick={handleDoneFilters}>Done</div>

                  </div>
                )}

                {showfilters && (
                  <div className='overlay'>

                  </div>
                )}





              </div>
              <div className="thead">
                <div className="thead-wrap">
                  <div className="th1 tname">Creator’s Name</div>
                  <div className="th tfollower">Followers</div>
                  <div className="th tcreator-type">Creator Type</div>
                  <div className="th tcreator-type">Content Type</div>
                  <div className="th tcity">City</div>
                </div>
              </div>
              {creatorsDatas?.map((creator, index) => (
                <>
                  <div
                    className={`creator ${isSelectionMode && selectedCreators.includes(creator) ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleCreatorClick(creator)}
                  >
                    <div className="creator-wrap">
                      {isSelectionMode && (
                        <div className="name-checkbox custom-checkbox">
                          <input
                            type="checkbox"
                            checked={selectedCreators.includes(creator)}
                            onChange={() => handleCheckboxChange(creator)}
                          />
                          <label></label>
                        </div>
                      )}
                      <div className="name">
                        <div className="profilewrapper">

                          <img className='brand-profile' src={creator.profile || avatar} alt="" />
                        </div>
                        <h3>{creator.name}</h3>
                      </div>
                      <div className="follower">{formatNumber(creator.follower)}</div>
                      <div className="creator-type">{creator.creator_type}</div>
                      <div className="content-type">{creator.content_type}</div>
                      <div className="city">{creator.city}</div>
                    </div>
                    <div className="social">
                      <img src={insta} alt="" />
                      <img src={yt} alt="" />
                    </div>
                    <div className="dots" onClick={(e) => handleDotsClick(e, creator)}>
                      <img src={dots} alt="" />
                    </div>
                  </div>
                  {/* //----------------- for mobile ------------------------------------------------------ */}
                  <div
                    className={`Screator Screator1 ${isSelectionMode1 && selectedCreators.includes(creator) ? 'selected' : ''}`}
                    key={index}
                    onClick={() => handleCreatorClick(creator)}
                  >
                    <div className="Screator-wrap Screator-wrap3">
                      {isSelectionMode1 && (
                        <></>
                      )}
                      <div className="Sname">
                        <div className="profileMwrapper">

                          <img src={creator.profile || avatar} alt="" />
                        </div>
                        <div className="name-checkbox select-check select-homme">
                          <input
                            type="checkbox"
                            checked={selectedCreators.includes(creator)}
                            onChange={() => handleCheckboxChange(creator)}
                          />
                          <label></label>
                        </div>
                        <div className='Shead'>
                          <h3>{creator.name}</h3>
                          <h4>{creator.creator_type}</h4>
                          <h4>{creator.city}</h4>

                        </div>
                      </div>
                      <div className="fc">
                        <div className="Sfollower">{formatNumber(creator.follower)}</div>
                        <div className="Scontent-type">{creator.content_type}</div>
                      </div>
                      <div className="Ssocial">
                        <img src={insta} alt="" />
                        <img src={yt} alt="" />
                      </div>
                    </div>

                  </div>
                </>
              ))}
              {isMenuOpen && (
                <CreatorMenu2
                  onSelect={handleSelect}
                  onAddToCollection={handleAddToCollection}
                  style={{ top: menuPosition.top }}
                />
              )}
              {showEditPopup && (
                <EditCollection
                  onClose={handleCloseEditPopup}
                  collection={selectedCollection}
                  updateCollections={fetchCollectionDetail}
                />
              )}
              {showcreatorEditpopup && (
              <EditCreators
                onClose={handleCloseEditCreators}
                collection={selectedCollection}
                updateCollections={fetchCollectionDetail}
              />
            )}
              {showSuccessPopup && (
                <div className="overlay1">
                  <div className="quote-popup">
                    <div className="cross" >
                      <img src={cross} alt="" onClick={() => setShowSuccessPopup(false)} />
                    </div>
                    <img src={comma} alt="" className="comma" />
                    <h3 className='hrs'>Our Team will contact you within next 24 Hours</h3>
                    <img src={rcomma} alt="" className="comma" />



                  </div>
                </div>
              )}

            </>
          )
          }


        </div>

      </div >
    </>
  )
}

export default CollectionDetails;


