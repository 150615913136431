import React, { useEffect, useState } from 'react';
import lead from "../../../media/lead.svg";
import bran from "../../../media/bran.svg";
import website from "../../../media/website.svg";
import product from "../../../media/product.svg";
import user from "../../../media/user.svg"
import celebrate from "../../../media/celebrate.png";
import { ConfigProvider, Slider } from 'antd';
import CreateCollection from '../../Collections/Collection components/CreateCollection';
import axios from "axios"


const CustomCampaign = ({ step, handleNextStep, handlePreviousStep, campaignId, setActiveComponent, setActiveItem }) => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [maxPrice, setMaxPrice] = useState(50000);
  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  const [collections, setCollections] = useState([]);
  const [suggestionText, setSuggestionText] = useState('');
  const userProfile = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (userProfile) {
      setSuggestionText(`${userProfile.company_name} ${selectedGoal} Campaign`);
    }
  }, [selectedGoal, userProfile]);

  console.log("collection.length", collections.length)
  const [showPopup, setShowPopup] = useState(false);

  const handleCreateCollectionClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get('https://brich.youthevents.in/Bdashboard/api/collections/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            user: ` ${JSON.parse(localStorage.getItem('user')).email}`
          }
        });
        setCollections(response.data);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    fetchCollections();
  }, []);

  console.log("collection is", collections)


  console.log("user profile", userProfile.email)

  const initialFormData = {
    user: null,
    creator_brand: null,
    goal: null,
    campaign_type: null,
    landing_page: false,
    landing_page_link: '',
    audience_type: '',
    age_range: 'All',
    gender: 'All',
    location: 'India',
    brand_website: '',
    brand_instahandle: '',
    campaign_name: suggestionText,
    campaign_description: '',
    content: '',
    content_type: '',
    content_type_file: null,
    budget: 0,
    minimum_budget: 0,
    maximum_budget: 0,
    amount_paid: 0,
    call_request: false,
    collection: campaignId,
    selected_ages: '',
    selected_genders: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const updateFormData = (field, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  console.log("form data is ", formData)

  const handleUserSelection = (userId) => {
    updateFormData('user', userId);
  };
  const updateCollections = async () => {
    try {
      const response = await axios.get('https://brich.youthevents.in/Bdashboard/api/collections/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          user: ` ${JSON.parse(localStorage.getItem('user')).email}`
        }
      });
      setCollections(response.data);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };



  const handlePostData = async () => {
    console.log('Form Data:', formData);

    // Make the POST request using formData
    try {
      const response = await fetch('https://brich.youthevents.in/Bdashboard/create-campaign/', {
        method: 'POST',
        body: JSON.stringify(formData), // Modify as needed based on backend expectations
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log('Campaign created successfully');
        handleNextStep()
      } else {
        console.error('Failed to create campaign');
      }
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };



  console.log("selected goal is ", selectedGoal);
  const [isPriceSelected, setIsPriceSelected] = useState(true);
  const [selectedCollection, setSelectedCollection] = useState(null);



  const handleCollectionSelection = (collectionId) => {
    setSelectedCollection(collectionId);
    updateFormData('collection', collectionId); // Update the form data with the selected collection ID
  };


  const handlePriceChange = (value) => {
    setPriceRange(value);
    setIsPriceSelected(false)
    updateFormData('minimum_budget', value[0]);
    updateFormData('maximum_budget', value[1]);

  };

  const handleGoalSelection = (goal) => {
    setSelectedGoal(goal);
    updateFormData('goal', goal);
    handleUserSelection(userProfile.id)
    updateFormData('creator_brand', userProfile.company_name);
    updateFormData('campaign_type', "Custom Campaign");

  };


  console.log("selected goal is ", selectedGoal);



  // landing page ========================================================================
  const [hasLandingPage, setHasLandingPage] = useState(false);

  const handleLandingPageSelection = (value) => {
    if (value === 'yes') {
      setHasLandingPage(true);
      updateFormData('landing_page', true);
    } else if (value === 'no') {
      setHasLandingPage(true);
      updateFormData('landing_page', false);
    }
  };

  const [brandWebsite, setBrandWebsite] = useState('');
  const [instagramHandle, setInstagramHandle] = useState('');

  const [BrandswebsiteStart, setBrandswebsiteStart] = useState(false);
  const [Intstagramhandlestart, setIntstagramhandlestart] = useState(false);

  const handleBrandWebsiteChange = (event) => {
    setBrandswebsiteStart(true)
    let url = event.target.value.trim(); // Remove leading and trailing whitespaces
  
    // Check if the URL is not empty
    if (url) {
      // Check if the URL doesn't start with http:// or https://
      if (!url.match(/^https?:\/\//i)) {
        // If not, prepend "https://"
        url = "https://" + url;
      }
    } else {
      // If the URL is empty, set it to an empty string
      url = "";
    }
    
    setBrandWebsite(url);
    updateFormData('brand_website', url);
  };
  
  

  // Function to handle Instagram handle change
  const handleInstagramHandleChange = (event) => {
    setIntstagramhandlestart(true)
    setInstagramHandle(event.target.value);
    updateFormData('brand_instahandle', event.target.value);
  };

  const isNextDisabled = brandWebsite.trim() === '' || instagramHandle.trim() === '';

  const [campaignName, setCampaignName] = useState('');
  const [campaignDescription, setCampaignDescription] = useState('');

  
  const [campaignnameStart, setcampaignnameStart] = useState('');
  const [descriptionStart, setdescriptionStart] = useState('');

  const handleCampaignNameChange = (event) => {
    setcampaignnameStart(true)
    const newValue = event.target.value;
    setCampaignName(event.target.value);
    updateFormData('campaign_name', newValue !== suggestionText ? newValue : suggestionText);
    updateFormData('content', "Own");
  };

  // Function to handle campaign description change
  const handleCampaignDescriptionChange = (event) => {
    setdescriptionStart(true)
    setCampaignDescription(event.target.value);
    updateFormData('campaign_description', event.target.value);
    updateFormData('campaign_name', suggestionText);

  };

  const[landing_pageStart , setlandingpageStart] = useState(false)

  const handleLandingPageLinkChange = (value) => {
      setlandingpageStart(true)
    let landingPageLink = value;
    // Check if the URL doesn't start with http:// or https://
    if (!landingPageLink.match(/^https?:\/\//i)) {
      // If not, prepend "https://"
      landingPageLink = "https://" + landingPageLink;
    }
    updateFormData('landing_page_link', landingPageLink);
  };


  const isCampNextDisabled = campaignDescription.trim() === '';

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className='smarts customs step1'>
            <div className="description">
              <div className="desc">
                <div className='general custom-general'>
                  <h3>Import a Collection</h3>
                </div>
                <p>Select and import a collection of influencers based on your marketing needs.</p>
              </div>
              <div className="both-btn">

                <div className="importC" onClick={handleCreateCollectionClick}>Create Collection</div>
                <div className='next custom-next' style={{ backgroundColor: collections.length === 0 || selectedCollection == null ? 'grey' : '' }} onClick={collections.length === 0 || selectedCollection == null ? null : handleNextStep} disabled={!collections.length === 0 || selectedCollection == null}>Next</div>
              </div>
            </div>
            <div className="working custom-working custom-collection ">
              {collections && collections.length === 0 ? (
                <>
                  <div className="not">
                    <h3>You don’t have any collections</h3>


                  </div>

                </>
              ) : (
                <>
                  {collections?.map((collection, index) => {
                    const allCategories = collection.creators?.map((creator) => creator.creator_type);
                    const sortedCategories = [...new Set(allCategories)].sort((a, b) => b.length - a.length);
                    const longCategory = sortedCategories[0];
                    const shortCategory = sortedCategories[sortedCategories.length - 1];
                    const uniqueCategories = sortedCategories.filter(
                      (category) => category !== longCategory && category !== shortCategory
                    );

                    return (
                      <div className='coll' key={index}>
                        <div className="colls">
                          <div className="CLname">{collection.collection_name}</div>
                          <div className="CLimage">

                            <div className="users">
                              {collection.creators?.length || 0}
                              <img src={user} alt="" />
                            </div>
                          </div>
                          <div className="CLcat">
                            <div className="numbers">
                              <div className="short">
                                {shortCategory ? shortCategory : "--"}
                              </div>
                              <div className="num">
                                +{uniqueCategories.length}
                              </div>

                            </div>
                            <div className="long">
                              {longCategory ? longCategory : '--'}
                            </div>
                          </div>
                          <div className="selector">
                            <input
                              type="radio"
                              name="collectionRadio"
                              id={`collectionRadio${index}`}
                              onChange={() => handleCollectionSelection(collection.id)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}

            </div>
            {showPopup && <CreateCollection onClose={handleClosePopup} updateCollections={updateCollections} />}
            <div className='custom-next1' style={{ backgroundColor: collections.length === 0 || selectedCollection == null ? 'grey' : '' }} onClick={collections.length === 0 || selectedCollection == null ? null : handleNextStep} disabled={!collections.length === 0 || selectedCollection == null}>Next</div>

          </div>
        );
      case 2:
        return (
          <div className='smarts customs step1'>
            <div className="description">
              <div className="desc">
                <div className='general custom-general'>
                  <h3>Select Your Goals</h3>
                </div>
              </div>
              <div className="backBtn">
                <div className="custom-back" onClick={handlePreviousStep}>Back</div>
                <div className='next custom-next' onClick={handleNextStep}>Next</div>
              </div>
            </div>
            <div className="working custom-working flex">
              <div className="goals cflex" onClick={() => handleGoalSelection('Lead Generation')}>
                <input type="radio" checked={selectedGoal === 'Lead Generation'} readOnly />
                <img src={lead} alt="" />
                <h3>Lead Generation</h3>
                <p>Identify potential Influencers and creators who will be the best suitable source for promoting your Brand. </p>
              </div>
              <div className="goals cflex" onClick={() => handleGoalSelection('Brand Awareness')}>
                <input type="radio" checked={selectedGoal === 'Brand Awareness'} readOnly />
                <img src={bran} alt="" />
                <h3>Brand Awareness</h3>
                <p> Let your brand be the hero for a successful business in the market.</p>
              </div>
              <div className="goals cflex" onClick={() => handleGoalSelection('Product Sales')}>
                <input type="radio" checked={selectedGoal === 'Product Sales'} readOnly />
                <img src={product} alt="" />
                <h3>Product Sales</h3>
                <p>Grow your sales with the best possible tools and services.</p>
              </div>
              <div className="goals cflex" onClick={() => handleGoalSelection('Website Visits')}>
                <input type="radio" checked={selectedGoal === 'Website Visits'} readOnly />
                <img src={website} alt="" />
                <h3>Website Visits</h3>
                <p>Boost your website visits, One Click at a Time</p>
              </div>
            </div>
            <div className="backBtn" style={{ marginTop: "32px" }}>
              <div className="custom-back1" onClick={handlePreviousStep}>Back</div>
              <div className='next1 custom-next1' onClick={handleNextStep} style={{ marginTop: "32px" }}>Next</div>
            </div>

          </div>
        );
      case 3:
        //  ----------------------  case2 ---------------------------------------------------------------------------------------------------

        if (selectedGoal === 'Lead Generation') {
          return (
            <div className='smarts customs step2'>

              <div className="description">
                <div className="desc">
                  <div className='general custom-general'>
                    <h3>Lead Generation</h3>
                  </div>
                  <p>Identify potential Influencers and creators who will be the best suitable source for promoting your Brand.</p>
                </div>
                <div className="backBtn">
                  <div className="custom-back" onClick={handlePreviousStep}>Back</div>
                  <div className='next custom-next' style={{ backgroundColor: hasLandingPage ? '' : 'grey' }} onClick={hasLandingPage ? handleNextStep : null} disabled={!hasLandingPage}>Next</div>
                </div>
              </div>
              <div className="working custom-working cflex landing">
                <h3>Do You Have a Landing Page ?</h3>
                <div className="asking flex">
                  <div className="ask cflex">
                    <input type="radio" readOnly name='landingpage' onClick={() => handleLandingPageSelection('yes')} />
                    <h3>Yes, I do</h3>
                    <p>Add your link to landing page below</p>
                    <input
                      type="text"
                      name="landingPageLink"
                      id="landingPageLink"
                      placeholder="eg: www.example.in"
                      readOnly={!hasLandingPage}
                      value={formData.landing_page_link} // Set the value from form data
                      onChange={(e) => handleLandingPageLinkChange(e.target.value)}  // Update form data on change
                      className={landing_pageStart ? "cbl" : ""}
                    />
                  </div>
                  <div className="ask cflex">
                    <input type="radio" name='landingpage' readOnly onClick={() => handleLandingPageSelection('no')} />
                    <h3>No</h3>
                    <p>Get one Created.</p>
                    <p>Our team can help you design a professional landing page tailored to your campaign.</p>
                  </div>

                </div>
              </div>
              <div className="backBtn" style={{ marginTop: "32px" }}>
                <div className="custom-back1" onClick={handlePreviousStep}>Back</div>
                <div className='next1 custom-next1' style={{ backgroundColor: hasLandingPage ? '' : 'grey' }} onClick={hasLandingPage ? handleNextStep : null} disabled={!hasLandingPage}>Next</div>
              </div>
            </div>
          );


        } else {
          handleNextStep()
        }


      case 4:
        //  ----------------------  case3 ---------------------------------------------------------------------------------------------------
        // to do add editor
        return (
          <div className='smarts customs step2'>

            <div className="description">
              <div className="desc">
                <div className='general custom-general'>
                  <h3>Add Brands Details</h3>
                </div>
              </div>
              <div className="backBtn">
                <div className="custom-back" onClick={handlePreviousStep}>Back</div>
                <div className='next custom-next' onClick={handleNextStep} >Next</div>
              </div>
            </div>
            <div className="working custom-working flex">
              <div className="link-insta ">
                <div className="link">
                  <h3>Add the link for brands website / product.</h3>
                  <input
                    type="text"
                    placeholder='eg: www.yourbrand.com'
                    value={brandWebsite}
                    onChange={handleBrandWebsiteChange}
                    className={BrandswebsiteStart ? "cbl" : ""}
                  />
                </div>
                <div className="link">
                  <h3>Please Provide Your Instagram Handle.</h3>
                  <input
                    type="text"
                    placeholder='eg: www.instagram.com/yourbrand'
                    value={instagramHandle}
                    onChange={handleInstagramHandleChange}
                    className={Intstagramhandlestart ? "cbl" : ""}
                  />
                </div>

              </div>


            </div>
            <div className="backBtn" style={{ marginTop: "32px" }}>
              <div className="custom-back1" onClick={handlePreviousStep}>Back</div>
              <div className='next1 custom-next1' style={{ marginTop: "32px" }} onClick={handleNextStep} >Next</div>
            </div>
          </div>
        );
      case 5:
        //  ----------------------  case3 ---------------------------------------------------------------------------------------------------
        // to do add editor
        return (
          <div className='smarts customs step2'>

            <div className="description">
              <div className="desc">
                <div className='general custom-general'>
                  <h3>Campaign Name</h3>
                </div>
              </div>
              <div className="backBtn">
                <div className="custom-back" onClick={handlePreviousStep}>Back</div>
                <div className='next custom-next' style={{ backgroundColor: isCampNextDisabled ? 'grey' : '' }} onClick={isCampNextDisabled ? null : handleNextStep} disabled={!isCampNextDisabled}>Next</div>
              </div>
            </div>
            <div className="working custom-working flex">
              <div className="link-insta ">
                <div className="link">
                  <h3>Add a Campaign Name</h3>
                  <input
                    type="text"
                    placeholder='Campaign Name'
                    value={campaignName || suggestionText}
                    onChange={handleCampaignNameChange}
                    className="cbl"
                  />
                </div>
                <div className="link">
                  <h3>Add Campaign Description</h3>
                  <input
                    type="text"
                    placeholder='Jot Down the Campaign Details'
                    value={campaignDescription}
                    onChange={handleCampaignDescriptionChange}
                    className={descriptionStart ? "cbl" : ""}
                  />
                </div>
              </div>

            </div>
            <div className="backBtn" style={{ marginTop: "32px" }}>
              <div className="custom-back1" onClick={handlePreviousStep}>Back</div>
              <div className='next1 custom-next1' style={{ backgroundColor: isCampNextDisabled ? 'grey' : '', marginTop: "32px" }} onClick={isCampNextDisabled ? null : handleNextStep} disabled={!isCampNextDisabled}>Next</div>
            </div>
          </div>
        );





      case 6:
        //  ----------------------  case7 ---------------------------------------------------------------------------------------------------
        return (
          <div className='smarts customs step7'>
            <div className="description">
              <div className="desc">
                <div className='general custom-general'>
                  <h3>Budget</h3>
                </div>
                <p>Adjust your budget for your campaign.</p>
              </div>
              <div className="backBtn">
                <div className="custom-back" onClick={handlePreviousStep}>Back</div>
                <div className='next custom-next' style={{ backgroundColor: isPriceSelected ? 'grey' : '' }} onClick={isPriceSelected ? null : handlePostData} disabled={isPriceSelected}>Next</div>
              </div>
            </div>
            <div className="budgets flex">


              <div className="set set-custom cflex ">
                <h3>Set Estimated Budget</h3>
                <p>Set your desired budget using the slider below.</p>
                <div className="brange flex">
                  <div className="cost"> {priceRange[0]}  - {priceRange[1]} ₹</div>

                </div>
                <div className="price-range">

                </div>
                <div className="discount cflex">
                  <div className="discount-slider">
                    <ConfigProvider
                      theme={{
                        components: {
                          Slider: {
                            railBg: "#0C2D1A",
                            trackBg: "rgba(90, 181, 129, 1)",
                            handleColor: "#5AB581",
                            handleActiveColor: "#5AB581",
                            dotBorderColor: "#5AB581",
                            dotActiveBorderColor: "#5AB581",
                            trackHoverBg: "rgba(90, 181, 129, 1)",
                            railHoverBg: "#0C2D1A"

                          }
                        },
                      }}
                    >
                      <Slider
                        range
                        step={10}
                        defaultValue={[10, maxPrice]}
                        min={10000}
                        max={maxPrice}
                        onChange={handlePriceChange}
                      />
                    </ConfigProvider>

                  </div>
                </div>
              </div>

            </div>

            <div className="backBtn" style={{ marginTop: "32px" }}>
              <div className="custom-back1" onClick={handlePreviousStep}>Back</div>
              <div className='next1 custom-next1' style={{ backgroundColor: isPriceSelected ? 'grey' : '', marginTop: "32px" }} onClick={isPriceSelected ? null : handlePostData} disabled={isPriceSelected}>Next</div>
            </div>

          </div>

        )



      case 7:
        //  ----------------------  case9 ---------------------------------------------------------------------------------------------------
        return (
          <div className='smarts customs step2 smarty cflex'>
            <img src={celebrate} alt="" />
            <div className="general custom-general" style={{ maxWidth: "248px" }}>
              <h3>Your Campaign is Ready </h3>
            </div>
            <p>Our Team will contact you within next 24 Hours</p>
            <div className="next next1" style={{ width: "120px", height: "32px", display: "flex" }} onClick={() => { setActiveComponent('manageCampaign'); setActiveItem('manageCampaign'); }}>Great</div>


          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="custom-campaign-steps">
      <div className="smart-heading" >
        <h3>CUSTOM CAMPAIGN</h3>
        <p>Make your own campaign</p>
      </div>

      {renderStepContent()}
    </div>
  );
};

export default CustomCampaign;
