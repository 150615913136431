import React, { useState } from 'react'
import "./signup.css"
import security from "../../media/security.png"
import google from "../../media/google.svg"
import { Link } from 'react-router-dom'
import SigninleftNavbar from '../SigninNavbar/SigninleftNavbar'
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../../redux/filterSlice'
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';

const Signin = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);
    const [Signin, setSignin] = useState("Sign-In");
    const [googleResponse, setGoogleResponse] = useState('');
    const [userNotFound, setUserNotFound] = useState(false);

    const setAuthToken = (token) => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    };


    const handleSignIn = async () => {
        try {
            setError(false)
            setErrorMessage('');
            setSignin("Signin in...")
            const response = await axios.post('https://brich.youthevents.in/Bdashboard/sign-in/', {
                email: email,
                password: password
            });

            const { token, user } = response.data;

            // Save the token in local storage or cookies
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));

            // Set the token in the Axios headers for subsequent requests
            setAuthToken(token);


            console.log('Response from server:', response.data);
            // console.log("response.status",response.status)


            if (response.status === 200) {
                dispatch(setUserAuthenticated(true));
                window.location.href = `/`;

            } else if (error.response.status === 401) {

                setError(true)
                setErrorMessage('Password or Email does not match');
                setSignin("Signin")
            }
            else {
                setError(true)
                setErrorMessage('Error while signing in:');
                setSignin("Signin")
            }
        } catch (error) {
            setError(true)

            console.error('Error while signing in:', error);
            // Handle errors if the request fails
            setErrorMessage('Password or Email does not match');

            setSignin("Signin")

        }
    };

    const responseGoogle = (response) => {
        console.log(response);
        setGoogleResponse(response);
        // Extract necessary user details from the response
        const { tokenId, profileObj } = response;

        // Make a POST request to your backend API with the user details
        axios.post(' https://brich.youthevents.in/Bdashboard/google/signin/', JSON.stringify({
            token_id: tokenId,
            email: profileObj?.email,
            name: profileObj?.name,
            image: profileObj?.imageUrl
            // Add any other required user details here
        }), {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                const { token, user } = response.data;

                // Save the token in local storage or cookies
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
    
                // Set the token in the Axios headers for subsequent requests
                setAuthToken(token);
    
    
                console.log('Response from server:', response.data);
                // console.log("response.status",response.status)
    
    
                if (response.status === 200) {
                    dispatch(setUserAuthenticated(true));
                    window.location.href = `/`;
    
                } else if (error.response.status === 401) {
    
                    setError(true)
                    setErrorMessage('Password or Email does not match');
                    setSignin("Signin")
                }
                else {
                    setError(true)
                    setErrorMessage('Error while signing in:');
                    setSignin("Signin")
                }
            }  )  
            .catch(error => {
                // setError(true)

                console.error('Error while signing in:', error);
                // Handle errors if the request fails
                setErrorMessage('Email is not associated with any user');
    
                setSignin("Signin")
            });
    };

    const handleGooglefailure = async () => {
        setErrorMessage('Error Try again ');
    }


    const logoutUser = () => {
        localStorage.removeItem('token');
        setAuthToken(null);
        // Perform any additional actions on logout
    };


    return (
        <div className="general-right">

            <div className="right flex">
                <div className="signin">
                    <div className="signin-head">
                        <h3>Sign In</h3>
                        <p>Enter Your Details Below to Continue.</p>
                    </div>
                    <div className="signbox cflex">
                        <div className={`user-pass cflex ${error ? 'errored' : ''}`}>
                            <input type="text" placeholder='Email / Phone No.' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <Link to={'/forget-password'} className="forget">forgot password ?</Link>
                        <div className="error-message">{errorMessage}</div>
                        <div className="gsign cflex" >
                            <div to={'/'} className="google" onClick={handleSignIn}>{Signin}</div>
                            {/* <div to={'/'} className="google" onClick={handleGoogleSignin}><img src={google} alt="" /> Continue with Google</div> */}
                            <GoogleLogin
                                clientId="956050916143-41t5n2khuru0rup7mq0l6ojfjjfu9ohn.apps.googleusercontent.com"
                                buttonText="Continue with Google"
                                onSuccess={responseGoogle}
                                onFailure={handleGooglefailure}
                                cookiePolicy={'single_host_origin'}
                                prompt="select_account" // Prompt the user to select an account
                                className='google'
                            />

                        </div>
                    </div>
                    <Link to={'/sign-up'} className="already" >
                        <h3>Don’t Have an Account? Sign Up</h3>
                    </Link>

                </div>
                <div className="drawing cflex">
                    <div className="draw-head">
                        {/* <h3>LOREM IPSUM</h3> */}
                        <p>Secure and easy access to your account. Manage your campaigns and track your progress with ease.</p>
                    </div>
                    <img src={security} alt="" />
                </div>

            </div>
        </div>
    )
}

export default Signin
