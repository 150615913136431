import React from 'react'
import cross from "../../media/cross.svg";
import NotSigninleftNavbar from '../Navbar/SigninleftNavbar';

const Privacy = ({ onClose }) => {
  return (
    <div className='general-right'>
      <div className="right">
        <h3 className='pricavy-head'>Refunds & Cancelation Policy</h3>
        <div className="policy-box">
          <div className="privacy-wrap">

            <div className="privacies" >
              <h3>Refunds</h3>
              <p><br />
                 The Platform does not provide any refunds once a campaign has already started.
                <br />
                 In exceptional cases, refunds may be processed if the campaign has not been allocated to influencers.
                <br />
                 If a refund is approved, a share will be deducted from the refund amount to cover transaction charges and consulting fees of our team.
                <br />
                <br />
              </p>
            </div>
            <div className="privacies">
              <h3>Cancellation</h3>
              <p><br />
                 Brands may request cancellation of their campaign before it has been allocated to influencers.
                <br />
                 Once a campaign has been allocated to influencers, cancellation is not possible, and no refunds will be provided.
                <br />
                <br />
              </p>
            </div>
            <div className="privacies">
              <h3>Refund Process</h3>
              <p> <br />
                 To request a refund, Brands must contact us at info@brich.in with their refund request.
                <br />
                 Refunds, if approved, will be processed within a reasonable timeframe.
                <br />
                 The refunded amount will be subject to deduction for transaction charges and consulting fees, as mentioned above.
                <br />
                <br />
              </p>
            </div>
            <div className="privacies">
              <h3>Exceptional Circumstances</h3>
              <p><br />
                 In exceptional circumstances, such as technical issues or force majeure events, where the Platform is unable to fulfill its obligations, refunds may be considered on a case-by-case basis.
                <br />
                 Any decision regarding refunds in such circumstances will be at the sole discretion of M/S BRICH.
                <br />
                <br />

              </p>
            </div>
            <div className="privacies">
              <h3>Contact Information</h3>
              <p><br />
                 If you have any questions about our Refunds & Cancellation Policy, you may contact us at info@brich.in.
                <br />
                <br />
                By using the Platform, you acknowledge that you have read, understood, and agree to be bound by this Refunds & Cancellation Policy.
                <br />
                <br /></p>
            </div>
            <div className="privacies">
              <h3></h3>
              <p> Last Updated: [Date]</p>
            </div>

          </div>
        </div>


      </div>
    </div>

  )
}

export default Privacy

