import React, { useEffect, useState } from 'react';
import cross from "../../../media/cross.svg";
import line from "../../../media/line.svg";
import vibe from "../../../media/vibe.png";
import FilterDropdown from '../../Signin Home page/Signin components/FilterDropdown';
import avatar from "../../../media/place.jpg"
import insta from "../../../media/insta.svg"
import yt from "../../../media/yt.svg"
import { useDispatch, useSelector } from "react-redux";
import { setCollections } from "../../../redux/filterSlice";
import sorts from "../../../media/sorts.svg"
import axios from "axios"
import down from "../../../media/down.svg"
import mobiledrop from "../../../media/mobiledrop.svg"




const CreateCollection = ({ onClose, updateCollections }) => {
  const dispatch = useDispatch();
  const Collections = useSelector(state => state.filters.collections);
  const [step, setStep] = useState(1);



  const handleProceed = () => {
    if (step === 1) {
      setStep(2); // Proceed to step 2 (adding creators)
    }
  };
  const [openFilter, setOpenFilter] = useState(null);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(true);
  const [collectionName, setCollectionName] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');


  const handleFilterChange = (filter) => {
    setOpenFilter(filter === openFilter ? null : filter);
  };


  const handleCheckboxChange = (creator) => {
    setSelectedCreators((prevSelected) =>
      prevSelected.includes(creator)
        ? prevSelected.filter((selected) => selected !== creator)
        : [...prevSelected, creator]
    );
  };

  const handleCreatorClick = (creator) => {
    if (isSelectionMode) {
      handleCheckboxChange(creator);
    }
  };

  console.log(selectedCreators);
  const storedUser = localStorage.getItem('user'); // Replace 'user' with your actual storage key
  const parsedUser = storedUser ? JSON.parse(storedUser) : null;
  console.log("stored user is", parsedUser.email)

  const [creatorsDatas, setCreatorsDatas] = useState([]);

  useEffect(() => {
    // Function to fetch data from the backend API
    const fetchCreatorsData = async () => {
      try {
        // Make a GET request to the backend API endpoint (adjust the URL accordingly)
        const response = await axios.get('https://brich.youthevents.in/Bdashboard/get-all-creators-data');

        // Update state with the received data
        setCreatorsDatas(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetch function when the component mounts
    fetchCreatorsData();
  }, []); // Empty dependency array ensures the effect runs once

  console.log("cretor datas", creatorsDatas)


  const handleSkip = () => {
    const collectionData = {
      name: collectionName,
      description: collectionDescription,
      // creators: [],
      user: parsedUser.email
    };
    const token = localStorage.getItem('token');

    axios.post('https://brich.youthevents.in/Bdashboard/api/collections/', collectionData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      console.log('Collection created successfully:', response.data);
      onClose();
      updateCollections();
    })
      .catch(error => {
        console.error('Error creating collection:', error);
      });

    // dispatch(setCollections([...Collections, collectionData]));

    onClose();

  };

  const handleDone = () => {
    // Create an object containing collection details
    const collectionData = {
      name: collectionName,
      description: collectionDescription,
      creators: selectedCreators.map(creator => ({
        email_id: creator.id,

      })),
      user: parsedUser.email

    };

    const token = localStorage.getItem('token');

    axios.post('https://brich.youthevents.in/Bdashboard/api/collections/', collectionData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        console.log('Collection created successfully:', response.data);
        onClose();
        updateCollections();
      })
      .catch(error => {
        console.error('Error creating collection:', error);
      });

    const isCollectionExists = Collections.some(existingCollection =>
      existingCollection.name === collectionData.name // Change this comparison as per your unique identifier
    );

    if (!isCollectionExists) {
      dispatch(setCollections([...Collections, collectionData]));
    } else {

    }

    onClose();
  };


  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownToggle = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdownName);
    }
  };

  const [selectedDepartment, setSelectedDepartment] = useState('');
  const departments = ["Fashion", "Entertainment", "Artist", "Fitness", "Gaming"];

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setActiveDropdown(null);
  };

  const [selectedfollowers, setSelectedfollowers] = useState('');
  const followers = ["All", "10k- 20k", "20k- 40k", "40k- 60k", "60k- 80k", "80k- 100k"];

  const handlefollowersSelect = (followers) => {
    setSelectedfollowers(followers);
    setActiveDropdown(null);
  };

  const [selectedplatform, setSelectedplatform] = useState('');
  const platform = ["Youtube", "Instagram", "Both"];

  const handleplatformSelect = (platform) => {
    setSelectedplatform(platform);
    setActiveDropdown(null);
  };

  const [selectedlocation, setSelectedlocation] = useState('');
  const locations = ["Fashion", "Entertainment", "Artist", "Fitness", "Gaming"];

  const handlelocationSelect = (location) => {
    setSelectedlocation(location);
    setActiveDropdown(null);
  };

  useEffect(() => {
    setActiveDropdown(null);
  }, []);

  const [filteredCreatorData, setFilteredCreatorsData] = useState(creatorsDatas)
  useEffect(() => {
    // Function to filter data when dropdown selection changes
    const filterData = () => {
      let filteredData = [...creatorsDatas];

      // Filter by selectedDepartment
      if (selectedDepartment) {
        filteredData = filteredData.filter(creator => creator.creator_type === selectedDepartment);
      }

      // Filter by selectedfollowers
      if (selectedfollowers) {
        // Add logic to filter by followers count or relevant property
        filteredData = filteredData.filter(creator => creator.followersCount === selectedfollowers);
      }

      // Filter by selectedplatform
      if (selectedplatform) {
        // Add logic to filter by platform or relevant property
        filteredData = filteredData.filter(creator => creator.platform === selectedplatform);
      }

      // Filter by selectedlocation
      if (selectedlocation) {
        // Add logic to filter by location or relevant property
        filteredData = filteredData.filter(creator => creator.location === selectedlocation);
      }

      // Set state with filtered data
      setFilteredCreatorsData(filteredData);
    };

    // Call the filter function when dropdown values change
    filterData();
  }, [selectedDepartment, selectedfollowers, selectedplatform, selectedlocation, creatorsDatas]);

  const [showFilter, setshowFilter] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null);


  const handleFilterItemClick = (filter) => {
    setSelectedFilter(filter === selectedFilter ? null : filter);
  };

  const handleDoneFilters = () => {
    setshowFilter(false)
  }

  const formatNumber = (num) => {
    const numValue = parseInt(num);
    if (numValue >= 1000000) {
        return (parseFloat((numValue / 1000000).toFixed(1)) + 'M').replace('.0', '');
    }
    if (numValue >= 1000) {
        return (parseFloat((numValue / 1000).toFixed(1)) + 'K').replace('.0', '');
    }
    return num;
};

  return (
    <div className="overlay">
      <div className="create-collection-popup">
        <div className="cross" onClick={onClose}>
          <img src={cross} alt="" />
        </div>

        {step === 1 && (
          <>
            <div className="h3">
              <h3>Create a Collection</h3>
              <img src={line} alt="" />
            </div>
            <div className="write">
              <h3>Add Collection Name</h3>
              <input
                type="text"
                placeholder="eg: Festival Campaign"
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
            </div>
            <div className="write">
              <h3>Add Collection Description</h3>
              <textarea
                type="text"
                placeholder="eg: Describe your collection and ideas"
                value={collectionDescription}
                onChange={(e) => setCollectionDescription(e.target.value)}
              />
            </div>
            <div className="proceed" onClick={handleProceed}>
              Proceed
            </div>
            <div className="vibe">
              <img src={vibe} alt="" />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="add-creator">
              <div className="addh3">
                <h3>Add Creators in Collection</h3>
                <img src={line} alt="" />
              </div>
              <div className="btns1">
                <div className="skip" onClick={handleSkip}>Skip</div>
                <div className="done" onClick={handleDone}>Done</div>
              </div>
              <div className="filters" style={{ marginTop: "28px", marginBottom: "22px", marginRight: "0" }}>
                {/* Category Dropdown */}
                <div className="custom-drop">
                  <input
                    type='text'
                    className='filter'
                    placeholder='Category'
                    value={selectedDepartment}
                    readOnly
                    onClick={() => handleDropdownToggle('department')}
                    style={{ position: "relative", maxWidth: "223px" }}
                  />
                  <img
                    src={down}
                    alt=""
                    className={`droparr ${activeDropdown === 'department' ? 'rotated' : ''}`}
                    onClick={() => handleDropdownToggle('department')}
                  />
                  {activeDropdown === 'department' && (
                    <ul className='filter-options'>
                      {departments.map((department, index) => (
                        <li className='filter-option' key={index} onClick={() => handleDepartmentSelect(department)}>
                          {department}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Followers Dropdown */}
                <div className="custom-drop">
                  <input
                    type='text'
                    className='filter'
                    placeholder='Followers'
                    value={selectedfollowers}
                    readOnly
                    onClick={() => handleDropdownToggle('followers')}
                    style={{ position: "relative", maxWidth: "223px" }}
                  />
                  <img
                    src={down}
                    alt=""
                    className={`droparr ${activeDropdown === 'followers' ? 'rotated' : ''}`}
                    onClick={() => handleDropdownToggle('followers')}
                  />
                  {activeDropdown === 'followers' && (
                    <ul className='filter-options'>
                      {followers.map((follower, index) => (
                        <li className='filter-option' key={index} onClick={() => handlefollowersSelect(follower)}>
                          {follower}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Platform Dropdown */}
                <div className="custom-drop">
                  <input
                    type='text'
                    className='filter'
                    placeholder='Platform'
                    value={selectedplatform}
                    readOnly
                    onClick={() => handleDropdownToggle('platform')}
                    style={{ position: "relative", maxWidth: "223px" }}
                  />
                  <img
                    src={down}
                    alt=""
                    className={`droparr ${activeDropdown === 'platform' ? 'rotated' : ''}`}
                    onClick={() => handleDropdownToggle('platform')}
                  />
                  {activeDropdown === 'platform' && (
                    <ul className='filter-options'>
                      {platform.map((platform, index) => (
                        <li className='filter-option' key={index} onClick={() => handleplatformSelect(platform)}>
                          {platform}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Location Dropdown */}
                <div className="custom-drop">
                  <input
                    type='text'
                    className='filter'
                    placeholder='Location'
                    value={selectedlocation}
                    readOnly
                    onClick={() => handleDropdownToggle('location')}
                    style={{ position: "relative", maxWidth: "223px" }}
                  />
                  <img
                    src={down}
                    alt=""
                    className={`droparr ${activeDropdown === 'location' ? 'rotated' : ''}`}
                    onClick={() => handleDropdownToggle('location')}
                  />
                  {activeDropdown === 'location' && (
                    <ul className='filter-options'>
                      {locations.map((location, index) => (
                        <li className='filter-option' key={index} onClick={() => handlelocationSelect(location)}>
                          {location}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="filter-logo filter-logo1" onClick={() => setshowFilter(!showFilter)}>
                <img src={sorts} alt="" />
                Filters
              </div>
              <div className="select-creator">

                {showFilter ? (
                  <div className='mobile-filters'>
                    <div className="filter-item" onClick={() => handleFilterItemClick('Categories')}>
                      <div className="mc">

                        <h3>Categories</h3>
                        <img src={mobiledrop} alt="" />
                      </div>
                      {selectedFilter === 'Categories' && (
                        // <div className="category-options">
                        <ul className='mobileul'>
                          {departments.map((department, index) => (
                            <li className='mfilter-option' key={index} onClick={() => handleDepartmentSelect(department)}>
                              {department}
                            </li>
                          ))}
                        </ul>
                        // </div>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Followers')}>
                      <div className="mc">


                        <h3>Followers</h3>
                        <img src={mobiledrop} alt="" />
                      </div>
                      {selectedFilter === 'Followers' && (
                        <ul className='mobileul'>
                          {followers.map((follower, index) => (
                            <li className='mfilter-option' key={index} onClick={() => handlefollowersSelect(follower)}>
                              {follower}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Platform')}>
                      <div className="mc">


                        <h3>Platform</h3>
                        <img src={mobiledrop} alt="" />
                      </div>
                      {selectedFilter === 'Platform' && (
                        <ul className='mobileul'>
                          {platform.map((platform, index) => (
                            <li className='mfilter-option' key={index} onClick={() => handleplatformSelect(platform)}>
                              {platform}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Location')}>
                      <div className="mc">


                        <h3>Location</h3>
                        <img src={mobiledrop} alt="" />
                      </div>
                      {selectedFilter === 'Location' && (
                        <ul className='mobileul'>
                          {locations.map((location, index) => (
                            <li className='mfilter-option' key={index} onClick={() => handlelocationSelect(location)}>
                              {location}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="filter-item" onClick={() => handleFilterItemClick('Sort')}>
                      <div className="mc">


                        <h3>Sort</h3>
                        <img src={mobiledrop} alt="" />
                      </div>
                      {selectedFilter === 'Sort' && (
                        <ul className='mobileul'>
                          <li>Option A</li>
                          <li>Option B</li>
                          <li>Option C</li>
                          {/* Add more sort options */}
                        </ul>
                      )}
                    </div>



                    <div className="done-black" onClick={handleDoneFilters}>Done</div>

                  </div>
                ) : (
                  <>
                    {filteredCreatorData.map((creator, index) => (
                      <div
                        className={`Screator ${isSelectionMode && selectedCreators.includes(creator) ? 'selected' : ''}`}
                        key={index}
                        onClick={() => handleCreatorClick(creator)}
                      >
                        <div className="Screator-wrap">
                          {isSelectionMode && (
                            <></>
                          )}
                          <div className="Sname">
                            <div className="profileMwrapper">

                              <img src={creator.profile ? creator.profile  : avatar } alt="" />
                            </div>
                            <div className="name-checkbox  select-check">
                              <input
                                type="checkbox"
                                checked={selectedCreators.includes(creator)}
                                onChange={() => handleCheckboxChange(creator)}
                              />
                              <label></label>
                            </div>
                            <div className='Shead'>
                              <h3>{creator.name}</h3>
                              <h4>{creator.creator_type}</h4>
                              <h4>{creator.city}</h4>

                            </div>
                          </div>
                          <div className="fc">
                            <div className="Sfollower">{formatNumber(creator.follower)}</div>
                            <div className="Scontent-type">{creator.content_type}</div>
                          </div>
                          <div className="Ssocial">
                            <img src={insta} alt="" />
                            <img src={yt} alt="" />
                          </div>
                        </div>

                      </div>
                    ))}
                  </>
                )}


              </div>
            </div>

          </>
        )}

      </div>
    </div>
  );
};

export default CreateCollection;
