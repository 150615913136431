import { React, useEffect, useState } from 'react'
import "./signin.css"
import arrow from "../../media/arrow.svg"
import phone from "../../media/phone.svg"
import sort from "../../media/sort.svg"
import down from "../../media/down.svg"
import avatar from "../../media/place.jpg"
import insta from "../../media/insta.svg"
import yt from "../../media/yt.svg"
import sorts from "../../media/sorts.svg"
import dots from "../../media/dots.svg"
import mobiledrop from "../../media/mobiledrop.svg"
import collections from "../../media/collections.svg"
import SearchBar from './Signin components/Searchbar'
import CreatorMenu from './Signin components/CreatorMenu'
import AddToCollection from './Signin components/AddToCollection'
import axios from 'axios';
import SubmittedpopupBanner from './Signin components/SubmittedpopupBaner'


const SigninHomePage = () => {
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [isSelectionMode1, setIsSelectionMode1] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showfilters, setShowFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);


  const handleRequestCallback = () => {
    setShowContactPopup(true);
  };

  const handleFormSubmit = () => {
    // You can add form validation and submission logic here.
    // For now, just set the showThankYouPopup to true.
    setShowThankYouPopup(true);
  };
  const handleselectionmode = () => {
    // You can add form validation and submission logic here.
    // For now, just set the showThankYouPopup to true.
    setIsSelectionMode(false);
  };



  const handleFilterItemClick = (filter) => {
    setSelectedFilter(filter === selectedFilter ? null : filter);
  };

  const handleShowFilters = () => {
    setShowFilters(true)
  }
  const handleDoneFilters = () => {
    setShowFilters(false)
  }

  const handleCollections = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };




  const handleDotsClick = (event, creator) => {
    event.preventDefault();
    const rect = event.target.getBoundingClientRect();
    setMenuPosition({ top: rect.top + window.scrollY });
    setSelectedCreator(creator);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = () => {
    // Handle the "Select" action here
    
    setIsSelectionMode(true);
    handleMenuClose();
  };

  const handleMenuScreen = () => {
    // Handle the "Select" action here
    if(isMenuOpen){
      setIsMenuOpen(false)
    }
    
  };


  const handleAddToCollection = () => {
    // Handle the "Add to Collection" action here
    setShowPopup(true)
    
    console.log(`Added ${selectedCreator.name} to collection`);
    handleMenuClose();
  };

  const handleCheckboxChange = (creator) => {
    setSelectedCreators((prevSelected) =>
      prevSelected.includes(creator)
        ? prevSelected.filter((selected) => selected !== creator)
        : [...prevSelected, creator]
    );
  };

  const handleCreatorClick = (creator) => {
    if (isSelectionMode || isSelectionMode1) {
      handleCheckboxChange(creator);
    }
  };

  console.log(selectedCreators);

  const [creatorsDatas, setCreatorsDatas] = useState([]);

  useEffect(() => {
    // Function to fetch data from the backend API
    const fetchCreatorsData = async () => {
      try {
        // Make a GET request to the backend API endpoint (adjust the URL accordingly)
        const response = await axios.get('https://brich.youthevents.in/Bdashboard/get-all-creators-data');

        // Update state with the received data
        setCreatorsDatas(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetch function when the component mounts
    fetchCreatorsData();
  }, []); // Empty dependency array ensures the effect runs once

  console.log("cretor datas", creatorsDatas)

  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownToggle = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdownName);
    }
  };

  // filter functions -----------------------------------------------------------

  const creatorTypes = Array.from(new Set(creatorsDatas?.map(item => item.creator_type)));

  const cities = Array.from(new Set(creatorsDatas?.map(item => item.city)));


  const [selectedDepartment, setSelectedDepartment] = useState('');

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setActiveDropdown(null);
  };

  const [selectedfollowers, setSelectedfollowers] = useState('');
  const followers = ["All", "10k- 20k", "20k- 40k", "40k- 60k", "60k- 80k", "80k- 100k"];

  const handlefollowersSelect = (followers) => {
    setSelectedfollowers(followers);
    setActiveDropdown(null);
  };

  const [selectedplatform, setSelectedplatform] = useState('');
  const platform = ["Youtube", "Instagram", "Both"];

  const handleplatformSelect = (platform) => {
    setSelectedplatform(platform);
    setActiveDropdown(null);
  };

  const [selectedlocation, setSelectedlocation] = useState('');

  const handlelocationSelect = (location) => {
    setSelectedlocation(location);
    setActiveDropdown(null);
  };

  useEffect(() => {
    setActiveDropdown(null);
  }, []);

  const [filteredCreatorData, setFilteredCreatorsData] = useState(creatorsDatas)
  useEffect(() => {
    // Function to filter data when dropdown selection changes
    const filterData = () => {
      let filteredData = [...creatorsDatas];

      // Filter by selectedDepartment
      if (selectedDepartment) {
        filteredData = filteredData.filter(creator => creator.creator_type === selectedDepartment);
      }

      if (selectedfollowers && selectedfollowers !== 'All') {
        const range = selectedfollowers.split('-').map(str => parseInt(str.trim())*1000);
        console.log("range ",range)
        filteredData = filteredData.filter(creator => {
          const followers = parseInt(creator.follower);
          return followers >= range[0] && followers <= range[1];
        });
      }

      if (selectedplatform) {
        console.log("selected platform",selectedplatform)
        // Add logic to filter by platform or relevant property
        filteredData = filteredData.filter(creator => {
          if (selectedplatform === 'Youtube') {
            return creator.youtube_link !== null && creator.youtube_link !== '';
          } else if (selectedplatform === 'Instagram') {
            return creator.instagam_link !== null && creator.instagam_link !== '';
          } else if (selectedplatform === 'Both') {
            return creator.youtube_link !== null && creator.instagam_link !== null;
          }
          return false;
        });
      }

      // Filter by selectedlocation
      if (selectedlocation) {
        // Add logic to filter by location or relevant property
        filteredData = filteredData.filter(creator => creator.city === selectedlocation);
      }

      // Set state with filtered data
      setFilteredCreatorsData(filteredData);
    };

    // Call the filter function when dropdown values change
    filterData();
  }, [selectedDepartment, selectedfollowers, selectedplatform, selectedlocation, creatorsDatas]);

  console.log("filtered dataa ", filteredCreatorData)

 const formatNumber = (num) => {
    const numValue = parseInt(num);
    if (numValue >= 1000000) {
        return (parseFloat((numValue / 1000000).toFixed(1)) + 'M').replace('.0', '');
    }
    if (numValue >= 1000) {
        return (parseFloat((numValue / 1000).toFixed(1)) + 'K').replace('.0', '');
    }
    return num;
};

  return (
    <>
      <div className='general-right' onClick={handleMenuScreen}>
        {/* <SigninleftNavbar /> */}
        <div className="right" >
          <div className="search-bars ">
            <SearchBar placeholder="Creators" data={creatorsDatas} />
          </div>
          <div className="welcome">
            <div className="head">
              <h3>WELCOME 🥳</h3>
              <p>Welcome to your Brand Dashboard, where you will get to explore our exclusive range of creators and many more tools!</p>

            </div>
            <div className="request" onClick={handleRequestCallback}>
              Request a Callback
              <div className="images">
                <img src={phone} alt="" />
                <img className='arrow' src={arrow} alt="" />

              </div>

            </div>
          </div>
          <div className="filter-list">
            <div className="list-head">
              <h3>Creators List</h3>
              <p>{creatorsDatas?.length} Creators</p>
            </div>
            {isSelectionMode ? (
              <div className="addtoc" onClick={handleCollections}>
                <div className="selectedno">{selectedCreators.length}</div>
                Add to Collection
                <img src={collections} alt="" />
              </div>
            ) : (
              <>
                <div className="filters filters1">
                  <div className="custom-drop">
                    <input
                      type='text'
                      className='filter'
                      placeholder='Category'
                      value={selectedDepartment}
                      readOnly
                      onClick={() => handleDropdownToggle('department')}
                      style={{ position: "relative", maxWidth: "223px" }}
                    />
                    <img
                      src={down}
                      alt=""
                      className={`droparr ${activeDropdown === 'department' ? 'rotated' : ''}`}
                      onClick={() => handleDropdownToggle('department')}
                    />
                    {activeDropdown === 'department' && (
                      <ul className='filter-options'>
                        {creatorTypes.map((department, index) => (
                          <li className='filter-option' key={index} onClick={() => handleDepartmentSelect(department)}>
                            {department}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  {/* Followers Dropdown */}
                  <div className="custom-drop">
                    <input
                      type='text'
                      className='filter'
                      placeholder='Followers'
                      value={selectedfollowers}
                      readOnly
                      onClick={() => handleDropdownToggle('followers')}
                      style={{ position: "relative", maxWidth: "223px" }}
                    />
                    <img
                      src={down}
                      alt=""
                      className={`droparr ${activeDropdown === 'followers' ? 'rotated' : ''}`}
                      onClick={() => handleDropdownToggle('followers')}
                    />
                    {activeDropdown === 'followers' && (
                      <ul className='filter-options'>
                        {followers.map((follower, index) => (
                          <li className='filter-option' key={index} onClick={() => handlefollowersSelect(follower)}>
                            {follower}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  {/* Platform Dropdown */}
                  <div className="custom-drop">
                    <input
                      type='text'
                      className='filter'
                      placeholder='Platform'
                      value={selectedplatform}
                      readOnly
                      onClick={() => handleDropdownToggle('platform')}
                      style={{ position: "relative", maxWidth: "223px" }}
                    />
                    <img
                      src={down}
                      alt=""
                      className={`droparr ${activeDropdown === 'platform' ? 'rotated' : ''}`}
                      onClick={() => handleDropdownToggle('platform')}
                    />
                    {activeDropdown === 'platform' && (
                      <ul className='filter-options'>
                        {platform.map((platform, index) => (
                          <li className='filter-option' key={index} onClick={() => handleplatformSelect(platform)}>
                            {platform}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  {/* Location Dropdown */}
                  <div className="custom-drop">
                    <input
                      type='text'
                      className='filter'
                      placeholder='Location'
                      value={selectedlocation}
                      readOnly
                      onClick={() => handleDropdownToggle('location')}
                      style={{ position: "relative", maxWidth: "223px" }}
                    />
                    <img
                      src={down}
                      alt=""
                      className={`droparr ${activeDropdown === 'location' ? 'rotated' : ''}`}
                      onClick={() => handleDropdownToggle('location')}
                    />
                    {activeDropdown === 'location' && (
                      <ul className='filter-options'>
                        {cities?.map((location, index) => (
                          <li className='filter-option' key={index} onClick={() => handlelocationSelect(location)}>
                            {location}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <FilterDropdown label="Categories" options={["Fashion", "Entertainment", "Artist", "Fitness", "Gaming",]} openFilter={openFilter} onFilterChange={handleCategoryFilterChange} />
                  <FilterDropdown label="Followers" options={["All", "10k- 20k", "20k- 40k", "40k- 60k", "60k- 80k", "80k- 100k"]} openFilter={openFilter} onFilterChange={handleFilterChange} />
                  <FilterDropdown label="Platform" options={["Youtube", "Instagram", "Both"]} openFilter={openFilter} onFilterChange={handleFilterChange} />
                  <FilterDropdown label="Location" options={["Option 1", "Option 2", "Option 3"]} openFilter={openFilter} onFilterChange={handleFilterChange} /> */}

                  <div className="sort"><img src={sort} alt="" /></div>
                </div>
                <div className="filter-logo" onClick={handleShowFilters}>
                  <img src={sorts} alt="" />
                  Filters
                </div>
              </>
            )}
            {showfilters && (
              <div className='mobile-filters'>
                <div className="filter-item" onClick={() => handleFilterItemClick('Categories')}>
                  <div className="mc">

                    <h3>Categories</h3>
                    <img src={mobiledrop} alt="" />
                  </div>
                  {selectedFilter === 'Categories' && (
                    // <div className="category-options">
                    <ul className='mobileul'>
                      {creatorTypes.map((department, index) => (
                        <li className='mfilter-option' key={index} onClick={() => handleDepartmentSelect(department)}>
                          {department}
                        </li>
                      ))}
                    </ul>
                    // </div>
                  )}
                </div>

                <div className="filter-item" onClick={() => handleFilterItemClick('Followers')}>
                  <div className="mc">


                    <h3>Followers</h3>
                    <img src={mobiledrop} alt="" />
                  </div>
                  {selectedFilter === 'Followers' && (
                    <ul className='mobileul'>
                      {followers.map((follower, index) => (
                        <li className='mfilter-option' key={index} onClick={() => handlefollowersSelect(follower)}>
                          {follower}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="filter-item" onClick={() => handleFilterItemClick('Platform')}>
                  <div className="mc">


                    <h3>Platform</h3>
                    <img src={mobiledrop} alt="" />
                  </div>
                  {selectedFilter === 'Platform' && (
                    <ul className='mobileul'>
                      {platform.map((platform, index) => (
                        <li className='mfilter-option' key={index} onClick={() => handleplatformSelect(platform)}>
                          {platform}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="filter-item" onClick={() => handleFilterItemClick('Location')}>
                  <div className="mc">


                    <h3>Location</h3>
                    <img src={mobiledrop} alt="" />
                  </div>
                  {selectedFilter === 'Location' && (
                    <ul className='mobileul'>
                      {cities?.map((location, index) => (
                        <li className='mfilter-option' key={index} onClick={() => handlelocationSelect(location)}>
                          {location}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                <div className="filter-item" onClick={() => handleFilterItemClick('Sort')}>
                  <div className="mc">


                    <h3>Sort</h3>
                    <img src={mobiledrop} alt="" />
                  </div>
                  {selectedFilter === 'Sort' && (
                    <ul className='mobileul'>
                      <li>Option A</li>
                      <li>Option B</li>
                      <li>Option C</li>
                      {/* Add more sort options */}
                    </ul>
                  )}
                </div>



                <div className="done-black" onClick={handleDoneFilters}>Done</div>

              </div>
            )}

            {showfilters && (
              <div className='overlay'>

              </div>
            )}

          </div>
          <div className="thead">
            <div className="thead-wrap">
              <div className="th1 tname">Creator’s Name</div>
              <div className="th tfollower">Followers</div>
              <div className="th tcreator-type">Creator Type</div>
              <div className="th tcreator-type">Content Type</div>
              <div className="th tcity">City</div>
            </div>
          </div>
          <div div className='scroller'>
          {filteredCreatorData.map((creator, index) => (
            <>
              <div
                className={`creator ${isSelectionMode && selectedCreators.includes(creator) ? 'selected' : ''}`}
                key={index}
                onClick={() => handleCreatorClick(creator)}
              >
                <div className="creator-wrap">
                  {isSelectionMode && (
                    <div className="name-checkbox custom-checkbox">
                      <input
                        type="checkbox"
                        checked={selectedCreators.includes(creator)}
                        onChange={() => handleCheckboxChange(creator)}
                      />
                      <label></label>
                    </div>
                  )}
                  <div className="name">
                    <div className="profilewrapper">
                      <img className='brand-profile' src={creator.profile || avatar} alt="" />
                    </div>
                    <h3>{creator.name}</h3>
                  </div>
                  <div className="follower">{formatNumber(creator.follower)}</div>
                  <div className="creator-type">{creator.creator_type}</div>
                  <div className="content-type">{creator.content_type}</div>
                  <div className="city">{creator.city}</div>
                </div>
                <div className="social">
                  {creator.instagam_link && creator.instagam_link !== 'undefined' && (
                    <a href={creator.instagam_link} target='_blank'><img src={insta} alt="Instagram" /></a>
                  )}

                  {creator.youtube_link && creator.youtube_link !== 'undefined' && (
                    <a href={creator.youtube_link} target='_blank'><img src={yt} alt="YouTube" /></a>
                  )}


                </div>
                <div className="dots" onClick={(e) => handleDotsClick(e, creator)}>
                  <img src={dots} alt="" />
                </div>
              </div>
              {/* //----------------- for mobile ------------------------------------------------------ */}
              <div
                className={`Screator Screator1 ${isSelectionMode1 && selectedCreators.includes(creator) ? 'selected' : ''}`}
                key={index}
                onClick={() => handleCreatorClick(creator)}
              >
                <div className="Screator-wrap Screator-wrap3">
                  {isSelectionMode1 && (
                    <></>
                  )}
                  <div className="Sname">
                    <div className="profileMwrapper">

                      <img className='brand-profile' src={creator.profile || avatar} alt="" />
                    </div>
                    <div className="name-checkbox select-check select-homme">
                      <input
                        type="checkbox"
                        checked={selectedCreators.includes(creator)}
                        onChange={() => handleCheckboxChange(creator)}
                      />
                      <label></label>
                    </div>
                    <div className='Shead'>
                      <h3>{creator.name}</h3>
                      <h4>{creator.creator_type}</h4>
                      <h4>{creator.city}</h4>

                    </div>
                  </div>
                  <div className="fc">
                    <div className="Sfollower">{formatNumber(creator.follower)}</div>
                    <div className="Scontent-type">{creator.content_type}</div>
                  </div>
                  <div className="Ssocial">
                    {creator.instagam_link && creator.instagam_link !== 'undefined' && (
                      <a href={creator.instagam_link} target='_blank'><img src={insta} alt="Instagram" /></a>
                    )}

                    {creator.youtube_link && creator.youtube_link !== 'undefined' && (
                      <a href={creator.youtube_link} target='_blank'><img src={yt} alt="YouTube" /></a>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
          </div>
          {isMenuOpen && (
            <CreatorMenu
              onSelect={handleSelect}
              onAddToCollection={handleAddToCollection}
              style={{ top: menuPosition.top }}
            />
          )}
        </div>
        {showPopup && <AddToCollection onClose={handleClosePopup} selectedCreators={selectedCreators} setIsSelectionMode={handleselectionmode}/>}

        {showContactPopup && <SubmittedpopupBanner onClose={() => setShowContactPopup(false)} handleFormSubmit={handleFormSubmit}   />}
      </div >
    </>
  )
}

export default SigninHomePage;
