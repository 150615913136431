import React, { useState } from 'react';
import "./navbar.css";
import logo from "../../media/ind.svg";
import explore from "../../media/explore.svg";
import coll from "../../media/collection.svg";
import manage from "../../media/manage.svg";
import create from "../../media/create.svg";
import lock from "../../media/lock.svg";
import subtract from "../../media/subtract.svg";
import wsubtract from "../../media/wsubtract.svg";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setShowprivacy , setShowterms} from '../../redux/filterSlice';


const NotSignNavbar = ({ onSignInClick }) => {

    const [showPricacyPopup, setShowPricacyPopup] = useState(false);
const [showTermsPopup, setShowTermsPopup] = useState(true);
    const [imageSource, setImageSource] = useState({
        coll: coll,
        create: create,
        manage: manage,
        subtract: subtract
    });

    const handleImageChange = (imageKey, newImage) => {
        setImageSource((prevImages) => ({
            ...prevImages,
            [imageKey]: newImage,
        }));
    };

    const dispatch = useDispatch();


    const togglePrivacyPopup = () => {
        dispatch(setShowprivacy(true)); // toggle the value
      };
      
      const toggleTermsPopup = () => {
        dispatch(setShowterms(true)); // toggle the value
      };


    return (
        <div className='navbar'>
            <Link to={'/'} className="logo">
                <img src={logo} alt="" />
                B Rich
            </Link>
            <div className="nav-items">
                <Link to={"/"} className="clicked"> <img src={explore} alt="" />Explore</Link>
                <div className="items"
          
                >
                    <img src={lock} alt="" className="lock" />
                    <img
                        src={coll}
                        alt=""
                        className="hoverable-image"

                    />
                Collections
                </div>
                <div className="items"
                
                >
                    <img src={lock} alt="" className="lock" />
                    <img
                        src={create}
                        alt=""
                        className="hoverable-image"

                    />
                    Create Campaign
                </div>
                <div className="items"
                  
                >
                    <img src={lock} alt="" className="lock" />
                    <img
                        src={manage}
                        alt=""
                        className="hoverable-image"

                    />
                   Manage Campaign
                </div>
            </div>
            <div className="unlock-cover">

                <div className="unlock">
                    <div className="up">
                        <img
                            src={imageSource.subtract}
                            alt=""
                            className="hoverable-image"

                        />
                        <div className="easy">
                            <div className="more">Unlock More</div>
                            <div className="login">with easy login</div>

                        </div>


                    </div>
                    <Link to={'/sign-in'} className="sign"
                        onMouseOver={() => handleImageChange('subtract', wsubtract)}
                        onMouseOut={() => handleImageChange('subtract', subtract)}
                    >
                        Sign-in
                    </Link>

                </div>
            </div>
                <Link to={"/terms-and-condition"} className='privacy' style={{marginBottom:"8px"}}>Terms and Conditions</Link>
            <Link to={"/policy"} className='terms' >Refunds & Cancelation Policy</Link>
            <Link to={"/privacy-policy"} style={{bottom:"-16px"}} className='terms privacyPolicy' >Privacy Policy</Link>
            
        </div>
    );
};

export default NotSignNavbar;
