import React, { useState } from 'react'
import "./signup.css"
import security from "../../media/security.png"
import google from "../../media/google.svg"
import { Link } from 'react-router-dom'
import SigninleftNavbar from '../SigninNavbar/SigninleftNavbar'
import pedit from "../../media/pedit.svg"
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../../redux/filterSlice'
import axios from 'axios'; // Import Axios
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'


const SignUp = () => {
    const [signUpMethod, setSignUpMethod] = useState(null);
    const dispatch = useDispatch();
    const [showOTPVerification, setShowOTPVerification] = useState(false); // State to manage showing OTP screen
    const [otpValue, setOtpValue] = useState(''); // State variable for OTP value
    const initialValues = {
        email: '',
        password: '',
        confirmPassword: '',
        company_name: '',
        phone_number: ''
    };
    const [fromdata, setFormData] = useState("")
    console.log("form data", fromdata)
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        company_name: Yup.string().required('Company/Brand Name is required'),
        phone_number: Yup.string().required('Phone Number is required')
    });


    const handleGoogleSignIn = () => {
        setSignUpMethod('Google');
        setShowOTPVerification(true);
    };

    const handleSignUp = async (values) => {
        try {
            const { confirmPassword, ...formDataWithoutConfirmPassword } = values;
            setFormData(formDataWithoutConfirmPassword)
            const response = await axios.post('https://brich.youthevents.in/Bdashboard/register/', formDataWithoutConfirmPassword);
            console.log('Response from server:', response.data);

            setSignUpMethod('OTP');
        } catch (error) {
            console.error('Error while sending data:', error);
        }
    };

    const [registered, setRegistered] = useState(false); // State to manage registration status


    const handleSignIn = async () => {
        try {
            const response = await axios.post('https://brich.youthevents.in/Bdashboard/verify_otp/', {
                data: fromdata,
                otp: otpValue
            });

            console.log('Response from server:', response.data);

            // Check if the OTP verification is successful
            if (response.status === 201) {
                dispatch(setUserAuthenticated(true));
                setRegistered(true); 
                

            } else {

            }
        } catch (error) {
            console.error('Error while verifying OTP:', error);
            // Handle errors if the request fails
        }
    };


    let signUpComponent;
    switch (signUpMethod) {
        case 'OTP':
            signUpComponent = (
                <div className="signin">
                    <div className="signin-head" style={{ marginTop: "80px" }}>
                        <h3>Verification</h3>
                        <p>To Keep You Secured.</p>
                    </div>
                    <div className="signbox forget2 cflex">
                        <h3>OTP Required</h3>
                        <p>an OTP has been sent to your Email please check your inbox</p>
                        <div className="user-pass cflex" style={{ marginBottom: "12px" }}>
                            <div className="feild2">
                                <input type="text" className='number' placeholder='9948441239' />
                                <img src={pedit} alt="" />
                            </div>
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otpValue}
                                onChange={(e) => setOtpValue(e.target.value)}
                            />
                        </div>
                        <div className="gsign cflex">
                            <div to={'/'} className="google" onClick={handleSignIn}>{registered ? 'Registered' : 'Proceed'}</div>
                        </div>
                        <div className="resend">resend OTP</div>
                    </div>
                    <Link to={'/sign-in'} className="already" >
                        <h3>Already Have an Account? Sign In</h3>
                    </Link>

                </div>
            );
            break;

        case 'Google':
            signUpComponent = (
                <div className="signin">
                    <div className="signin-head" style={{ marginTop: "80px" }}>
                        <h3>Basic Info</h3>
                        <p>Enter Details Below to Continue.</p>
                    </div>
                    <div className="signbox signupbox cflex">
                        <div className="user-pass cflex" style={{ marginBottom: "28px" }}>
                            <input type="text" placeholder='Company / Brands Name' />
                            <input type="text" placeholder='Phone No.' />

                        </div>

                        <div className="gsign cflex">
                            <Link to={'/'} className="google" onClick={handleSignUp}>Proceed</Link>
                        </div>
                    </div>
                    <Link to={'/sign-in'} className="already" >
                        <h3>Already Have an Account? Sign In</h3>
                    </Link>

                </div>
            );
            // }
            break;

        default:
            signUpComponent = (
                <>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            handleSignUp(values);
                            resetForm(); // Reset the form after submission
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className="signin">
                                <div className="signin-head">
                                    <h3>Sign Up</h3>
                                    <p>Enter Your Details Below to Register.</p>
                                </div>
                                <div className="signbox signupbox cflex">
                                    <div className="user-pass cflex" style={{ marginBottom: "28px" }}>
                                        <Field type="text" name="email" placeholder="Email" />
                                        <ErrorMessage name="email" component="div" className="error-message" />
                                        <Field type="password" name="password" placeholder="Password" />
                                        <ErrorMessage name="password" component="div" className="error-message" />
                                        <Field type="password" name="confirmPassword" placeholder="Confirm Password" />
                                        <ErrorMessage name="confirmPassword" component="div" className="error-message" />
                                        <Field type="text" name="company_name" placeholder="Company / Brands Name" />
                                        <ErrorMessage name="company_name" component="div" className="error-message" />
                                        <Field type="text" name="phone_number" placeholder="Phone No." />
                                        <ErrorMessage name="phone_number" component="div" className="error-message" />
                                    </div>
                                    <div className="gsign cflex">
                                        <button type='submit' className="google" style={{ border: "none" }}>Sign-In</button>
                                        <div className="google" onClick={handleGoogleSignIn}><img src={google} alt="" /> Continue with Google</div>
                                    </div>
                                </div>
                                <Link to={'/sign-in'} className="already" >
                                    <h3>Already Have an Account? Sign In</h3>
                                </Link>
                            </Form>
                        )}
                    </Formik>
                </>
            );
            break;
    }

    return (
        <div className="general-right">

            <div className="right flex">
                {signUpComponent}
                <div className="drawing cflex">
                    <div className="draw-head">
                        {/* <h3>LOREM IPSUM</h3> */}
                        <p>Secure and easy access to your account. Manage your campaigns and track your progress with ease.</p>
                    </div>
                    <img src={security} alt="" />
                </div>

            </div>
        </div>
    )
}

export default SignUp