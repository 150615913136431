import React, { useState } from 'react';
import "./create.css"
import SmartCampaign from './Campaign types/SmartCampaign';
import CustomCampaign from './Campaign types/CustomCampaign';
import smart from "../../media/smart.svg"
import custom from "../../media/custom.svg"

const CreateCampaign = ({setActiveComponent , setActiveItem}) => {
  const [campaignType, setCampaignType] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [ctype, setCtype] = useState("");

  const isNextDisabled = ctype === ''


const handleToggle = (type) => {
setCtype(type)
}
  const handleCampaignTypeSelection = () => {

    setCampaignType(ctype);
  };

  const handleNextStep = (step) => {
    if(step){
      setCurrentStep(step);
    }
    setCurrentStep(currentStep + 1);
  };

  console.log("current step",currentStep)

  const handlePreviousStep = () => {
    if (currentStep === 3) {
      setCurrentStep(1);
    }  if (currentStep === 9) {
      setCurrentStep(6);
    } else {
      // Otherwise, decrement the current step by 1
      setCurrentStep(currentStep - 1);
    }
  };

  const renderCampaignSteps = () => {
    if (campaignType === 'smart') {
      return <SmartCampaign step={currentStep} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} setActiveComponent={setActiveComponent}  setActiveItem={setActiveItem} />;
    } else if (campaignType === 'custom') {
      return <CustomCampaign step={currentStep} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} setActiveComponent={setActiveComponent}  setActiveItem={setActiveItem}/>;
    } else {
      
      return (
        <div className='general-right'>

          <div className="right cflex leftin">
            <div className="campaign-head create-h">
              <h3>CREATE CAMPAIGN</h3>
              <p>Explore the best set of tools and create a campaign for promotional strategies and marketing of your brand.</p>
            </div>
            <div className="ctypes">
              <div className="next" style={{ backgroundColor: isNextDisabled ? 'grey' : '' }} onClick={isNextDisabled ? null : handleCampaignTypeSelection} disabled={!isNextDisabled} >
                Next
              </div>
              <div className="ctabs">
                <div className="smart">
                <input
                    type="radio"
                    id="smartCampaign"
                    name="campaignType"
                    value="smart"
                    onChange={() => handleToggle('smart')}
                  />

                  <img src={smart} alt="" />
                  <div className="shead">
                    <h3>Smart Campaign</h3>
                  </div>
                  <p>Auto generated campaign</p>
                </div>
                <div className="custom">
                <input
                    type="radio"
                    id="customCampaign"
                    name="campaignType"
                    value="custom"
                    onChange={() => handleToggle('custom')}
                  />
                  <img src={custom} alt="" />
                  <div className="chard">
                    <h3>Custom Campaign</h3>
                  </div>
                  <p>Make your own campaign </p>
                </div>
              </div>
            </div>
            {/* <button onClick={() => handleCampaignTypeSelection('smart')}>Smart Campaign</button>
            <button onClick={() => handleCampaignTypeSelection('custom')}>Custom Campaign</button> */}
          </div>
        </div>
      );
    }
  };

  return (
    <div className='general-right campign-blob'>

      <div className="right">
        {renderCampaignSteps()}
      </div>
    </div>
  );
};

export default CreateCampaign;
