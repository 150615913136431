import React, { useState } from 'react'
import "./notsignin.css"
import arrow from "../../media/arrow.svg"
import phone from "../../media/phone.svg"
import sort from "../../media/sort.svg"
import down from "../../media/down.svg"
import avatar from "../../media/Avatar.png"
import locks from "../../media/locks.svg"
import insta from "../../media/insta.svg"
import yt from "../../media/yt.svg"
import dots from "../../media/dots.svg"
import SubmittedpopupBanner from '../Signin Home page/Signin components/SubmittedpopupBaner'


const creators = [
  {
    "name": "Darcy Jay",
    "follower": "90K",
    "creator-type": "Fitness Creator",
    "content-type": "Reels, Posts, Story",
    "city": "Mumbai"
  },
  {
    "name": "John Smith",
    "follower": "20K",
    "creator-type": "Lifestyle Blogger",
    "content-type": "Posts, Vlogs",
    "city": "New York"
  },
  {
    "name": "Alice Brown",
    "follower": "75K",
    "creator-type": "Foodie",
    "content-type": "Recipes, Food Photography",
    "city": "San Francisco"
  },
  {
    "name": "Michael Johnson",
    "follower": "50K",
    "creator-type": "Travel Enthusiast",
    "content-type": "Travel Vlogs, Photos",
    "city": "Los Angeles"
  },
  {
    "name": "Emma White",
    "follower": "80K",
    "creator-type": "Fashion Blogger",
    "content-type": "Outfit Posts, Styling Tips",
    "city": "London"
  },
  {
    "name": "Samuel Green",
    "follower": "10K",
    "creator-type": "Tech Reviewer",
    "content-type": "Gadget Reviews, Tutorials",
    "city": "San Jose"
  },
  {
    "name": "Olivia Davis",
    "follower": "70K",
    "creator-type": "DIY Crafts",
    "content-type": "Craft Tutorials, Handmade Creations",
    "city": "Toronto"
  }
]


const NotSignin = () => {
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);


  const handleRequestCallback = () => {
    setShowContactPopup(true);
  };

  const handleFormSubmit = () => {
    // You can add form validation and submission logic here.
    // For now, just set the showThankYouPopup to true.
    setShowThankYouPopup(true);
  };

  return (
    
    <div className='general-right'>
      <div className="right">

      {showContactPopup && <SubmittedpopupBanner onClose={() => setShowContactPopup(false)} handleFormSubmit={handleFormSubmit}   />}

        <div className="welcome" style={{ marginTop: "56px" }}>
          <div className="head">
            <h3>WELCOME 🥳</h3>
            <p>Welcome to your Brand Dashboard, where you will get to explore our exclusive range of creators and many more tools!</p>

          </div>
          <div className="request" onClick={handleRequestCallback}>
            Request a Callback
            <div className="images">
              <img src={phone} alt="" />
              <img className='arrow' src={arrow} alt="" />

            </div>

          </div>
        </div>
        <div className="filter-list">
          <div className="list-head">
            <h3>Creators List</h3>
            <p>289 Creators</p>
          </div>
          <div className="filters">
            <div className="filter">Categories <img src={down} alt="" /></div>
            <div className="filter">Followers <img src={down} alt="" /></div>
            <div className="filter">Platform <img src={down} alt="" /></div>
            <div className="filter">Location <img src={down} alt="" /></div>
            <div className="sort"><img src={sort} alt="" /></div>
          </div>

        </div>
        <div className="thead">
          <div className="thead-wrap">
            <div className="th1 tname">Creator’s Name</div>
            <div className="th tfollower">Followers</div>
            <div className="th tcreator-type">Creator Type</div>
            <div className="th tcreator-type">Content Type</div>
            <div className="th tcity">City</div>

          </div>
        </div>
        {creators.map((creator, index) => (
          <>
            <div className="creator" key={index}>
              <div className="creator-wrap">
                <div className="name">
                <div className="profilewrapper">

                  <img className='brand-profile' src={avatar} alt="" />
                  </div>

                  <h3>{creator.name}</h3>
                </div>
                <div className="follower">
                  {creator.follower}
                </div>
                <div className="creator-type">
                  {creator['creator-type']}
                </div>
                <div className="content-type">
                  {creator['content-type']}
                </div>
                <div className="city">
                  {creator.city}
                </div>
              </div>
              <div className="social">
                <img src={insta} alt="" />
                <img src={yt} alt="" />

              </div>
              <div className="dots">
                <img src={dots} alt="" />
              </div>
            </div>
            <div
              className={`Screator Screator1`}
              key={index}
            >
              <div className="Screator-wrap Screator-wrap1">
               
                <div className="Sname">
                <div className="profileMwrapper">
                  <img src={avatar} alt="" />
                  </div>

                  <div className='Shead'>
                    <h3>{creator.name}</h3>
                    <h4>{creator['creator-type']}</h4>
                    <h4>{creator.city}</h4>

                  </div>
                </div>
                <div className="fc">
                  <div className="Sfollower">{creator.follower}</div>
                  <div className="Scontent-type">{creator['content-type']}</div>
                </div>
                <div className="Ssocial">
                  <img src={insta} alt="" />
                  <img src={yt} alt="" />
                </div>
              </div>

            </div>
          </>
        ))}

        <div className="blur">
          <img src={locks} alt="" />
          <h3>SIGN-IN  TO EXPLORE ALL CREATORS</h3>
        </div>
      </div>
    </div>
  )
}

export default NotSignin
