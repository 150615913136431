import { React, useEffect, useState } from 'react'
import "./manage.css"
import DetailedCampaignView from './campaign view/DetailCampaignView';
import axios from "axios"

const ManageCampaign = ({setActiveComponent , setActiveItem}) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleViewClick = (campaign) => {
    setSelectedCampaign(campaign);
  };

  const getColorByStatus = (status) => {
    switch (status) {
      case "In Progress":
      case "Successful":
        return "#176F02"; // Green color for In Progress and Successful
      case "Requested Callback":
        return "#B88D00"; // Yellow color for Request Callback
      case "Cancelled":
        return "#D82100"; // Red color for Cancelled
      default:
        return "black"; // Default color
    }
  };
  const handleBackToList = () => {
    setSelectedCampaign(null); // Clear the selected campaign to go back to the list view
  };

  const [userCampaigns, setUserCampaigns] = useState([]);

  useEffect(() => {
    // Fetch user campaigns when the component mounts
    fetchUserCampaigns();
  }, []);
  const userProfile = JSON.parse(localStorage.getItem('user'));


  const fetchUserCampaigns = async () => {
    try {
      // Make a GET request to the Django backend API endpoint
      const response = await axios.get('https://brich.youthevents.in/Bdashboard/get_user_campaigns/', {
        headers: {
          'user': userProfile.id,
        }
      });

      // Set the fetched campaigns to the state
      setUserCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching user campaigns:', error);
      // Handle error state or show error message to the user
    }
  };
  console.log("user campaign is ", userCampaigns)

  return (
    <div className="general-right">
      {selectedCampaign ? (
        <div className="right flex">
          <DetailedCampaignView campaign={selectedCampaign} goBackToList={handleBackToList} />
        </div>

      ) : (
        <>
          <div className="right cflex" style={{justifyContent:"flex-start"}}>

            <div className="campaign-head">
              <h3>MANAGE CAMPAIGN</h3>
              <p>Evaluate the effectiveness of your campaign</p>
            </div>
            {userCampaigns.length === 0 ? (
              <>
                <div className="no-camp">
                  <div className="no-head">
                    <h3>NO CAMPAIGN FOUND</h3>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                  </div>
                  <div className="start"onClick={() => { setActiveComponent('createCampaign'); setActiveItem('createCampaign'); }}>Start a Campaign</div>
                </div>
              </>
            ) : (
              <>
                <div className="campaigns">
                  <h3>Your Campaigns</h3>
                  <p>Campaign History & Status likh de</p>
                  <div className="camp-title">
                    <div className="ctitles1">
                      <div className="tits">Campaign Name</div>
                      <div className="tits">Campaign Type</div>
                      <div className="tits">Status</div>
                    </div>
                  </div>
                  <div className="camp-cover">
                    {userCampaigns.map((campaign, index) => (
                      <div className="camps" key={index}>
                        <div className="camp-name">{campaign.campaign_name}</div>
                        <div className="camp-type">{campaign.campaign_type}</div>
                        <div className="camp-status" style={{ color: getColorByStatus(campaign.Campaign_status) }}>{campaign.Campaign_status}</div>
                        <div
                          className={`nexts ${campaign.Campaign_status == 'Pending'|| campaign.Campaign_status == "Requested Callback" ? 'disabled' : ''}`}
                          onClick={campaign.Campaign_status !== 'Pending' && campaign.Campaign_status !== "Requested Callback" ? () => handleViewClick(campaign) : undefined}
                          disabled={campaign.Campaign_status === 'Pending' || campaign.Campaign_status === "Requested Callback"}
                        >View</div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};


export default ManageCampaign
