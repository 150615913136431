// CreatorMenu.js
import React from 'react';
import dust from "../../../media/dust.svg"
import add from "../../../media/add.svg"
import select from "../../../media/select.svg"

const CreatorMenu = ({ onSelect, onAddToCollection, style }) => {
  return (
    <div className="creator-menu" style={style}>
      <div className="menu-option" onClick={onAddToCollection}>
        Add to Collection
        <img src={add} alt="" />
      </div>

      <div className="menu-option" onClick={onSelect}>
        Select
        <img src={select} alt="" />
      </div>
    </div>
  );
};

export default CreatorMenu;
