import React, { useState } from "react";
import SearchIcon from "../../../media/search.svg";
import { Link } from "react-router-dom";
// import CloseIcon from "@material-ui/icons/Close";
import avatar from "../../../media/place.jpg"
import { useNavigate } from "react-router-dom";

function SearchBar({ placeholder, data }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const navigate = useNavigate();


  console.log(data)

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const formatNumber = (num) => {
    const numValue = parseInt(num);
    if (numValue >= 1000000) {
      return (parseFloat((numValue / 1000000).toFixed(1)) + 'M').replace('.0', '');
    }
    if (numValue >= 1000) {
      return (parseFloat((numValue / 1000).toFixed(1)) + 'K').replace('.0', '');
    }
    return num;
  };

  const handleSearch = () => {
    if (wordEntered) {
      navigate(`/creator-search-details/${wordEntered}`);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search">
      <div className="searchInputs">
        <input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
          onKeyDown={handleKeyDown}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <img src={SearchIcon} alt="" />
          ) : (
            // <CloseIcon id="clearBtn" onClick={clearInput} />
            <>
              <img src={SearchIcon} alt="" onClick={handleSearch} />
            </>
          )}
        </div>
      </div>
      {filteredData.length != 0 && (
        <div className="dataResult">
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <Link to={`/creator-details/${value.id}`} className="dataItem" key={key}>
                <div className="sname">
                  <div className="searchwrapper">

                    <img src={value.profile ? value.profile : avatar} alt="" />
                  </div>
                  <div className="cat">
                    <div className="sname">{value.name}</div>
                    <div className="scat">{value.creator_type}</div>


                  </div>

                </div>
                <div className="sfollower">
                  {formatNumber(value.follower)}
                </div>

              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
