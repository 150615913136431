import React, { useState, useEffect } from 'react';
import line3 from "../../media/line3.svg"
import logo from "../../media/logo.svg"
import explore from "../../media/explore.svg";
import coll from "../../media/collection.svg";
import manage from "../../media/manage.svg";
import create from "../../media/create.svg";
import wcoll from "../../media/wcollection.svg";
import wmanage from "../../media/wmanage.svg";
import wcreate from "../../media/wcreate.svg";
import subtract from "../../media/subtract.svg";
import wsubtract from "../../media/wsubtract.svg";
import { Link } from 'react-router-dom';
import lock from "../../media/lock.svg";

const NotSigninleftNavbar = ({ setActiveComponent }) => {
    const [isRightNavbarOpen, setIsRightNavbarOpen] = useState(false);

    const toggleRightNavbar = () => {
        setIsRightNavbarOpen(!isRightNavbarOpen);
    };
    const [imageSource, setImageSource] = useState({
        coll: coll,
        create: create,
        manage: manage,
        subtract: subtract
    });

    const [activeItem, setActiveItem] = useState('explore');

    const handleImageChange = (imageKey, newImage) => {
        setImageSource((prevImages) => ({
            ...prevImages,
            [imageKey]: newImage,
        }));
    };

    return (
        <div className='leftnav'>
            <img src={line3} alt="" className='line3' onClick={toggleRightNavbar} />
            <Link to={'/'} className='logos'>
                <img src={logo} alt="" />
                B RICH
            </Link>

            {/* Right-side navbar */}
            <div className={`right-navbar ${isRightNavbarOpen ? 'open' : ''}`}>
                <div className="nav-item">
                    <div className="nav-items">
                        <Link to={"/"} className="clicked"> <img src={explore} alt="" />Explore</Link>
                        <div className="items"

                        >
                            <img src={lock} alt="" className="lock" />
                            <img
                                src={coll}
                                alt=""
                                className="hoverable-image"

                            />
                            Collections
                        </div>
                        <div className="items"

                        >
                            <img src={lock} alt="" className="lock" />
                            <img
                                src={create}
                                alt=""
                                className="hoverable-image"

                            />
                            Create Campaign
                        </div>
                        <div className="items"

                        >
                            <img src={lock} alt="" className="lock" />
                            <img
                                src={manage}
                                alt=""
                                className="hoverable-image"

                            />
                            Manage Campaign
                        </div>
                    </div>
                    <div className="unlock-cover">

                        <div className="unlock">
                            <div className="up">
                                <img
                                    src={imageSource.subtract}
                                    alt=""
                                    className="hoverable-image"

                                />
                                <div className="easy">
                                    <div className="more">Unlock More</div>
                                    <div className="login">with easy login</div>

                                </div>
                            </div>
                            <Link to={'/sign-in'} className="sign"
                                onMouseOver={() => handleImageChange('subtract', wsubtract)}
                                onMouseOut={() => handleImageChange('subtract', subtract)}
                            >
                                Sign-in
                            </Link>

                        </div>
                    </div>

                </div>
            </div>
            <Link to={"/terms-and-condition"} className='privacy' style={{marginBottom:"8px"}}>Terms and Conditions</Link>
            <Link to={"/policy"} className='terms' >Refunds & Cancelation Policy</Link>
            <Link to={"/privacy-policy"} style={{bottom:"-16px"}} className='terms privacyPolicy' >Privacy Policy</Link>

        </div>
    )
}

export default NotSigninleftNavbar
