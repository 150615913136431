import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className='general-right'>
            <div className="right">
                <h3 className='pricavy-head'>Privacy Policy</h3>
                <div className="policy-box">
                    <div className="privacy-wrap">

                        <div className="privacies" >
                            <h3>Information We Collect</h3>
                            <p><br />
                                Personal Information: When you sign up or use our services, we may collect personal information such as your name, email address, phone number, and payment information.
                                <br />
                                Google User Data: If you choose to link your Google account to our platform, we access certain information from your Google account in accordance with your permission. This may include your email address, profile information, and any other data you've consented to share.
                                <br />
                                <br />
                            </p>
                        </div>
                        <div className="privacies">
              <h3>How We Use Your Information</h3>
              <p>
                We use the information we collect for various purposes, including:
                <ul>
                  <li>To provide, operate, and maintain our platform</li>
                  <li>To improve, personalize, and expand our services</li>
                  <li>To understand and analyze how you use our platform</li>
                  <li>To develop new products, services, features, and functionality</li>
                  <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the platform, and for marketing and promotional purposes</li>
                  <li>To process your transactions and manage your orders</li>
                  <li>To find and prevent fraud</li>
                  <li>To comply with legal obligations</li>
                </ul>
              </p>
            </div>
            
            <div className="privacies">
              <h3>Sharing Your Information</h3>
              <p>
                We do not sell your personal information to third parties. We may share your information in the following situations:
                <ul>
                  <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.</li>
                  <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law or if we believe such action is necessary to comply with a legal obligation, protect and defend our rights or property, prevent fraud, or protect against legal liability.</li>
                  <li><strong>With Your Consent:</strong> We may share your information with third parties if you have given us your consent to do so.</li>
                </ul>
              </p>
            </div>
                        <div className="privacies">
                            <h3>Data Storage and Security</h3>
                            <p><br />
                                We use industry-standard security measures to protect your data. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee its absolute security.

                                <br />

                            </p>
                        </div>
                        <div className="privacies">
                            <h3>Your Privacy Rights</h3>
                            <p><br />
                                Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your personal data. To exercise these rights, please contact us at the contact information provided below.

                                <br /></p>
                        </div>
                        <div className="privacies">
                            <h3>Third-Party Links</h3>
                            <p><br />
                                Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices of these third-party sites.
                                <br /></p>
                        </div>
                        <div className="privacies">
                            <h3>Changes to This Privacy Policy</h3>
                            <p><br />
                                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.

                                <br /></p>
                        </div>
                        <div className="privacies">
                            <h3>Contact Us</h3>

                            <p>If you have any questions about this Privacy Policy, please contact us at:<br />
                                B Rich<br />
                                Email: info@brich.in<br />

                            </p>
                        </div>

                    </div>
                </div>


            </div>
        </div>

    )
}

export default PrivacyPolicy
