import './App.css';
import NotSignNavbar from './Components/Navbar/Navbar';
import NotSignin from './Components/Not Signin/NotSignin';
import React, { useEffect, useState } from 'react';
import SigninNavbar from './Components/SigninNavbar/SigninNavbar';
import SigninHomePage from './Components/Signin Home page/SigninHomePage';
import Collections from './Components/Collections/Collections';
import CreateCampaign from './Components/Create Campaign/CreateCampaign';
import ManageCampaign from './Components/Manage Campaign/ManageCampaign';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Settings from './Components/User Settings/Settings';
import Signin from './Components/Sign in flow/Signin';
import Signup from './Components/Sign in flow/Signup';
import ForgetPassword from './Components/Sign in flow/ForgetPassword';
import { useSelector } from 'react-redux';
import SearchResult from './Components/Search Result/SearchResult';
import { setUserAuthenticated, setUserProfile  ,setShowprivacy , setShowterms} from './redux/filterSlice';
import { useDispatch } from 'react-redux';
import SigninleftNavbar from './Components/SigninNavbar/SigninleftNavbar';
import CollectionDetails from './Components/Collections/Collection components/CollectionDetail';
import cross from "./media/cross.svg";
import Privacy from './Components/policies/Privacy';
import Terms from './Components/policies/Terms';
import NotSigninleftNavbar from './Components/Navbar/SigninleftNavbar';
import { gapi } from 'gapi-script';
import SuccessPage from './Components/Create Campaign/Campaign types/SuccessPage';
import SeachAllResults from './Components/Search Result/SeachAllResults';
import PrivacyPolicy from './Components/policies/PrivacyPolicy';




function App() {
  const dispatch = useDispatch(); // Getting the dispatch function from react-redux

  const isSignedIn = useSelector((state) => state.filters.userAuthenticated);
  const [activeComponent, setActiveComponent] = useState('explore');
  const [activeItem, setActiveItem] = useState('explore');
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const handlesetActiveItem =(item) =>{
    setActiveItem(item)
  }


  const showPrivacy = useSelector((state) => state.filters.showPrivacy);
  const showTerms = useSelector((state) => state.filters.showTerms);

  console.log("value of terms and privacy",showPrivacy,showTerms)


  const togglePrivacyPopup = () => {
    dispatch(setShowprivacy(false));
  };
  
  const toggleTermsPopup = () => {
    dispatch(setShowterms(false)); 
  };



  useEffect(() => {
    // Check if user is already authenticated (e.g., with a token in localStorage)
    const token = localStorage.getItem('token');
    if (token) {
      setIsUserAuthenticated(true);
    }
  }, []);


  useEffect(() => {
    // Check if user is already authenticated (e.g., with a token in localStorage)
    const userProfile = JSON.parse(localStorage.getItem('user'));


    console.log("user profile", userProfile)

    // Dispatch actions to set retrieved data into Redux store
    if (isUserAuthenticated === 'true' && userProfile) {
      dispatch(setUserAuthenticated(true));
      dispatch(setUserProfile(userProfile));
    }
  }, []);

  const clientId = '956050916143-41t5n2khuru0rup7mq0l6ojfjjfu9ohn.apps.googleusercontent.com'
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    }
    gapi.load("client:auth2", start)
  }, [])

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'explore':
        return <SigninHomePage />;
      case 'collections':
        return <Collections setActiveComponent={setActiveComponent} setActiveItem={handlesetActiveItem}/>;
      case 'createCampaign':
        return <CreateCampaign setActiveComponent={setActiveComponent}  setActiveItem={handlesetActiveItem}/>;
      case 'manageCampaign':
        return <ManageCampaign setActiveComponent={setActiveComponent}  setActiveItem={handlesetActiveItem}/>;
      case 'settings':
        return <Settings />;
      default:
        return <SigninHomePage />;
    }
  };



  return (
    <BrowserRouter>
      <div className="App">
        {showPrivacy && <Privacy onClose={togglePrivacyPopup} />}
        {showTerms && <Terms onClose={toggleTermsPopup} />}

        <Routes>
          <Route
            path="/"
            element={
              isUserAuthenticated ? (
                <>
                  <SigninNavbar setActiveComponent={setActiveComponent} activeItem={activeItem}  setActiveItem={handlesetActiveItem} />
                  <SigninleftNavbar setActiveComponent={setActiveComponent}  activeItem={activeItem}  setActiveItem={handlesetActiveItem} />
                  {renderActiveComponent()}
                </>
              ) : (
                <>
                  <NotSignNavbar />
                  <NotSigninleftNavbar/>
                  <NotSignin />
                </>
              )
            }
          />

          <Route path="/user-settings"
            element={[
              <SigninNavbar />,
              <Settings />
            ]} />

          <Route path="/sign-in"
            element={
              <>
              <NotSignNavbar />
              <NotSigninleftNavbar/>
              <Signin />
            </>
             
            } />
          <Route path="/policy"
            element={
              <>
              <NotSignNavbar />
              <NotSigninleftNavbar/>
              <Privacy />
            </>
             
            } />
          <Route path="/privacy-policy"
            element={
              <>
              <NotSignNavbar />
              <NotSigninleftNavbar/>
              <PrivacyPolicy />
            </>
             
            } />
          <Route path="/terms-and-condition"
            element={
              <>
              <NotSignNavbar />
              <NotSigninleftNavbar/>
              <Terms />
            </>
             
            } />

          <Route path="/sign-up"
            element={<>
              <NotSignNavbar />
              <NotSigninleftNavbar/>
              <Signup />
            </>
             
            } />

          <Route path="/forget-password"
            element={<>
              <NotSignNavbar />
              <NotSigninleftNavbar/>
              <ForgetPassword />
            </>
             
            } />

          <Route path="/creator-details/:id"
            element={[
              <SigninNavbar setActiveComponent={setActiveComponent} activeItem={activeItem}  setActiveItem={handlesetActiveItem} />,
              <SearchResult />
            ]} />

          <Route path="/creator-search-details/:name"
            element={[
              <SigninNavbar setActiveComponent={setActiveComponent} activeItem={activeItem}  setActiveItem={handlesetActiveItem} />,
              <SeachAllResults />
            ]} />

          <Route path="/collection-details/:id"
            element={[
              <SigninNavbar setActiveComponent={setActiveComponent} activeItem={activeItem}  setActiveItem={handlesetActiveItem} />,
              <CollectionDetails />
            ]} />

          <Route path="/success"
            element={[
              <SigninNavbar setActiveComponent={setActiveComponent} activeItem={activeItem}  setActiveItem={handlesetActiveItem} />,
              <SuccessPage />
            ]} />



        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
