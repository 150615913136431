import React, { useState } from "react";
import PropTypes from "prop-types";
import left from "../../../media/lft.svg"
import right from "../../../media/rgt.svg"
import "../create.css"

// const propTypes = {
//   images: PropTypes.arrayOf(
//     PropTypes.shape({
//       img: PropTypes.string
//     })
//   ).isRequired
// };

const ImageSlider = (props) => {
  console.log("images in slider", props)
  const { images } = props;
  const [current, setCurrent] = useState(0);
  const length = images.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <section className="slider">
      {length > 1 && ( // Conditionally render left arrow
        <img
          src={left}
          className="left-arrow"
          onClick={prevSlide}
          alt="Previous"
        />
      )}
      {images.map((image, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
              <img src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt="img" className="image" />
            )}
          </div>
        );
      })}
      {length > 1 && ( // Conditionally render right arrow
        <img
          src={right}
          className="right-arrow"
          onClick={nextSlide}
          alt="Next"
        />
      )}
    </section>
  );
};

// ImageSlider.propTypes = propTypes;

export default React.memo(ImageSlider);
