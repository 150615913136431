import React, { useEffect } from 'react'
import "./collection.css"
import plus from "../../media/plus.svg"
import user from "../../media/user.svg"
import delet from "../../media/delete.svg"
import edit from "../../media/edit.svg"
import adds from "../../media/adds.svg"
import { useState } from 'react'
import CreateCollection from './Collection components/CreateCollection'
import axios from 'axios'
import EditCollection from './Collection components/EditCollection'
import { Link } from 'react-router-dom';
import cross from "../../media/cross.svg";
import comma from "../../media/comma.svg";
import rcomma from "../../media/rcomma.svg";
import CustomCampaign from '../Create Campaign/Campaign types/CustomCampaign'
import EditCreators from './Collection components/EditCreators'



const Collections = ({ setActiveComponent, setActiveItem }) => {

  const [collections, setCollections] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleCreateCollectionClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get('https://brich.youthevents.in/Bdashboard/api/collections/', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            user: ` ${JSON.parse(localStorage.getItem('user')).email}`
          }
        });
        setCollections(response.data);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    fetchCollections();
  }, []);

  const updateCollections = async () => {
    try {
      const response = await axios.get('https://brich.youthevents.in/Bdashboard/api/collections/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          user: ` ${JSON.parse(localStorage.getItem('user')).email}`
        }
      });
      setCollections(response.data);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };
  const handleDeleteCollection = async (collectionId) => {
    try {
      await axios.delete(`https://brich.youthevents.in/Bdashboard/api/collections/${collectionId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          user: `${JSON.parse(localStorage.getItem('user')).email}`
        }
      });
      // After successful deletion, update the collections state
      updateCollections();
    } catch (error) {
      console.error('Error deleting collection:', error);
    }
  };
  console.log("collection is", collections)

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showcreatorEditpopup, setShowCreatorEditPopup] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  // Function to handle opening the edit popup
  const handleEditCollection = (collection) => {
    setSelectedCollection(collection);
    setShowEditPopup(true);
  };

  // Function to close the edit popup
  const handleCloseEditPopup = () => {
    setSelectedCollection(null);
    setShowEditPopup(false);
  };

  const handleEditCreators = (collection) => {
    setSelectedCollection(collection);
    setShowCreatorEditPopup(true);
  };
  const handleCloseEditCreators = () => {
    setSelectedCollection(null);
    setShowCreatorEditPopup(false);
  };

  const sendQuoteRequest = async (collectionId) => {
    try {
      const response = await axios.post(`https://brich.youthevents.in/Bdashboard/api/collections/${collectionId}/request-quote/`, {
        // Optionally, you can send additional data in the request body if needed
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Handle success - show a message or perform other actions if needed
      console.log('Quote request sent:', response.data.message);
      setShowSuccessPopup(true)
    } catch (error) {
      console.error('Error sending quote request:', error);
      // Handle errors - display an error message or perform other actions
    }
  };
  const [showCampaign, setShowCampaign] = useState({ show: false, campaignId: null });

  const handleStartCampaignClick = (campaignId) => {
    setShowCampaign({ show: true, campaignId });
  };

  const handleBackToMainContent = () => {
    setShowCampaign({ show: false, campaignId: null });
  };

  const [currentStep, setCurrentStep] = useState(2);
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };



  return (
    <div className='general-right'>

      <div className="right">
        {showCampaign.show ? (
          <CustomCampaign
            campaignId={showCampaign.campaignId}
            onBack={handleBackToMainContent}
            step={currentStep}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            setActiveComponent={setActiveComponent}
            setActiveItem={setActiveItem}

          />
        ) : (
          <>
            <div className="collection">
              <div className="c-head">

                <h3>COLLECTION</h3>
                <p>Create a collection of your desired creators and build a campaign for your Brand. </p>
              </div>
              <div className="cbutton" onClick={handleCreateCollectionClick}>
                <img src={plus} alt="" />
                Create a Collection
              </div>
            </div>
            <div className="ctitle">
              <div className="ctitles">

                <div className="Cname">Collection Name</div>
                <div className="Ccreators">Creators</div>
                <div className="Ccategory">Categories</div>
                <div className="Caction">Actions</div>
              </div>
            </div>
            <div className="citems">
              {collections && collections.length === 0 ? (
                <>
                  <div className="not">
                    <h3>You don’t have any collections</h3>
                    <div className="cbutton1" onClick={handleCreateCollectionClick}>
                      <img src={plus} alt="" />
                      Create a Collection
                    </div>

                  </div>
                </>
              ) : (
                <>
                  {collections?.map((collection, index) => {
                    const allCategories = collection.creators?.map((creator) => creator.creator_type);
                    const sortedCategories = [...new Set(allCategories)].sort((a, b) => b.length - a.length);
                    const longCategory = sortedCategories[0];
                    const shortCategory = sortedCategories[sortedCategories.length - 1];
                    const uniqueCategories = sortedCategories.filter(
                      (category) => category !== longCategory && category !== shortCategory
                    );

                    return (
                      <div className='coll' key={index}>
                        <div className="colls">
                          <Link to={`/collection-details/${collection.id}`} className="CLname">{collection.collection_name}</Link>
                          <Link to={`/collection-details/${collection.id}`} className="CLimage">
                            <div className="users">
                              {collection.creators?.length || 0}
                              <img src={user} alt="" />
                            </div>
                          </Link>
                          <div className="CLcat">
                            <div className="numbers">
                              {shortCategory !== longCategory && shortCategory &&
                                <div className="short">
                                  {shortCategory}
                                </div>
                              }

                              {uniqueCategories.length > 0 &&
                                <div className="num">
                                  {`+ ${uniqueCategories.length}`}
                                </div>
                              }

                            </div>
                            <div className="long">
                              {longCategory ? longCategory : '--'}
                            </div>
                          </div>
                          <div className="CLactions">
                            <div className="action" onClick={() => handleDeleteCollection(collection.id)}><img src={delet} alt="" /></div>
                            <div className="action" onClick={() => handleEditCollection(collection)}><img src={edit} alt="" /></div>
                            <div className="action" onClick={() => handleEditCreators(collection)}><img src={adds} alt="" /></div>
                          </div>

                          <div className="method">
                            <div className="get" onClick={() => sendQuoteRequest(collection.id)}>GET QUOTE</div>
                            <div className="get" onClick={() => handleStartCampaignClick(collection.id)}>START CAMPAIGN</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </>
              )}

            </div>
            {showPopup && <CreateCollection onClose={handleClosePopup} updateCollections={updateCollections} />}
            {showEditPopup && (
              <EditCollection
                onClose={handleCloseEditPopup}
                collection={selectedCollection}
                updateCollections={updateCollections}
              />
            )}
            {showcreatorEditpopup && (
              <EditCreators
                onClose={handleCloseEditCreators}
                collection={selectedCollection}
                updateCollections={updateCollections}
              />
            )}
            {showSuccessPopup && (
              <div className="overlay1">
                <div className="quote-popup">
                  <div className="cross" >
                    <img src={cross} alt="" onClick={() => setShowSuccessPopup(false)} />
                  </div>
                  <img src={comma} alt="" className="comma" />
                  <h3 className='hrs'>Our Team will contact you within next 24 Hours</h3>
                  <img src={rcomma} alt="" className="comma" />



                </div>
              </div>
            )}

          </>
        )}

      </div>
    </div>
  )
}

export default Collections
