import React, { useState } from 'react';
import "./signinnavbar.css";
import logo from "../../media/ind.svg";
import explore from "../../media/explore.svg";
import wexplore from "../../media/wexplore.svg";
import coll from "../../media/collection.svg";
import manage from "../../media/manage.svg";
import create from "../../media/create.svg";
import wcoll from "../../media/wcollection.svg";
import wmanage from "../../media/wmanage.svg";
import wcreate from "../../media/wcreate.svg";
import subtract from "../../media/subtract.svg";
import wsubtract from "../../media/wsubtract.svg";
import SigninleftNavbar from './SigninleftNavbar';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'; 



const SigninNavbar = ({ setActiveComponent, setActiveItem ,activeItem}) => {
    const [imageSource, setImageSource] = useState({
        explore:explore,
        coll: coll,
        create: create,
        manage: manage,
        subtract: subtract
    });

    

    const handleImageChange = (imageKey, newImage) => {
        setImageSource((prevImages) => ({
            ...prevImages,
            [imageKey]: newImage,
        }));
    };
    const user = useSelector((state) => state.filters.userProfile);
    const userProfile = JSON.parse(localStorage.getItem('user'));

    console.log("user profile", user)

    const hideEmail = (email) => {
        if (!email) return '';
        const [username, domain] = email.split('@');
        const visiblePart = username.slice(0, 4); // Display only the first 6 characters of the username
        const hiddenEmail = `${visiblePart}***@${domain}`;
        return hiddenEmail;
    };

    // Usage of the modified email for display
    const hiddenEmail = hideEmail(userProfile?.email);

    return (
        <>
        <div className='navbar'>
            <Link to={'/'} className="logo">
                <img src={logo} alt="" />
                B Rich
            </Link>
            <div className="nav-items">
                <Link to={'/'} className={`itemss items ${activeItem === 'explore' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('explore'); setActiveItem('explore'); }}
                    onMouseOver={() => handleImageChange('explore', explore)}
                    onMouseOut={() => handleImageChange('explore', wexplore)}
                >
                    <img src={imageSource.explore} alt="" style={{height:"24px", width:"24px"}} className="hoverable-image"/>
                    <div className="item-img"></div>
                    Explore
                </Link>

                <Link to={'/'} className={`items itemss ${activeItem === 'collections' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('collections'); setActiveItem('collections'); }}
                    onMouseOver={() => handleImageChange('coll', wcoll)}
                    onMouseOut={() => handleImageChange('coll', coll)}
                >
                    <img
                        src={imageSource.coll}
                        alt=""
                        className="hoverable-image"
                    />
                    <div className="item-img"></div> Collections
                </Link>
                <Link to={'/'} className={`items itemss ${activeItem === 'createCampaign' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('createCampaign'); setActiveItem('createCampaign'); }}
                    onMouseOver={() => handleImageChange('create', wcreate)}
                    onMouseOut={() => handleImageChange('create', create)}
                >
                    <img
                        src={imageSource.create}
                        alt=""
                        className="hoverable-image"
                    />
                    <div className="item-img"></div>Create Campaign
                </Link>
                <Link to={'/'} className={`items itemss ${activeItem === 'manageCampaign' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('manageCampaign'); setActiveItem('manageCampaign'); }}
                    onMouseOver={() => handleImageChange('manage', wmanage)}
                    onMouseOut={() => handleImageChange('manage', manage)}
                >
                    <img
                        src={imageSource.manage}
                        alt=""
                        className="hoverable-image"
                    />
                    <div className="item-img"></div>Manage Campaign
                </Link>
            </div>
            <div className="unlock-cover">
                <div className="unlock">
                    <div className="up">
                        <img
                            src={imageSource.subtract}
                            alt=""
                            className="hoverable-image"
                        />
                        <div className="easy">
                            <div className="more">{userProfile?.company_name}</div>
                            <div className="login">{hiddenEmail}</div>
                        </div>
                    </div>
                    <Link
                    onClick={() => { setActiveComponent('settings'); setActiveItem('settings'); }}
                    to={'/'}
                     className="sign"
                        onMouseOver={() => handleImageChange('subtract', wsubtract)}
                        onMouseOut={() => handleImageChange('subtract', subtract)}
                    >
                        Settings
                    </Link>
                </div>
            </div>
        </div>
        </>

    );
};

export default SigninNavbar;
